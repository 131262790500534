// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".common-components-Modal-Modal__wrapper--G1qmj{position:relative}.common-components-Modal-Modal__wrapper--G1qmj .common-components-Modal-Modal__header--1zI-f{width:100%}.common-components-Modal-Modal__wrapper--G1qmj .common-components-Modal-Modal__body--Ok16K{width:100%}.common-components-Modal-Modal__wrapper--G1qmj .common-components-Modal-Modal__footer--3T1Y_{width:100%}", ""]);
// Exports
exports.locals = {
	"wrapper": "common-components-Modal-Modal__wrapper--G1qmj",
	"header": "common-components-Modal-Modal__header--1zI-f",
	"body": "common-components-Modal-Modal__body--Ok16K",
	"footer": "common-components-Modal-Modal__footer--3T1Y_"
};
module.exports = exports;
