import * as CONST from 'common/const';
import api from 'utils/api';
import moment from 'moment-timezone';

export const getReportsHistoryStart = () => ({
  type: CONST.REPORTS_HISTORY_GET_START,
});

export const getReportsHistorySuccess = runLogs => ({
  type: CONST.REPORTS_HISTORY_GET_SUCESS,
  runLogs,
});

export const getReportsHistoryFailure = (error = null) => ({
  type: CONST.REPORTS_HISTORY_GET_FAILURE,
  globalError: error,
});

export const getReportsHistory = () => async (dispatch, getState) => {
  const { username } = getState().auth;
  dispatch(getReportsHistoryStart());
  try {
    const result = await api.getReportsHistory(username);
    result.runLogs.sort((a,b) => b.run_date-a.run_date);
    const runLogs = result.runLogs.map((log, index) => ({
      ...log,
      entry_id: index + 1,
      run_date: moment.tz(parseInt(log.run_date, 10), moment.tz.guess()).format('MM/DD/YYYY h:mm A z')
    }));
    dispatch(getReportsHistorySuccess(runLogs));
  } catch (e) {
    dispatch(getReportsHistoryFailure(e));
  }
};

export const getReportsHistoryReset = () => async (dispatch) => {
  dispatch({
    type: CONST.REPORTS_HISTORY_GET_RESET,
  });
};

// Download Report Actions

export const downloadReportStart = () => ({
  type: CONST.REPORTS_HISTORY_DOWNLOAD_START,
});

export const downloadReportSuccess = fileURL => ({
  type: CONST.REPORTS_HISTORY_DOWNLOAD_SUCCESS,
  fileURL,
});

export const downloadReportFailure = (error = null) => ({
  type: CONST.REPORTS_HISTORY_DOWNLOAD_FAILURE,
  globalError: error,
});

export const downloadReport = fileName => async (dispatch) => {
  dispatch(downloadReportStart());
  try {
    const result = await api.downloadReport(fileName);
    dispatch(downloadReportSuccess(result.fileURL));
  } catch (e) {
    dispatch(getReportsHistoryFailure(e));
  }
};

export const downloadReportReset = () => async (dispatch) => {
  dispatch({
    type: CONST.REPORTS_HISTORY_DOWNLOAD_RESET,
  });
};
