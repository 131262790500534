function zip(arr1, arr2) {
  const result = [];
  for (let i = 0; i < arr1.length && i < arr2.length; i += 1) {
    result.push([arr1[i], arr2[i]]);
  }
  return result;
}

/**
 * Take data imported from the XLSXUtils.loadFile() method
 * and perform a data transform on it.
 *
 * When data is imported from XLSXUtils.loadFile(), it takes
 * the form and order of the Excel table. The first row contains
 * the file's headers, and the subsequent rows contain the
 * actual data.
 *
 * In order for this data to be useable, we must convert it
 * into JSON. This function uses the attributes found in the
 * first row of the input array as object keys, and converts
 * each subsequent row from an array of values into an object
 * mapping the field names to each field value.
 */
export function transformExcelToJson(excelData = []) {
  if (!(excelData instanceof Array) || excelData.length < 1) {
    return null; // TODO: Handle error condition more gracefully
  }

  const header = excelData[0]; // .map(attributeName => attributeName.trim().replace(/ /g, '_'));
  const values = excelData.slice(1);
  const result = values.map(value => Object.fromEntries(zip(header, value)));
  return result;
}

/**
 * When Journals are imported from Excel, their column names come
 * in the form of "Journal Header ID" or "Item Type", for example.
 * This function maps each input column name to the name expected
 * by DynamoDB and the rest of the application.
 */
export function journalToJson(journal) {
  const attributeNameMap = {
    'Journal Header ID': 'journal_header_id',
    'Journal Line ID': 'journal_line_id',
    'Item Type': 'item_type',
    'Processing Status': 'processing_status',
    Type: 'type',
    'Workbook Name': 'workbook_name',
    'Workbook ID': 'workbook_id',
    'Journal Batch Name': 'journal_batch_name',
    'Journal Batch ID': 'journal_batch_id',
    'Upload Name': 'upload_name',
    'Journal Creation Method': 'journal_create_method',
    'Line Description': 'line_desc',
    Company: 'company',
    Location: 'location',
    'Cost Center': 'cost_center',
    Account: 'account',
    'Product Line': 'product_line',
    'Channel-Geo': 'channel_geo',
    'Project Code': 'project_code',
    'Entered Dr': 'entered_dr',
    'Entered Cr': 'entered_cr',
    'Document Type': 'document_type',
    'Accounting Date': 'accounting_date',
    'Approver Company': 'approver_company',
    'Journal Currency': 'journal_currency',
    'Journal Name': 'journal_name',
    'Journal Category': 'journal_category',
    'Transaction Type': 'transaction_type',
    'IC Journal Type': 'ic_journal_type',
    'IC Matching ID ': 'ic_matching_id',
    'Entry Recurring': 'entry_recurring',
    'IC Transaction Grouping': 'ic_transaction_group',
    'Journal Description': 'journal_desc',
    'Provider or Recipient': 'provider_or_recipient',
    'Accounted Dr': 'accounted_dr',
    'Accounted Cr': 'accounted_cr',
    'Exchange Rate': 'exchange_rate',
    'Currency Conversion Type': 'currency_conv_type',
    'Currency Conversion Date': 'currency_conv_date',
    'Reversal Period': 'reversal_period',
    'Trading Partner': 'trading_partner',
    'Time Required': 'time_reqd',
    'Upstream System': 'upstream_system',
    'Process Documented': 'process_documented',
    'Close Manager Task Number': 'close_mgr_task_num',
    'Agreement Line Number': 'agreement_line_num',
    'Product Category Name': 'product_cat_name',
    'Product Subcategory Name': 'product_subcat_name',
    'Indirect Tax Rate': 'indirect_tax_rate',
    'Credit Memo Invoice Year': 'credit_memo_invoice_yr',
    'IC Event Type': 'ic_event_type',
    'IC Line Offset': 'ic_line_offset',
    'IC Partner Offset': 'ic_partner_offset',
    'Intercompany Transaction ID': 'ic_transaction_id',
    'Provider Legal Entity Name': 'prov_legal_ent_name',
    'Provider Jurisdiction': 'prov_jurisdiction',
    'Receiver Legal Entity Name': 'rec_legal_ent_name',
    'Receiver Jurisdiction': 'rec_jurisdiction',
    'Indirect Tax Regime Code': 'indirect_tax_reg_code',
    'Indirect Tax Rate Code': 'indirect_tax_rate_code',
    'Tax Derivation Flag': 'system_derived_tax',
    'Tax Reference Link': 'tax_ref_link',
    'Tax Generation': 'tax_gen_type',
    'Invoice Status': 'invoice_status',
    'Invoice Number': 'invoice_num',
    'Invoice Link': 'invoice_link',
    Ledger: 'ledger',
    Reversed: 'reversed',
    'Reversal Method': 'reversal_method',
    'Original Journal Reference': 'orig_journal_id',
    'Preparer Login': 'create_user',
    'Creation Date': 'create_date',
    'Update User Login': 'last_update_user',
    'Last Update Date': 'last_update_date',
    'Approver Login': 'approve_user',
    'Approval date': 'approve_date',
  };

  return Object.fromEntries(
    Object.entries(journal)
      .filter(([key, value]) => !(key in Object.keys(attributeNameMap)))
      .map(([key, value]) => [attributeNameMap[key], value]),
  );
}

export default {
  transformExcelToJson,
};
