import * as CONST from 'common/const';
import createReducer from './createReducer';

export const initialState = {
  loading: false,
  searching: false,
  error: null,
  workbook: {
    journalBatches: [],
  },
  searchItems: [],
  downloadTemplateUrl: null,
  templateDownloading: false,
  copiedWorkbook: null,
  downloadWorkbookUrl: null,
  downloadInvoiceResponse: null,
};

export default createReducer(initialState, {
  [CONST.LANDING_PAGE_UPLOAD_FILE_START]: state => ({
    ...state,
    loading: true,
    error: null,
    workbook: { journalBatches: [] },
  }),

  [CONST.LANDING_PAGE_UPLOAD_FILE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    workbook: action.payload.workbook,
  }),

  [CONST.LANDING_PAGE_UPLOAD_FILE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
    workbook: { journalBatches: [] },
  }),

  [CONST.LANDING_PAGE_SEARCH_START]: state => ({
    ...state,
    searching: true,
    error: null,
  }),

  [CONST.LANDING_PAGE_SEARCH_SUCCESS]: (state, action) => ({
    ...state,
    searching: false,
    error: null,
    searchItems: action.payload,
  }),

  [CONST.LANDING_PAGE_SEARCH_FAILURE]: (state, action) => ({
    ...state,
    searching: false,
    error: action.payload,
  }),

  [CONST.LANDING_PAGE_DOWNLOAD_TEMPLATE_START]: state => ({
    ...state,
    error: null,
    templateDownloading: true,
  }),

  [CONST.LANDING_PAGE_DOWNLOAD_TEMPLATE_SUCCESS]: (state, action) => ({
    ...state,
    error: null,
    downloadTemplateUrl: action.payload,
    templateDownloading: false,
  }),

  [CONST.LANDING_PAGE_DOWNLOAD_TEMPLATE_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
    templateDownloading: false,
  }),

  [CONST.LANDING_PAGE_DOWNLOAD_TEMPLATE_RESET]: state => ({
    ...state,
    downloadTemplateUrl: null,
  }),

  [CONST.LANDING_PAGE_COPY_FILE_START]: state => ({
    ...state,
    loading: true,
    error: null,
  }),

  [CONST.LANDING_PAGE_COPY_FILE_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    copiedWorkbook: action.payload,
  }),

  [CONST.LANDING_PAGE_COPY_FILE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),

  [CONST.LANDING_PAGE_COPY_FILE_RESET]: state => ({
    ...state,
    copiedWorkbook: null,
  }),

  [CONST.LANDING_PAGE_DELETE_WORKBOOK_START]: state => ({
    ...state,
    loading: true,
    error: null,
  }),

  [CONST.LANDING_PAGE_DELETE_WORKBOOK_SUCCESS]: state => ({
    ...state,
    loading: false,
    error: null,
  }),

  [CONST.LANDING_PAGE_DELETE_WORKBOOK_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),

  [CONST.LANDING_PAGE_DOWNLOAD_WORKBOOK_START]: state => ({
    ...state,
    loading: true,
    error: null,
  }),

  [CONST.LANDING_PAGE_DOWNLOAD_WORKBOOK_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    downloadWorkbookUrl: action.payload,
  }),

  [CONST.LANDING_PAGE_DOWNLOAD_WORKBOOK_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),

  [CONST.LANDING_PAGE_DOWNLOAD_WORKBOOK_RESET]: state => ({
    ...state,
    downloadWorkbookUrl: null,
  }),

  [CONST.LANDING_PAGE_POST_FILE_START]: state => ({
    ...state,
    loading: true,
    error: null,
  }),

  [CONST.LANDING_PAGE_POST_FILE_SUCCESS]: state => ({
    ...state,
    loading: false,
    error: null,
  }),

  [CONST.LANDING_PAGE_POST_FILE_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),

  [CONST.LANDING_PAGE_DOWNLOAD_INVOICE_START]: state => ({
    ...state,
    error: null,
    loading: true,
  }),

  [CONST.LANDING_PAGE_DOWNLOAD_INVOICE_SUCCESS]: (state, action) => ({
    ...state,
    error: null,
    downloadInvoiceResponse: action.payload,
    loading: false,
  }),

  [CONST.LANDING_PAGE_DOWNLOAD_INVOICE_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }),

  [CONST.LANDING_PAGE_DOWNLOAD_INVOICE_RESET]: state => ({
    ...state,
    downloadInvoiceResponse: null,
  }),
});
