import React from 'react';
import PropTypes from 'prop-types';
import { Steps, Icon as AntdIcon } from 'antd';
import { FormSection } from '@amzn/awsui-components-react';
import 'antd/dist/antd.css';
import '@amzn/awsui-components-react/index.css';
import CONSTANT from 'utils/constant';

/*
 * This progress bar shows the status of each file upload step
 */
class ProgressBar extends React.Component {
  static propTypes = {
    currentIndex: PropTypes.number.isRequired,
    // stepStatus: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  getStatus(index) {
    const { currentIndex } = this.props;
    if (index < currentIndex) return CONSTANT.STEP_FINISH;
    if (index === currentIndex) return CONSTANT.STEP_PROCESS;
    return CONSTANT.STEP_WAIT;
  }

  getDescription(index) {
    const { currentIndex } = this.props;
    if (index < currentIndex) return 'Succeed';
    if (index === currentIndex) return 'In progress';
    return 'Waiting';
  }

  render() {
    const { currentIndex } = this.props;
    return (
      <FormSection className="custom-screenshot-hide" header="Upload MJE Data">
        <Steps size="small" current={currentIndex}>
          <Steps.Step
            status={this.getStatus(0)}
            title="Specify table"
            icon={<AntdIcon type="search" />}
            description={this.getDescription(0)}
          />

          <Steps.Step
            status={this.getStatus(1)}
            title="Download header"
            icon={<AntdIcon type="cloud-download" />}
            description={this.getDescription(1)}
          />

          <Steps.Step
            status={this.getStatus(2)}
            title="Select file"
            icon={<AntdIcon type="folder-open" />}
            description={this.getDescription(2)}
          />

          <Steps.Step
            status={this.getStatus(3)}
            title="Validate file"
            icon={<AntdIcon type="security-scan" />}
            description={this.getDescription(3)}
          />

          <Steps.Step
            status={this.getStatus(4)}
            title="Upload file"
            icon={<AntdIcon type="cloud-upload" />}
            description={this.getDescription(4)}
          />

          <Steps.Step
            status={this.getStatus(5)}
            title="Generate report"
            icon={<AntdIcon type="printer" />}
            description={this.getDescription(5)}
          />
        </Steps>
      </FormSection>
    );
  }
}

export default ProgressBar;
