import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as CONST from 'common/const';
import CONSTANT from 'utils/constant';
import MJEModal from 'common/components/mjeModal/MJEModal';

const NewSupportTicketUrl = process.env.NEW_SUPPORT_TICKET_URL;

const ErrorNotification = () => {
  const isOpen = useSelector(state => state.errorReducer.isOpen);
  const error = useSelector(state => state.errorReducer.error);

  const dispatch = useDispatch();

  function handleClose() {
    dispatch({ type: CONST.CLEAR_GLOBAL_ERROR });
  }
  function handleCreateTicket() {
    window.open(NewSupportTicketUrl, '_blank');
  }
  return (
    <>
      {error && (
        <MJEModal
          visible={error && isOpen}
          header="There was a problem"
          content={
            error == null || error.message == null
              ? CONSTANT.GENERIC_ERROR_MESSAGE
              : error.message
          }
          onYes={handleCreateTicket}
          onNo={handleClose}
          type="ERROR"
          yesButtonText="Create Support Ticket"
          noButtonText="Close"
          size="medium"
          actionType="YES_NO"
        />
      )}
    </>
  );
};

export default ErrorNotification;
