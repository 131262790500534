import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button, ColumnLayout, Form, FormSection,
} from '@amzn/awsui-components-react';
import { TableView } from 'common/components/dataDisplay';
import '@amzn/awsui-components-react/index.css';

class StepTwo extends React.Component {
  static propTypes = {
    handleViewClick: PropTypes.func.isRequired,
    handleDownloadClick: PropTypes.func.isRequired,
    handleNextClick: PropTypes.func.isRequired,

    templateName: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.shape({})),
    items: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    templateName: '',
    columns: [],
    items: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      elementSelected: [],
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(polarisSelectedList) {
    this.setState({
      elementSelected: polarisSelectedList,
    });
  }

  render() {
    const { elementSelected } = this.state;
    const {
      handleViewClick,
      handleDownloadClick,
      handleNextClick,
      templateName,
      columns,
      items,
    } = this.props;

    const viewDetailsDisabled = elementSelected.length !== 1;

    const header = (
      <div className="awsui-util-action-stripe">
        <div className="awsui-util-action-stripe-title">
          <h2>
            Meta data table
            {templateName}
          </h2>
        </div>
        <div className="awsui-util-action-stripe-group">
          <Button
            text="View Details"
            type="submit"
            disabled={viewDetailsDisabled}
            onClick={() => handleViewClick(elementSelected)}
          />
        </div>
      </div>
    );

    const columnDefinitions = [];
    for (let index = 0; index < columns.length; index += 1) {
      const columnDefinitionsItem = {};
      const column = columns[index];
      columnDefinitionsItem.id = column;
      columnDefinitionsItem.header = column;
      columnDefinitionsItem.cell = item => item[column];
      columnDefinitionsItem.width = 150;
      columnDefinitionsItem.minWidth = '150px';
      columnDefinitions.push(columnDefinitionsItem);
    }

    const sortableColumns = [];
    for (let index = 0; index < columns.length; index += 1) {
      const sortableColumnsItem = {};
      const column = columns[index];
      sortableColumnsItem.id = column;
      sortableColumnsItem.field = column;
      sortableColumns.push(sortableColumnsItem);
    }

    const visibleColumnOption = [];
    const firstOption = {};
    firstOption.label = 'Properties';
    const options = [];
    for (let index = 0; index < columns.length; index += 1) {
      const visibleColumnOptionItem = {};
      const column = columns[index];
      visibleColumnOptionItem.id = column;
      visibleColumnOptionItem.label = column;
      visibleColumnOptionItem.editable = column !== 'column_name';
      visibleColumnOptionItem.visible = true;
      options.push(visibleColumnOptionItem);
    }
    firstOption.options = options;
    visibleColumnOption.push(firstOption);

    const pageSizeOption = [
      { value: 10, label: '10 items' },
      { value: 20, label: '20 items' },
      { value: 50, label: '50 items' },
    ];

    return (
      <div>
        <Form>
          <FormSection
            className="custom-screenshot-hide"
            header="Step 2 - Download empty spreadsheet with header"
          >
            <ColumnLayout columns={1}>
              <div data-awsui-column-layout-root>
                <TableView
                  tableTitle="MJE meta data table"
                  columnDefinitions={columnDefinitions}
                  items={items}
                  header={header}
                  trackBy="column_name"
                  searchMessage="Filter"
                  pageSize={10}
                  loading={false}
                  sortableColumns={sortableColumns}
                  pageSizeOption={pageSizeOption}
                  visibleColumnOption={visibleColumnOption}
                  s
                  handleSelectChange={this.handleSelectChange}
                />

                <br />
                <br />
                <li>
                  Please download the empty excel with header below and fill in the data you want to
                  upload.
                </li>
                <li>
                  Remember you data shall always be consistent with the schema defined for this
                  table.
                </li>
                <li>
                  Data validation will be enforced before you upload the data. Invalid records will
                  be discarded when uploading.
                </li>
                <br />
                <br />

                <Button
                  text="Download Header"
                  variant="primary"
                  icon="download"
                  className="awsui-util-mb-xl"
                  onClick={handleDownloadClick}
                />
              </div>
            </ColumnLayout>
          </FormSection>
        </Form>

        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-title" />
          <div className="awsui-util-action-stripe-group">
            <Button
              text="Next"
              variant="primary"
              icon="angle-right"
              iconAlign="right"
              className="awsui-util-mb-xl"
              type="submit"
              onClick={handleNextClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(StepTwo);
