import * as CONST from 'common/const';
import api from 'utils/api';

export const fetchDataStart = () => ({
  type: CONST.PRIME_POST_PAGE_FETCH_DATA_START,
});

export const fetchDataSuccess = (data = {}) => ({
  type: CONST.PRIME_POST_PAGE_FETCH_DATA_SUCCESS,
  payload: data,
});

export const fetchDataFailure = (error = null) => ({
  type: CONST.PRIME_POST_PAGE_FETCH_DATA_FAILURE,
  globalError: error,
});

export const fetchData = postingSummary => (dispatch /* , getState */) => {
  dispatch(fetchDataStart());
  try {
    dispatch(fetchDataSuccess(postingSummary));
  } catch (e) {
    dispatch(fetchDataFailure(e));
  }
};

export const downloadConfirmationStart = () => ({
  type: CONST.PRIME_POST_PAGE_DOWNLOAD_START,
});

export const downloadConfirmationSuccess = (data = {}) => ({
  type: CONST.PRIME_POST_PAGE_DOWNLOAD_SUCCESS,
  payload: data,
});

export const downloadConfirmationFailure = (error = null) => ({
  type: CONST.PRIME_POST_PAGE_DOWNLOAD_FAILURE,
  globalError: error,
});

export const downloadConfirmationReset = () => ({
  type: CONST.PRIME_POST_PAGE_DOWNLOAD_RESET,
});

export const downloadConfirmation = (requestType, requestId) => async (dispatch) => {
  dispatch(downloadConfirmationStart());
  try {
    const result = await api.downloadDataGrid(requestType, requestId, true);
    dispatch(downloadConfirmationSuccess(result.workbookURL));
  } catch (e) {
    dispatch(downloadConfirmationFailure(e));
  }
};
