import React from 'react';
import PropTypes from 'prop-types';
import '@amzn/awsui-components-react/index.css';
import { Form, FormSection, ColumnLayout } from '@amzn/awsui-components-react';
import { FreeTextElement } from 'common/components/dataEntry';

function MJEHeaderSection({ header }) {
  return (
    <div>
      <Form>
        <FormSection className="custom-screenshot-hide" header="MJE Header">
          <ColumnLayout columns={3}>
            <div data-awsui-column-layout-root>
              {Object.keys(header).map(name => (
                <FreeTextElement
                  key={name}
                  title={name}
                  name={name}
                  value={header[name]}
                  disabled
                  required
                  handleChange={(n, v) => v}
                  setErrorFlag={(n, errorText) => errorText}
                />
              ))}
            </div>
          </ColumnLayout>
        </FormSection>
      </Form>
    </div>
  );
}

MJEHeaderSection.propTypes = {
  header: PropTypes.shape({}),
};

MJEHeaderSection.defaultProps = {
  header: {},
};

export default MJEHeaderSection;
