import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import '@amzn/awsui-components-react/index.css';
import { FormSection, ColumnLayout } from '@amzn/awsui-components-react';
import { TwoKeyValuePairSingleVertical } from 'common/components/dataDisplay';

function StepSix({
  templateName,
  totalRecordCount,
  totalMJECount,
  validationEndTime,
  validationStartTime,
  uploadStartTime,
  uploadEndTime,
}) {
  return (
    <div>
      <FormSection className="custom-screenshot-hide" header="Step 6 - Upload Summary">
        <ColumnLayout columns={3} variant="text-grid">
          <div data-awsui-column-layout-root="true">
            <TwoKeyValuePairSingleVertical
              titleOne="Upload from System"
              valueOne="Local"
              titleTwo="Upload to system"
              valueTwo="ICE"
            />
            <TwoKeyValuePairSingleVertical
              titleOne="Upload against template"
              valueOne={templateName}
              titleTwo="Upload status"
              valueTwo="Success"
              statusTwo="positive"
            />
            <TwoKeyValuePairSingleVertical
              titleOne="Total records uploaded"
              valueOne={totalRecordCount}
              titleTwo="Total MJE uploaded"
              valueTwo={totalMJECount}
            />
            <TwoKeyValuePairSingleVertical
              titleOne="Validation start time"
              valueOne={validationStartTime}
              titleTwo="Validation end time"
              valueTwo={validationEndTime}
            />
            <TwoKeyValuePairSingleVertical
              titleOne="Upload start time"
              valueOne={uploadStartTime}
              titleTwo="Upload end time"
              valueTwo={uploadEndTime}
            />
          </div>
        </ColumnLayout>
      </FormSection>
    </div>
  );
}

StepSix.propTypes = {
  templateName: PropTypes.string,
  totalRecordCount: PropTypes.number,
  totalMJECount: PropTypes.number,
  validationEndTime: PropTypes.string,
  validationStartTime: PropTypes.string,
  uploadStartTime: PropTypes.string,
  uploadEndTime: PropTypes.string,
};

StepSix.defaultProps = {
  templateName: '',
  totalRecordCount: 0,
  totalMJECount: 0,
  validationEndTime: null,
  validationStartTime: null,
  uploadStartTime: null,
  uploadEndTime: null,
};

export default withRouter(StepSix);
