/* eslint-disable no-debugger */
/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { AppLayout } from '@amzn/awsui-components-react';
import 'babel-polyfill';

import ErrorNotification from 'common/components/err/errorNotification';
import { ensureAuthenticated } from 'utils/auth';
import { loginSuccess, loginFailure } from 'actions';
import ICMJEApp from './ICMJEApp';
import ICMJEAppRestrict from './ICMJEAppRestrict';
import '@amzn/awsui-components-react/index.css';
import './index.css';

import configureStore from './store';
import CONSTANT from './utils/constant';

const store = configureStore({});

function getUserRole(auth) {
  const session = auth.getSignInUserSession();
  const idTokenPayload = session.getIdToken().decodePayload();
  return idTokenPayload[CONSTANT.COGNITO_ROLE_ATTRIBUTE];
}

function startICMJEApp() {
  ensureAuthenticated(true)
    .then((auth) => {
      const role = getUserRole(auth);
      console.log(`User role: ${role}`);

      if (role && (role.includes(CONSTANT.USER_ROLE.ADMIN)
          || role.includes(CONSTANT.USER_ROLE.AUTHORIZED_USER))) {
        store.dispatch(loginSuccess(auth));
        ReactDOM.render(
          <Provider store={store}>
            <BrowserRouter>
              <div className="awsui">
                <ErrorNotification />
                <AppLayout
                  content={<ICMJEApp />}
                  contentType="default"
                  navigationHide
                  toolsHide
                />
              </div>
            </BrowserRouter>
          </Provider>,
          document.getElementById('root'),
        );
      } else {
        store.dispatch(loginFailure(new Error('Access Denied')));
        ReactDOM.render(
          <BrowserRouter>
            <div className="awsui">
              <AppLayout
                content={<ICMJEAppRestrict />}
                contentType="default"
                navigationHide
                toolsHide
              />
            </div>
          </BrowserRouter>,
          document.getElementById('root'),
        );
      }
    })
    .catch((e) => {
      console.log('ensureAuthenticated threw an exception: ', e);
      store.dispatch(loginFailure(e));
      ReactDOM.render(
        <BrowserRouter>
          <div className="awsui">
            <AppLayout
              content={<ICMJEAppRestrict />}
              contentType="default"
              navigationHide
              toolsHide
            />
          </div>
        </BrowserRouter>,
        document.getElementById('root'),
      );
    });
}

startICMJEApp();
