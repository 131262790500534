// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pages-primePostPage-PrimePostPage__wrapper--2ythF .pages-primePostPage-PrimePostPage__primePost--30mdN awsui-table .awsui-table-container>table{min-width:1119px}.pages-primePostPage-PrimePostPage__wrapper--2ythF .pages-primePostPage-PrimePostPage__primePost--30mdN awsui-table .awsui-table-container>table>tbody>tr>*{padding:.3rem .1rem}.pages-primePostPage-PrimePostPage__wrapper--2ythF .pages-primePostPage-PrimePostPage__primePost--30mdN awsui-table .awsui-table-container>table>tbody>tr>td{padding-left:1.3rem}.pages-primePostPage-PrimePostPage__wrapper--2ythF table.awsui-table-nowrap{counter-reset:rowCount}.pages-primePostPage-PrimePostPage__wrapper--2ythF .ReactTable .rt-th{text-align:left}.pages-primePostPage-PrimePostPage__wrapper--2ythF table.awsui-table-nowrap tbody tr span.rowCounter::before{counter-increment:rowCount;content:counter(rowCount)}", ""]);
// Exports
exports.locals = {
	"wrapper": "pages-primePostPage-PrimePostPage__wrapper--2ythF",
	"primePost": "pages-primePostPage-PrimePostPage__primePost--30mdN"
};
module.exports = exports;
