import React from 'react';
import './mjeBanner.css';
import PropTypes from 'prop-types';

function MJEBanner(props) {
  const { bannerText } = props;
  return (
    <div className="mjeBanner">
      <h3>{bannerText}</h3>
    </div>
  );
}

MJEBanner.propTypes = {
  bannerText: PropTypes.string.isRequired,
};

export default MJEBanner;
