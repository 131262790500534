/* eslint-disable no-await-in-loop */
import {ensureAuthenticated} from 'utils/auth';
import AWS from 'aws-sdk';
import Logger from 'utils/logger';
import CONSTANT from 'utils/constant';

// BaseUrl for API. When in development mode, this API will route back to
// https://localhost:3000; Webpack has been configured to proxy API requests
// to http://localhost:1180 for local development with RDE.
let baseUrl = '/api';
const environment = process.env.NODE_ENV;
const UserPoolId = process.env.USER_POOL_ID;
const IdentityPoolId = process.env.IDENTITY_POOL_ID;
const S3_BUCKET = process.env.ICTP_MJE_VALIDATION_RESPONSE_BUCKET;

// Common Authentication logic.
function authenticate() {
  return ensureAuthenticated();
}

// Common abstraction for calling any API. The parameters used for querying
// the API are all common among the API endpoints, so this method abstracts
// this functionality to make the API methods more readable.
async function callExternalApi(method, url, body = null, headers = {}) {
  const auth = await authenticate();
  const session = auth.getSignInUserSession();
  const jwtToken = session.getIdToken().getJwtToken();

  // Utility function to check response status and handle errors based on status code
  async function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      // If everything looks good
      return response;
    }
    if (response.status === 417) {
      // Lambda API will return status code 417 with a message intended for the final user
      const responseBody = await response.json();
      const error = new Error(responseBody.message);
      error.response = response;
      throw error;
    } else if (response.status >= 300 && response.status < 500) {
      // If there are issues where the user could take action (not server side errors)
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    } else {
      // All other status codes (mostly 500s will display this generic message)
      const error = new Error(CONSTANT.GENERIC_ERROR_MESSAGE);
      error.response = response;
      throw error;
    }
  }

  function parseJSON(response) {
    return response.json();
  }

  const response = await fetch(url, {
    method,
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      ...headers,
      Authorization: jwtToken,
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: method === 'GET' ? null : JSON.stringify(body),
  });

  await checkStatus(response);
  const result = await parseJSON(response);

  Logger.logInfo(`HTTP ${method} ${url} = ${result}`);
  return result;
}

// Common abstraction for calling the API. The parameters used for querying
// the API are all common among the API endpoints, so this method abstracts
// this functionality to make the API methods more readable.
async function callApi(method, endpoint, body = null, headers = {}) {
  if (environment === 'production') {
    baseUrl = process.env.BUSINESS_LOGIC_API;
  }

  const url = `${baseUrl}${endpoint}`;

  const result = await callExternalApi(method, url, body, headers);

  return result;
}

async function downloadFromS3(s3key) {
  const auth = await ensureAuthenticated();
  const session = auth.getSignInUserSession();
  const jwtToken = session.getIdToken().getJwtToken();
  const region = 'us-west-2';
  AWS.config.update({
    region,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId,
      Logins: {
        [`cognito-idp.us-west-2.amazonaws.com/${UserPoolId}`]: jwtToken,
      },
    }),
  });
  const s3 = new AWS.S3({
    region,
  });
  const params = {
    Bucket: S3_BUCKET,
    Key: s3key,
  };
  const data = await s3.getObject(params).promise();
  return JSON.parse(data.Body.toString());
}

// ICTPWebAPILambda API Abstraction Class. This class contains all
// methods which correspond to the Backend Lambda API.
const API = {
  async createMetaData() {
    return authenticate(); // TODO: Implement
  },

  async deleteMetaData() {
    return authenticate(); // TODO: Implement
  },

  async queryMetaData() {
    return authenticate(); // TODO: Implement
  },

  async updateMetaData() {
    return authenticate(); // TODO: Implement
  },

  async fetchMJEKeys() {
    return authenticate(); // TODO: Implement
  },

  async queryMJE() {
    return authenticate(); // TODO: Implement
  },

  async uploadMJEFile(data) {
    const result = await callApi('POST', '/file/upload', data);
    Logger.logInfo(`uploadMJEFile() = ${result}`);
    return result;
  },

  async loadSearchModal() {
    const result = await callApi('GET', '/journals/searchable-attributes');
    Logger.logInfo(`loadSearchModal() = ${result}`);
    return result;
  },

  async searchForMJEDashboard(data) {
    const result = await callApi('POST', '/journals/search', data);
    return result;
  },

  async downloadTemplate() {
    const result = await callApi(
      'GET',
      '/template/download/MJE_Template.xlsx', // This will be the official name for the most recent template
    );
    Logger.logInfo(`downloadTemplate() = ${result}`);
    return result;
  },

  async fetchValidation(requestType, requestId, displayStagedTaxLines) {
    const response = await callApi('GET', `/fetchValidation/${requestType}/${requestId}/${displayStagedTaxLines}`);
    const { s3key } = response;
    const result = downloadFromS3(s3key);
    Logger.logInfo(`fetchValidation() = ${result}`);
    return result;
  },

  async updateWorkbook(data) {
    const result = await callApi('PUT', '/journals/update-workbook', data);
    Logger.logInfo(`updateWorkbook() = ${result}`);
    return result;
  },

  async postFile(payload) {
    const result = await callApi('POST', '/files/post', payload);
    return result;
  },

  async validateWorkbook(workbook_id) {
    const result = await callApi('POST', `/validate/workbook/${workbook_id}`, {});
    Logger.logInfo(`validateWorkbook() = ${result}`);
    return result;
  },

  async copyFile(payload) {
    const result = await callApi('POST', '/files/copy', payload);
    return result;
  },

  async saveWorkbook(data) {
    const result = await callApi('PUT', '/workbooks/save', data);
    Logger.logInfo(`saveWorkbook() = ${result}`);
    return result;
  },

  async saveWorkbookName(data) {
    const result = await callApi('PUT', '/workbooks/update-name', data);
    Logger.logInfo(`saveWorkbookName() = ${result}`);
    return result;
  },

  async fetchReversalPeriods(journalHeaderId) {
    const result = await callApi('GET', `/reversal-periods/${journalHeaderId}`);
    Logger.logInfo(`fetchReversalPeriods() = ${result}`);
    return result;
  },

  async reverseJournal(data) {
    const result = await callApi('POST', '/journals/reverse', data);
    Logger.logInfo(`reverseJournal() = ${result}`);
  },

  async fetchJournal(requestId) {
    const result = await callApi(
      'GET',
      `/journal/${requestId}`,
    );
    Logger.logInfo(`fetchJournal() = ${result}`);
    return result;
  },

  async downloadDataGrid(requestType, requestId, isForPostConfirmation) {
    const result = await callApi(
      'GET',
      `/downloadDataGrid/${requestType}/${requestId}/${isForPostConfirmation || false}`,
    );
    Logger.logInfo(`downloadDataGrid() = ${result}`);
    return result;
  },

  async deleteWorkbook(workbookId) {
    const result = await callApi('DELETE', `/landing/workbooks/${workbookId}`, {});
    Logger.logInfo(`deleteWorkbook() = ${result}`);
    return result;
  },

  async downloadJournalSummary(requestId) {
    const result = await callApi(
      'GET',
      `/journals/download/${requestId}`,
    );
    Logger.logInfo(`downloadJournalSummary() = ${result}`);
    return result;
  },

  async deleteValidationUIRow(workbookId, journalHeaderId, lineSeqId) {
    const result = await callApi('DELETE', `/workbooks/${workbookId}/journals/${journalHeaderId}/lines/${lineSeqId}`, {});
    Logger.logInfo(`deleteValidationUIRow() = ${result}`);
    return result;
  },

  async insertValidationUIRow(workbookId, row) {
    const result = await callApi('POST', `/workbooks/${workbookId}/lines`, { lineData: row });
    Logger.logInfo(`insertValidationUIRow() = ${result}`);
    return result;
  },

  async runIPEWorkflow(workbookId) {
    await callApi('POST', `/autoGenerateTax/workbook/${workbookId}`, {});

    // Wait up to 15min for the workflow to finish
    for (let i = 0; i < 90; i += 1) {
      Logger.logInfo('Waiting for workflow to finish...');
      // Wait 10s before the next status check
      await new Promise(r => setTimeout(r, 10000));
      const result = await callApi('GET', `/workflow/status/${workbookId}`);
      if (result.status === CONSTANT.WORKFLOW_STATUS.FINISHED
          || result.status === CONSTANT.WORKFLOW_STATUS.FAILED) {
        return result;
      }
    }

    return { status: CONSTANT.WORKFLOW_STATUS.TIMEOUT };
  },

  async getIndirectTaxSummary(workbookId) {
    const result = await callApi('GET', `/getIndirectTaxSummary/${workbookId}`, {});
    Logger.logInfo(`getIndirectTaxSummary() = ${result}`);
    return result;
  },

  async reportCreate(reportType, reportPeriod, userID) {
    const result = await callApi('POST', '/reports/create', { userID, reportType, reportPeriod });
    return result;
  },

  async reportProcess(userID, reportID) {
    const result = await callApi('POST', '/reports/process', { userID, reportID });
    return result;
  },

  async getReportsHistory(userName) {
    const result = await callApi('GET', `/reports/history/${userName}`);
    return result;
  },

  async getReportPeriods() {
    const result = await callApi('GET', `/reports/periods`);
    Logger.logInfo(`getReportPeriods() = ${result}`);
    return result;
  },

  async downloadReport(fileName) {
    const result = await callApi('GET', `/reports/download/${fileName}`);
    return result;
  },

  async runMJEWorkflow(workbookId) {
    await callApi('POST', `/workflow/start/${workbookId}`, {});

    // Wait up to 15min for the workflow to finish
    for (let i = 0; i < 90; i += 1) {
      Logger.logInfo('Waiting for workflow to finish...');
      // Wait 10s before the next status check
      await new Promise(r => setTimeout(r, 10000));
      const result = await callApi('GET', `/workflow/status/${workbookId}`);
      if (result.status === CONSTANT.WORKFLOW_STATUS.VALIDATION_FINISHED
        || result.status === CONSTANT.WORKFLOW_STATUS.FAILED) {
        return result;
      }
    }
    return { status: CONSTANT.WORKFLOW_STATUS.TIMEOUT };
  },

  async runWorkflowForExpressUpload(workbookId) {
    const runMJEWorkflowResult = await this.runMJEWorkflow(workbookId);

    if (runMJEWorkflowResult.status === CONSTANT.WORKFLOW_STATUS.VALIDATION_FINISHED && runMJEWorkflowResult.workbookIsValid) {
      return this.runIPEWorkflow(workbookId);
    }

    return runMJEWorkflowResult;
  },

  async downloadInvoice(invoiceHeaderId, invoiceNumber) {
    Logger.logInfo(`Downloading invoice: ${invoiceNumber}`);
    const result = await callApi('POST', `/invoice/download`, {
      invoiceId: invoiceHeaderId.toString(),
      friendlyName: `${invoiceHeaderId}.pdf`,
    });

    return result;
  },

};

export default API;
