import * as CONST from 'common/const';
import createReducer from './createReducer';

export const initialState = {
  loading: false,
  journalHeaderId: '',
  error: null,
  header: {},
  conversion: {},
  dataGridColumns: [],
  dataGrid: [],
  reversal: {
    methods: [],
    periods: [],
  },
  saving: false,
  reversalConfirmationVisible: false,
  downloadJournalUrl: null,
};

export default createReducer(initialState, {

  [CONST.PRIME_JOURNAL_SUMMARY_UPDATE_PAGE]: (state, action) => ({
    ...state,
    header: action.data.header,
    conversion: action.data.conversion,
    dataGridColumns: action.data.dataGridColumns,
    dataGrid: action.data.dataGrid,
    journalHeaderId: action.data.requestId,
    reversal: action.data.reversal,
  }),

  [CONST.PRIME_JOURNAL_SUMMARY_FETCH_DATA_START]: state => ({
    ...state,
    loading: true,
    error: null,
  }),

  [CONST.PRIME_JOURNAL_SUMMARY_FETCH_DATA_SUCCESS]: state => ({
    ...state,
    loading: false,
    error: null,
  }),

  [CONST.PRIME_JOURNAL_SUMMARY_FETCH_DATA_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  }),

  [CONST.JOURNAL_SUMMARY_REVERSE_JOURNAL_START]: state => ({
    ...state,
    saving: true,
    error: null,
    reversalConfirmationVisible: false,
  }),

  [CONST.JOURNAL_SUMMARY_REVERSE_JOURNAL_SUCCESS]: state => ({
    ...state,
    saving: false,
    error: null,
    reversalConfirmationVisible: true,
  }),

  [CONST.JOURNAL_SUMMARY_REVERSE_JOURNAL_FAILURE]: (state, action) => ({
    ...state,
    saving: false,
    error: action.error,
  }),

  [CONST.JOURNAL_SUMMARY_CLOSE_CONFIRMATION]: (state, action) => ({
    ...state,
    reversalConfirmationVisible: false,
  }),
  // JOURNAL_SUMMARY_CLOSE_CONFIRMATION

  [CONST.JOURNAL_SUMMARY_DOWNLOAD_START]: state => ({
    ...state,
    loading: true,
    error: null,
  }),

  [CONST.JOURNAL_SUMMARY_DOWNLOAD_SUCCESS]: (state, action) => ({
    ...state,
    downloadJournalUrl: action.payload.journalURL,
    loading: false,
  }),

  [CONST.JOURNAL_SUMMARY_DOWNLOAD_FAILURE]: state => ({
    ...state,
    downloadJournalUrl: null,
    loading: false,
  }),

  [CONST.JOURNAL_SUMMARY_DOWNLOAD_RESET]: state => ({
    ...state,
    downloadJournalUrl: null,
  }),
});
