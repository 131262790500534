import React from 'react';
import PropTypes from 'prop-types';
import '@amzn/awsui-components-react/index.css';
import { ColumnLayout, Button } from '@amzn/awsui-components-react';

/*
 * ActionStrip for submit/clear the form
 */
function ActionStrip({ handleClear, handleSubmit }) {
  return (
    <ColumnLayout columns={1}>
      <div data-awsui-column-layout-root>
        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-title" />
          <div className="awsui-util-action-stripe-group">
            <Button text="Clear" type="submit" onClick={handleClear} />
            <Button text="Submit" type="submit" variant="primary" onClick={handleSubmit} />
          </div>
        </div>
      </div>
    </ColumnLayout>
  );
}

ActionStrip.propTypes = {
  handleClear: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

ActionStrip.defaultProps = {};

export default ActionStrip;
