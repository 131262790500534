import React from 'react';
import PropTypes from 'prop-types';
import CustomProps from 'common/prop-types';
import '@amzn/awsui-components-react/index.css';
import 'react-table/react-table.css';
import ReactTable from 'react-table';


import ReactTooltip from 'react-tooltip';
import {
  Button, Modal, RadioButton, Select,
} from '@amzn/awsui-components-react';

import MJEModal from 'common/components/mjeModal/MJEModal';
import CONSTANT from 'utils/constant';
import Logger from 'utils/logger';
import FORMATTERS from 'utils/formatUtils';
import SearchModal from './SearchModal';
import styles from '../LandingPage.scss';
import { clearSession, getFromSession } from '../../../utils/storage';
import { getSearchPayload } from '../../../actions';

const myDashboardColumnsFilter = ({ filter, onChange }) => (
  <input
    placeholder="Filter"
    className={styles.filterInput}
    onChange={event => onChange(event.target.value)}
    value={filter ? filter.value : ''}
  />
);


const RADIO_BUTTON_COLUMN_ID = 999;
let columnItems = [
  {
    Header: '#',
    id: 0,
    width: 40,
    Cell: row => <span>{row.index + 1}</span>,
    headerClassName: styles.leftAlignContent,
    filterable: false,
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 90,
    headerClassName: styles.leftAlignContent,
    Filter: myDashboardColumnsFilter,
  },
  {
    Header: 'Preparer',
    accessor: 'prepare_user',
    headerClassName: styles.leftAlignContent,
    Filter: myDashboardColumnsFilter,
  },
  {
    Header: 'Upload Name',
    accessor: 'upload_name',
    headerClassName: styles.leftAlignContent,
    Filter: myDashboardColumnsFilter,
  },
  {
    Header: 'Journal Name',
    accessor: 'journal_name',
    headerClassName: styles.leftAlignContent,
    Filter: myDashboardColumnsFilter,
  },
  {
    Header: 'Accounting Date',
    accessor: 'accounting_date',
    width: 130,
    headerClassName: styles.leftAlignContent,
    Filter: myDashboardColumnsFilter,
  },
  {
    Header: 'Entered Currency',
    accessor: 'journal_currency',
    width: 130,
    headerClassName: styles.leftAlignContent,
    Filter: myDashboardColumnsFilter,
  },
  {
    Header: 'Entered Amount',
    accessor: 'entered_dr',
    width: 130,
    headerClassName: styles.leftAlignContent,
    Filter: myDashboardColumnsFilter,
    Cell: cellInfo => <span>{FORMATTERS.currencyTextFormatter(cellInfo.value)}</span>,
  },
  {
    Header: 'Status',
    accessor: 'processing_status',
    headerClassName: styles.leftAlignContent,
    Filter: myDashboardColumnsFilter,
  },
  {
    show: false,
    Header: 'Journal ID',
    accessor: 'journal_header_id',
  },
];

const actionIds = Object.freeze({
  NONE: '0',
  REVERSE_ENTRY: '1',
  COPY_JOURNAL: '2',
  COPY_JOURNAL_BATCH: '3',
  COPY_COMPLETE_UPLOAD: '4',
  VIEW_JOURNAL: '5',
  VIEW_JOURNAL_BATCH: '6',
  VIEW_COMPLETE_UPLOAD: '7',
  VIEW_INVOICE: '8',
  VIEW_JOURNAL_SUMMARY: '9',
  DELETE: '10',
  DOWNLOAD_WORKBOOK: '11',
});


const actions = [
  {
    id: actionIds.NONE,
    label: 'Select Action',
  },
  {
    id: actionIds.REVERSE_ENTRY,
    label: 'Reverse Entry',
  },
  {
    id: actionIds.COPY_JOURNAL,
    label: 'Copy Journal',
  },
  {
    id: actionIds.COPY_JOURNAL_BATCH,
    label: 'Copy Journal Batch',
  },
  {
    id: actionIds.COPY_COMPLETE_UPLOAD,
    label: 'Copy Complete Upload',
  },
  {
    id: actionIds.VIEW_COMPLETE_UPLOAD,
    label: 'View Complete Upload',
  },
  {
    id: actionIds.VIEW_JOURNAL_SUMMARY,
    label: 'View Journal Summary',
  },
  {
    id: actionIds.VIEW_INVOICE,
    label: 'View Invoice',
  },
  {
    id: actionIds.DELETE,
    label: 'Delete Complete Upload',
  },
  {
    id: actionIds.DOWNLOAD_WORKBOOK,
    label: 'Download Complete Upload',
  },
];

class MyMJEDashboard extends React.Component {
  static propTypes = {
    isSearching: PropTypes.bool,

    items: PropTypes.arrayOf(CustomProps.MJESearchItem),
    // filterable: PropTypes.bool,

    onNewSearch: PropTypes.func,
    onNewRedirectRequest: PropTypes.func,
    onCopyFileRequest: PropTypes.func,
    onDeleteWorkbookRequest: PropTypes.func,
    onDownloadWorkbookRequest: PropTypes.func,
    onDownloadInvoiceRequest: PropTypes.func,

    username: PropTypes.string,
  };

  static defaultProps = {
    isSearching: false,
    // filterable: true,
    items: [],
    onNewSearch: () => { },
    onNewRedirectRequest: () => { },
    onCopyFileRequest: () => { },
    onDeleteWorkbookRequest: () => { },
    onDownloadWorkbookRequest: () => { },
    onDownloadInvoiceRequest: () => { },
    username: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      showSearch: false,
      enableSearchUndo: getFromSession('searchModalState') != null,
      confirmSearchUndo: false,
      showDashboardFilter: false,
      selectedAction: actions[0],
      selectedJournal: null,
      localError: null,
      confirmDeleteWorkbook: false,
    };

    this.buildMyDashboardHeaderColumns = this.buildMyDashboardHeaderColumns.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleSearchClose = this.handleSearchClose.bind(this);
    this.handleSearchGo = this.handleSearchGo.bind(this);
    this.handleShowRestoreDefaultsDialog = this.handleShowRestoreDefaultsDialog.bind(this);
    this.selectJournal = this.selectJournal.bind(this);
    this.selectAction = this.selectAction.bind(this);
    this.handleExecuteAction = this.handleExecuteAction.bind(this);
    this.handleRestoreDefaults = this.handleRestoreDefaults.bind(this);
    this.handleShowRestoreDefaultsDialogCancelled = this.handleShowRestoreDefaultsDialogCancelled
      .bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.handleErrorModalClosed = this.handleErrorModalClosed.bind(this);
    this.deleteWorkbook = this.deleteWorkbook.bind(this);
    this.handleConfirmDeletionCancelled = this.handleConfirmDeletionCancelled.bind(this);
    this.handleDeletionConfirmed = this.handleDeletionConfirmed.bind(this);
  }

  componentDidMount() {
    const { onNewSearch } = this.props;
    this.buildMyDashboardHeaderColumns();

    const stateContainer = getFromSession('searchModalState');
    if (stateContainer) {
      const { state } = stateContainer;
      if (state && state && state.searchItems && state.accountingStartDate
        && state.accountingEndDate && state.dynamicAttribute
        && (state.dynamicAttributeValue != null || state.dynamicAttributeValue == '')
        && stateContainer.primaryAttributes) {
        onNewSearch(getSearchPayload(state.searchItems,
          state.accountingStartDate,
          state.accountingEndDate,
          state.dynamicAttribute,
          state.dynamicAttributeValue,
          stateContainer.primaryAttributes));
        return;
      }
    }
    onNewSearch(null);
  }

  toggleFilter() {
    const { showDashboardFilter } = this.state;
    this.setState(() => ({ showDashboardFilter: !showDashboardFilter }));
  }

  selectJournal(journal) {
    this.setState(() => ({ selectedJournal: journal }));
  }

  selectAction(selectedAction) {
    this.setState(() => ({ selectedAction }));
  }

  buildMyDashboardHeaderColumns() {
    if (columnItems.find(x => x.id === RADIO_BUTTON_COLUMN_ID) != null) {
      columnItems = columnItems.filter(x => x.id !== RADIO_BUTTON_COLUMN_ID);
    }
    columnItems = [
      ...columnItems,
      {
        id: RADIO_BUTTON_COLUMN_ID,
        Header: 'Action',
        Cell: row => (
          <RadioButton
            type="radio"
            className="awsui-util-ml-m"
            value={row.original.journal_header_id.toString()}
            label=""
            checked={this.state.selectedJournal != null && this.state.selectedJournal.journal_header_id == row.original.journal_header_id}
            name="journalSelector"
            onChange={() => this.selectJournal(row.original)}
          />
        ),
        sortable: false,
        filterable: false,
        width: 60,
      },
    ];
  }

  handleSearchClick() {
    this.setState(() => ({ showSearch: true }));
  }

  handleSearchClose() {
    this.setState(() => ({ showSearch: false }));
  }

  handleSearchGo(searchPayload) {
    const { onNewSearch } = this.props;
    onNewSearch(searchPayload);

    this.handleSearchClose();
    this.setState(() => ({
      confirmSearchUndo: false,
      enableSearchUndo: true,
    }));
  }

  handleShowRestoreDefaultsDialog() {
    this.setState(() => ({ confirmSearchUndo: true }));
  }

  handleErrorModalClosed() {
    this.setState(() => ({ localError: null }));
  }

  handleRestoreDefaults() {
    clearSession();
    window.location.reload(false);

    this.setState(() => ({
      confirmSearchUndo: false,
      enableSearchUndo: false,
      showDashboardFilter: false,
    }));
    const { onNewSearch } = this.props;
    onNewSearch(null);
  }

  handleShowRestoreDefaultsDialogCancelled() {
    this.setState(() => ({ confirmSearchUndo: false }));
  }

  handleExecuteAction() {
    const { selectedAction } = this.state;
    switch (selectedAction.id) {
      case actionIds.REVERSE_ENTRY:
      case actionIds.VIEW_COMPLETE_UPLOAD:
        this.openWorkbook();
        break;
      case actionIds.VIEW_JOURNAL_BATCH:
        this.openBatch();
        break;
      case actionIds.VIEW_JOURNAL:
        this.openJournal();
        break;
      case actionIds.COPY_COMPLETE_UPLOAD:
        this.copyWorkbook();
        break;
      case actionIds.COPY_JOURNAL_BATCH:
        this.copyBatch();
        break;
      case actionIds.COPY_JOURNAL:
        this.copyJournal();
        break;
      case actionIds.VIEW_JOURNAL_SUMMARY:
        this.viewJournalSummary();
        break;
      case actionIds.DELETE:
        this.deleteWorkbook();
        break;
      case actionIds.DOWNLOAD_WORKBOOK:
        this.downloadWorkbook();
        break;
      case actionIds.VIEW_INVOICE:
        this.viewInvoice();
        break;
      default:
    }
  }

  openWorkbook() {
    const { selectedJournal } = this.state;
    const { FILE_REQUEST_TYPES } = CONSTANT;
    const { onNewRedirectRequest } = this.props;
    const requestId = selectedJournal.workbook_id;
    onNewRedirectRequest(`/MJE/Validation/${FILE_REQUEST_TYPES.WORKBOOK}/${requestId}`);
  }

  openBatch() {
    const { selectedJournal } = this.state;
    const { FILE_REQUEST_TYPES } = CONSTANT;
    const { onNewRedirectRequest } = this.props;
    const requestId = selectedJournal.journal_batch_id;
    onNewRedirectRequest(`/MJE/Validation/${FILE_REQUEST_TYPES.BATCH}/${requestId}`);
  }

  openJournal() {
    const { selectedJournal } = this.state;
    const { FILE_REQUEST_TYPES } = CONSTANT;
    const { onNewRedirectRequest } = this.props;
    const requestId = selectedJournal.journal_header_id;

    onNewRedirectRequest(`/MJE/Validation/${FILE_REQUEST_TYPES.JOURNAL}/${requestId}`);
  }

  copyWorkbook() {
    const { selectedJournal } = this.state;
    const { FILE_REQUEST_TYPES } = CONSTANT;
    const { onCopyFileRequest, username } = this.props;
    const requestId = selectedJournal.workbook_id;
    onCopyFileRequest({
      requestType: FILE_REQUEST_TYPES.WORKBOOK,
      requestId,
      userName: username,
    });

    clearSession();
  }

  copyBatch() {
    const { selectedJournal } = this.state;
    const { FILE_REQUEST_TYPES } = CONSTANT;
    const { onCopyFileRequest, username } = this.props;
    const requestId = selectedJournal.journal_batch_id;
    onCopyFileRequest({
      requestType: FILE_REQUEST_TYPES.BATCH,
      requestId,
      userName: username,
    });

    clearSession();
  }

  copyJournal() {
    const { selectedJournal } = this.state;
    const { FILE_REQUEST_TYPES } = CONSTANT;
    const { onCopyFileRequest, username } = this.props;
    const requestId = selectedJournal.journal_header_id;
    onCopyFileRequest({
      requestType: FILE_REQUEST_TYPES.JOURNAL,
      requestId,
      userName: username,
    });

    clearSession();
  }

  viewJournalSummary() {
    const { selectedJournal } = this.state;
    const { onNewRedirectRequest } = this.props;
    const { journal_header_id } = selectedJournal;
    const { JOURNAL_STATUS } = CONSTANT;

    if (selectedJournal.processing_status === JOURNAL_STATUS.SYSTEM_SAVED
      || selectedJournal.processing_status === JOURNAL_STATUS.USER_SAVED
      || selectedJournal.processing_status === JOURNAL_STATUS.FUTURE_REVERSAL) {
      this.setState(() => ({ localError: 'Journal Summary is only available for type "accepted" ' }));
      return;
    }
    onNewRedirectRequest(`/MJE/PrimeJournalSummary/${journal_header_id}`);
  }

  viewInvoice() {
    const { selectedJournal } = this.state;
    const { onDownloadInvoiceRequest } = this.props;
    const { JOURNAL_STATUS } = CONSTANT;
    if (selectedJournal.processing_status !== JOURNAL_STATUS.GL_POSTED) {
      this.setState(() => ({ localError: 'Journal Invoice is only available for type "accepted"' }));
      return;
    }
    if (
      selectedJournal.invoice_failure_reason == null
      && selectedJournal.invoice_header_id == null
    ) {
      this.setState(() => ({ localError: 'This Journal has not been invoiced yet. Please try again later' }));
      return;
    }
    onDownloadInvoiceRequest({
      invoice_header_id: selectedJournal.invoice_header_id,
      invoice_number: selectedJournal.invoice_number,
      invoice_failure_reason: selectedJournal.invoice_failure_reason,
    });
  }

  deleteWorkbook() {
    const { selectedJournal } = this.state;
    const { JOURNAL_STATUS } = CONSTANT;

    if (selectedJournal.processing_status !== JOURNAL_STATUS.SYSTEM_SAVED
      && selectedJournal.processing_status !== JOURNAL_STATUS.USER_SAVED
      && selectedJournal.processing_status !== JOURNAL_STATUS.FUTURE_REVERSAL) {
      this.setState(() => ({ localError: 'Delete unsuccessful...cannot delete type "accepted"' }));
      return;
    }
    this.setState(() => ({ confirmDeleteWorkbook: true }));
  }

  handleConfirmDeletionCancelled() {
    this.setState(() => ({ confirmDeleteWorkbook: false }));
  }

  handleDeletionConfirmed() {
    const { onDeleteWorkbookRequest } = this.props;
    const { selectedJournal } = this.state;
    onDeleteWorkbookRequest(selectedJournal.workbook_id);
    this.setState(() => ({ confirmDeleteWorkbook: false, selectedJournal: null }));
  }

  downloadWorkbook() {
    const { selectedJournal } = this.state;
    const { onDownloadWorkbookRequest } = this.props;
    onDownloadWorkbookRequest(selectedJournal.workbook_id);
  }

  render() {
    const {
      items, isSearching, username,
    } = this.props;
    const {
      enableSearchUndo,
      showSearch,
      selectedJournal,
      selectedAction,
      showDashboardFilter,
      confirmSearchUndo,
      localError,
      confirmDeleteWorkbook,
    } = this.state;

    return (
      <React.Fragment>
        <div className="awsui-util-container">
          <div className="awsui-util-container-header">
            <div className="awsui-util-action-stripe">
              <div className="awsui-util-action-stripe-title">
                <h2>
                  My MJE Dashboard&nbsp;
                  <span className="awsui-util-header-counter">{`(${items.length})`}</span>
                </h2>
              </div>
              <div className="awsui-util-action-stripe-group">
                <Button
                  icon="filter"
                  iconAlign="right"
                  className={`button-${showDashboardFilter ? 'active' : ''}`}
                  onClick={this.toggleFilter}
                >
                  Filter Rows
                </Button>
                <Button
                  icon="search"
                  iconAlign="right"
                  data-tip="Search for any MJEs"
                  onClick={this.handleSearchClick}
                >
                  Search
                </Button>
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  effect="solid"
                  event="mouseover"
                  eventOff="mouseout"
                />
              </div>
            </div>
          </div>
          <div data-awsui-column-layout-root className="scroll-y-pane my-dashboard-grid-pane">
            <ReactTable
              data={items}
              minRows={0}
              columns={columnItems}
              sortable
              defaultSorted={[
                {
                  id: "journal_header_id",
                  desc: true
                }
              ]}
              filterable={showDashboardFilter}
              loadingText="Searching..."
              wrapLines={false}
              resizableColumns
              stickyHeader
              loading={isSearching}
              showPagination={items != null && items.length > 50}
              defaultPageSize={50}
              noMatch="noMatchContent"
            />
          </div>
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <Button
                disabled={!enableSearchUndo || isSearching}
                onClick={this.handleShowRestoreDefaultsDialog}
              >
                Restore Defaults
              </Button>
              <MJEModal
                visible={confirmSearchUndo}
                header="Please Confirm"
                content="Restore the defaults to your dashboard?"
                actionType="YES_NO"
                onYes={this.handleRestoreDefaults}
                onNo={this.handleShowRestoreDefaultsDialogCancelled}
              />
            </div>
            <div className={`awsui-util-action-stripe-group ${styles.executeActionWrapper}`}>
              <Select
                options={actions}
                selectedOption={selectedAction}
                onChange={e => this.selectAction(e.detail.selectedOption)}
              />
              <Button
                onClick={this.handleExecuteAction}
                disabled={!(selectedJournal != null && selectedAction.id !== actionIds.NONE)}
              >
                Execute Action
              </Button>
            </div>
          </div>
        </div>
        <SearchModal
          handleSearchGo={this.handleSearchGo}
          handleSearchClose={this.handleSearchClose}
          visible={showSearch}
        />
        <Modal
          visible={localError != null}
          size="small"
          header="Error"
          onDismiss={this.handleErrorModalClosed}
        >
          <div className="awsui-util-t-c">{localError}</div>
        </Modal>
        {confirmDeleteWorkbook && (
          <MJEModal
            visible={confirmDeleteWorkbook}
            header="Please Confirm"
            content={`Please Confirm! Delete Progress of ${selectedJournal.workbook_name}?`}
            actionType="YES_NO"
            onYes={this.handleDeletionConfirmed}
            onNo={this.handleConfirmDeletionCancelled}
          />
        )}
      </React.Fragment>
    );
  }
}

export default MyMJEDashboard;
