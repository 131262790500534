/* eslint-disable class-methods-use-this */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, ColumnLayout, Modal } from '@amzn/awsui-components-react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

import CONSTANT from 'utils/constant';
import Logger from 'utils/logger';
import * as actions from 'actions/validationUI';
import MJEBanner from 'common/components/mjeBanner/MJEBanner';
import MJEModal from 'common/components/mjeModal/MJEModal';
import CustomProps from 'common/prop-types';
import { LoadingSpinner } from 'common/components';

import ValidationUIFileSummary from './components/ValidationUIStatistics';
import ValidationUIReversal from './components/ValidationUIReversal';
import ValidationUIDataGrid from './components/ValidationUIDataGrid';
import './ValidationUI.scss';
import TaxAutomationModal from './components/TaxAutomationModal';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';

const allTransactionsReversed = (reversalsMap) => {
  if (reversalsMap == null || reversalsMap.entries().length === 0) {
    return false;
  }
  let result = true;
  for (let [batchName, reversalsInBatch] of reversalsMap) {
    if (!reversalsInBatch.every(r => r.journalName === 'All Journals' || r.reversed)){
      return false;
    }
  }
  return result;
};
class ValidationUI extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.string,
    workbookName: PropTypes.string,
    statistics: PropTypes.shape({
      numJournalBatches: PropTypes.number,
      numJournalLines: PropTypes.number,
      numErrorRows: PropTypes.number,
      numErrors: PropTypes.number,
      numAutomatedLines: PropTypes.number,
      accountingDate: PropTypes.string,
      netEntered: PropTypes.number,
      netAccounted: PropTypes.number,
      numAgreements: PropTypes.number,
      numCurrencies: PropTypes.number,
    }),
    reversal: PropTypes.instanceOf(Map),
    reversalAllJournals: PropTypes.shape({
      journalName: PropTypes.string,
      periods: PropTypes.arrayOf(PropTypes.shape({
        displayValue: PropTypes.string,
        lastDayOfMonth: PropTypes.string,
      })),
      methods: PropTypes.arrayOf(PropTypes.shape({
        displayName: PropTypes.string,
        value: PropTypes.string,
      })),
      selected: PropTypes.bool,
      selectedPeriod: PropTypes.string,
      selectedMethod: PropTypes.string,
      disabled: PropTypes.bool,
    }),
    dataGridColumns: PropTypes.arrayOf(
      PropTypes.shape({
        seqNum: PropTypes.number,
        header: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.oneOf(['required', 'conditional', 'system']),
      }),
    ),
    dataGrid: PropTypes.arrayOf(
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
          PropTypes.arrayOf(PropTypes.string),
          PropTypes.objectOf(PropTypes.arrayOf(
            PropTypes.objectOf(PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.bool,
            ])),
          )),
          PropTypes.objectOf(PropTypes.bool),
        ]),
      ),
    ),

    history: CustomProps.history(),
    match: CustomProps.match({
      requestType: PropTypes.string,
      requestId: PropTypes.string,
    }),
    location: CustomProps.location({
      displayTaxLines: PropTypes.bool,
    }),
    // TODO: Define Posting Summary shape
    // eslint-disable-next-line react/forbid-prop-types
    postingSummary: PropTypes.object,

    noTaxAutomation: PropTypes.bool,
    indirectTaxSummary: PropTypes.arrayOf(PropTypes.shape({
      index: PropTypes.number,
      journal_batch_name: PropTypes.string,
      ic_transaction_group: PropTypes.string,
      journal_name: PropTypes.string,
      indirect_tax_rate: PropTypes.string,
      numLinesCreated: PropTypes.number,
    })),

    successfulSaveDate: PropTypes.instanceOf(Date),
    downloadWorkbookUrl: PropTypes.string,
    username: PropTypes.string,

    isPosting: PropTypes.bool,
    isSaving: PropTypes.bool,
    needsRevalidate: PropTypes.bool,
    needsTaxGeneration: PropTypes.bool,
    workbookDeleted: PropTypes.bool,
    exitedWorkbook: PropTypes.bool,

    fetchInitialData: PropTypes.func,
    editReversal: PropTypes.func,
    setReversal: PropTypes.func,
    undoReversal: PropTypes.func,
    editDataGrid: PropTypes.func,
    revalidateDataGrid: PropTypes.func,
    postFile: PropTypes.func,
    clearPostingSummary: PropTypes.func,
    downloadDataGrid: PropTypes.func,
    downloadDataGridReset: PropTypes.func,
    exitWorkbook: PropTypes.func,
    exitWorkbookReset: PropTypes.func,
    saveWorkbook: PropTypes.func,
    saveWorkbookReset: PropTypes.func,
    saveWorkbookName: PropTypes.func,
    deleteRow: PropTypes.func,
    addRow: PropTypes.func,
    generateTaxLines: PropTypes.func,
    deleteWorkbook: PropTypes.func,
    deleteWorkbookReset: PropTypes.func,
  };

  static defaultProps = {
    loading: false,
    error: null,
    workbookName: '',
    statistics: {},
    reversal: new Map(),
    dataGridColumns: [],
    dataGrid: [],
    postingSummary: null,
    downloadWorkbookUrl: null,
    noTaxAutomation: null,
    indirectTaxSummary: [],
    reversalAllJournals: null,

    successfulSaveDate: '',
    username: '',

    isPosting: false,
    isSaving: false,
    needsRevalidate: true,
    needsTaxGeneration: true,
    workbookDeleted: false,
    exitedWorkbook: false,

    fetchInitialData: () => { },
    editReversal: () => { },
    setReversal: () => { },
    undoReversal: () => { },
    editDataGrid: () => { },
    revalidateDataGrid: () => { },
    postFile: () => { },
    clearPostingSummary: () => { },
    downloadDataGrid: () => { },
    downloadDataGridReset: () => { },
    exitWorkbook: () => { },
    exitWorkbookReset: () => { },
    saveWorkbook: () => { },
    saveWorkbookReset: () => { },
    saveWorkbookName: () => { },
    deleteRow: () => { },
    addRow: () => { },
    generateTaxLines: () => { },
    deleteWorkbook: () => { },
    deleteWorkbookReset: () => { },

    history: {},
    match: {},
    location: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      spinnerText: 'Loading...',
      showCancelModal: false,
      showTaxModal: false,
      inTaxMode: false,
    };
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onExitClick = this.onExitClick.bind(this);
    this.onDownloadClick = this.onDownloadClick.bind(this);
    this.onRevalidateClick = this.onRevalidateClick.bind(this);
    this.onGenerateTaxLines = this.onGenerateTaxLines.bind(this);
    this.onBackToEdit = this.onBackToEdit.bind(this);
    this.onViewTaxLines = this.onViewTaxLines.bind(this);
    this.onPostConfirm = this.onPostConfirm.bind(this);
    this.onDeleteEntry = this.onDeleteEntry.bind(this);
    this.onDismissCancelModal = this.onDismissCancelModal.bind(this);
    this.onDismissTaxModal = this.onDismissTaxModal.bind(this);
    this.onSaveWorkbookNameClick = this.onSaveWorkbookNameClick.bind(this);
    this.onDeleteRow = this.onDeleteRow.bind(this);
    this.onAddRow = this.onAddRow.bind(this);
  }

  componentDidMount() {
    const {
      match,
      location,
      fetchInitialData,
      clearPostingSummary,
    } = this.props;
    const { requestType, requestId } = match.params;
    // Set default value for displayTaxLines as false if location.state is not provided
    const { displayTaxLines = false } = (location && location.state) ? location.state : {};

    clearPostingSummary();
    this.setState({ inTaxMode: displayTaxLines });
    fetchInitialData(requestType, requestId, displayTaxLines);
  }

  componentDidUpdate() {
    const {
      postingSummary, history, successfulSaveDate, saveWorkbookReset, downloadWorkbookUrl,
      downloadDataGridReset, workbookDeleted, deleteWorkbookReset, exitedWorkbook, exitWorkbookReset
    } = this.props;
    if (window.location.href.includes('/MJE1_1')) {
      if (postingSummary && postingSummary.batchSummaries) {
        history.push('/MJE1_1/PostSuccess');
      }
      if (successfulSaveDate != null) {
        saveWorkbookReset();
        history.push('/MJE1_1');
      }
      if (downloadWorkbookUrl !== null) {
        window.open(downloadWorkbookUrl);
        downloadDataGridReset();
      }
      if (workbookDeleted) {
        deleteWorkbookReset();
        history.push('/MJE1_1');
      }
      if (exitedWorkbook) {
        exitWorkbookReset();
        history.push('/MJE1_1');
      }
    } else {
      if (postingSummary && postingSummary.batchSummaries) {
        history.push('/mje/PostSuccess');
      }
      if (successfulSaveDate != null) {
        saveWorkbookReset();
        history.push('/');
      }
      if (downloadWorkbookUrl !== null) {
        window.open(downloadWorkbookUrl);
        downloadDataGridReset();
      }
      if (workbookDeleted) {
        deleteWorkbookReset();
        history.push('/');
      }
      if (exitedWorkbook) {
        exitWorkbookReset();
        history.push('/');
      }
    }
  }

  onCancelClick() {
    this.setState({ showCancelModal: true });
  }

  onExitClick() {
    const { exitWorkbook } = this.props;
    this.setState(() => ({ spinnerText: 'Exiting...' }));
    exitWorkbook();
  }

  onSaveClick() {
    const { saveWorkbook, username } = this.props;
    this.setState(() => ({ spinnerText: 'Saving...' }));
    saveWorkbook(username);
  }

  onSaveWorkbookNameClick(workbookName) {
    const { saveWorkbookName, username, match } = this.props;
    const { requestType, requestId } = match.params;
    saveWorkbookName({
      userName: username,
      requestType,
      requestID: requestId,
      workbookName,
    });
  }

  onDownloadClick(event) {
    Logger.logInfo(event);
    const { match, downloadDataGrid } = this.props;
    const { requestType, requestId } = match.params;
    this.setState(() => ({ spinnerText: 'Downloading...' }));
    downloadDataGrid(requestType, requestId);
  }

  onRevalidateClick() {
    const { match } = this.props;
    const { requestId } = match.params;
    const { revalidateDataGrid } = this.props;
    this.setState(() => ({ spinnerText: 'Validating...' }));
    revalidateDataGrid(requestId);
  }

  onGenerateTaxLines() {
    const {
      match, generateTaxLines, revalidateDataGrid, needsTaxGeneration,
    } = this.props;
    this.setState({ showTaxModal: true });
    if (needsTaxGeneration) {
      const { requestId } = match.params;
      const context = this;
      this.state = {
        spinnerText: 'Revalidating...',
      };

      revalidateDataGrid(requestId, (errors) => {
        if (errors !== null) {
          if (!errors) {
            context.setState({
              spinnerText: 'Generating tax lines...',
            });
            generateTaxLines(requestId);
          } else {
            context.setState({
              spinnerText: '',
            });
          }
        }
      });
    }
  }

  onBackToEdit() {
    const { inTaxMode } = this.state;
    const { match, fetchInitialData } = this.props;
    const { requestType, requestId } = match.params;

    if (inTaxMode) {
      this.setState({ showTaxModal: false, showCancelModal: false, inTaxMode: false });
      fetchInitialData(requestType, requestId, false);
    } else {
      this.setState({ showTaxModal: false });
    }
  }

  onViewTaxLines() {
    const { inTaxMode } = this.state;
    const { match, fetchInitialData } = this.props;
    const { requestType, requestId } = match.params;

    if (inTaxMode) {
      this.setState({ showTaxModal: false });
    } else {
      this.setState({ showTaxModal: false, inTaxMode: true });
      fetchInitialData(requestType, requestId, true);
    }
  }

  onPostConfirm() {
    const { match, postFile, username } = this.props;
    const { requestType, requestId } = match.params;
    this.setState(() => ({ spinnerText: 'Posting file...', showTaxModal: false }));
    postFile({
      userName: username,
      requestType,
      requestID: requestId,
    });
  }

  onDeleteEntry() {
    const { deleteWorkbook, match } = this.props;
    const { requestId } = match.params;
    this.setState({ showCancelModal: false, spinnerText: 'Deleting workbook...' });
    deleteWorkbook(requestId);
  }

  onDismissCancelModal() {
    this.setState({ showCancelModal: false });
  }

  onDismissTaxModal() {
    this.setState({ showTaxModal: false });
  }

  onDeleteRow(payload) {
    const { deleteRow } = this.props;
    this.setState(() => ({ spinnerText: 'Deleting line...' }));
    deleteRow(payload);
  }

  onAddRow(row, type) {
    const { addRow } = this.props;
    let waitingMessage = '';
    switch (type) {
      case 'ROW':
        waitingMessage = 'Adding line to journal...';
        break;
      case 'JOURNAL':
        waitingMessage = 'Adding journal to batch...';
        break;
      default:
        waitingMessage = 'Adding line...';
        break;
    }
    this.setState(() => ({ spinnerText: waitingMessage }));
    addRow(row, type);
  }



  renderActionButtons() {
    const {
      statistics, dataGrid, needsRevalidate, reversal,
    } = this.props;
    const { inTaxMode } = this.state;
    const dataGridHasErrors = statistics.numErrors === undefined || statistics.numErrors > 0;
    const { JOURNAL_STATUS } = CONSTANT;

    const canGenerateTaxLines = (items, hasErrors) => {
      if (items == null || items.length === 0) {
        return false;
      }
      return !hasErrors && !items.some(rowData => rowData.processing_status === JOURNAL_STATUS.NO_APPROVER) && items.some(rowData => rowData.processing_status === JOURNAL_STATUS.SYSTEM_SAVED || rowData.processing_status === JOURNAL_STATUS.USER_SAVED);
    };



    const hasStatus = (items, ...statuses) => {
      if (items == null || items.length < 1 || statuses == null || statuses.length < 1) {
        return false;
      }
      return items.some((rowData) => {
        for (let i = 0; i < statuses.length; i++) {
          const status = statuses[i];
          if (rowData.processing_status === status) {
            return true;
          }
        }
        return false;
      });
    };

    const isLocked = items => hasStatus(items, JOURNAL_STATUS.GL_PROCESSING, JOURNAL_STATUS.GL_POSTED, JOURNAL_STATUS.GL_FAILED);

    return (
      <ColumnLayout columns={2}>
        <div data-awsui-column-layout-root="true">
          <div>
            <Button
              text="Cancel & Exit"
              onClick={this.onCancelClick}
              disabled={isLocked(dataGrid)}
              data-tip="Cancel Entry And Exit"
              data-for="action-buttons"
            />
            {!hasStatus(dataGrid, JOURNAL_STATUS.GL_PROCESSING, JOURNAL_STATUS.GL_POSTED) && (
            <Button
              text="Save & Exit"
              onClick={this.onSaveClick}
              disabled={hasStatus(dataGrid, JOURNAL_STATUS.FUTURE_REVERSAL) || inTaxMode}
              data-tip="Save Progress And Go To Dashboard"
              data-for="action-buttons"
            />
            )}
            {hasStatus(dataGrid, JOURNAL_STATUS.GL_PROCESSING, JOURNAL_STATUS.GL_POSTED) && (
            <Button
              text="Reverse & Exit"
              onClick={this.onSaveClick}
              disabled={allTransactionsReversed(reversal)}
              data-tip="Reverse Transactions And Go To Dashboard"
              data-for="action-buttons"
            />
            )}
            {isLocked(dataGrid) && (
            <Button
              text="Exit"
              onClick={this.onExitClick}
              data-tip="Return to landing page"
              data-for="action-buttons"
            />
            )}
            <Button
              text="Download"
              onClick={this.onDownloadClick}
              disabled={inTaxMode}
              data-tip="Download Complete Entry With Errors"
              data-for="action-buttons"
            />
          </div>
          <div>
            <Button
              text="Generate Tax Lines"
              variant="primary"
              className="awsui-util-f-r awsui-util-ml-m"
              disabled={!canGenerateTaxLines(dataGrid, dataGridHasErrors) || needsRevalidate}
              onClick={this.onGenerateTaxLines}
              data-tip={
                dataGridHasErrors
                  ? 'Correct Errors Before Generating Tax Lines'
                  : 'Generate Tax Lines'
              }
              data-for="action-buttons"
            />
            <Button
              text="Revalidate"
              className="awsui-util-f-r"
              disabled={isLocked(dataGrid) || inTaxMode || hasStatus(dataGrid, JOURNAL_STATUS.FUTURE_REVERSAL)}
              onClick={this.onRevalidateClick}
              data-tip="Recheck Your Entry"
              data-for="action-buttons"
            />
          </div>
          <ReactTooltip
            id="action-buttons"
            place="top"
            type="dark"
            effect="solid"
            event="mouseenter"
            eventOff="mouseleave"
          />
        </div>
      </ColumnLayout>
    );
  }

  renderCancelModal() {
    const { workbookName } = this.props;
    const { showCancelModal, inTaxMode } = this.state;
    return (
      <Modal
        visible={showCancelModal}
        header="Please confirm!"
        content={`Warning: Delete progress of any non-posted journals in ${workbookName}?`}
        footer={(
          <div>
            <Button text="No, go back" onClick={this.onDismissCancelModal} />
            <Button text="Yes, delete entry" variant="primary" onClick={this.onDeleteEntry} className="awsui-util-f-r" />
            {inTaxMode && <Button text="Remove auto lines" onClick={this.onBackToEdit} className="awsui-util-f-r" />}
          </div>
        )}
        onDismiss={this.onDismissCancelModal}
      />
    );
  }

  renderTaxModal() {
    const { showTaxModal } = this.state;
    const { workbookName, indirectTaxSummary, noTaxAutomation } = this.props;
    return (
      <TaxAutomationModal
        visible={showTaxModal && (noTaxAutomation
            || (indirectTaxSummary !== undefined && indirectTaxSummary.length > 0))}
        workbookName={workbookName}
        indirectTaxSummary={indirectTaxSummary}
        noTaxAutomation={noTaxAutomation}
        onClickBack={this.onBackToEdit}
        onClickView={this.onViewTaxLines}
        onClickPost={this.onPostConfirm}
        onDismiss={this.onDismissTaxModal}
      />
    );
  }

  render() {
    const {
      loading,
      error,
      workbookName,
      statistics,
      reversal,
      dataGridColumns,
      dataGrid,
      editReversal,
      setReversal,
      undoReversal,
      editDataGrid,
      isPosting,
      isSaving,
      match,
      reversalAllJournals,
    } = this.props;
    const { spinnerText, inTaxMode } = this.state;
    const { requestType } = match.params;
    const { FILE_REQUEST_TYPES, JOURNAL_STATUS } = CONSTANT;
    const isWorkbookEditable = (items) => {
      if (items == null || items.length === 0) {
        return false;
      }
      return !items.some(rowData => rowData.processing_status !== JOURNAL_STATUS.SYSTEM_SAVED
          && rowData.processing_status !== JOURNAL_STATUS.USER_SAVED
          && rowData.processing_status !== JOURNAL_STATUS.NO_APPROVER);
    };

    const disableReversalComponent = FILE_REQUEST_TYPES.WORKBOOK !== requestType || (!isWorkbookEditable(dataGrid) && allTransactionsReversed(reversal));

    return (
      <div>
        <MJEBanner bannerText="Step by Step Manual Journal Entry Load Form" />
        <LoadingSpinner loading={loading || isPosting} text={spinnerText} />
        <div className="validation-ui">
          <div className="validation-ui-top-container">
            <SplitterLayout secondaryInitialSize={520}>
              <div>
                <ValidationUIFileSummary
                  workbookName={workbookName}
                  statistics={statistics}
                  onClickSave={this.onSaveWorkbookNameClick}
                  canEditWorkbookName={isWorkbookEditable(dataGrid) && !inTaxMode}
                  isSaving={isSaving}
                />
              </div>
              <div>
                <ValidationUIReversal
                  transactions={reversal}
                  reversalAllJournals={reversalAllJournals}
                  onReversalChange={editReversal}
                  onSetClick={setReversal}
                  onUndoClick={undoReversal}
                  inViewMode={inTaxMode}
                  disabled={disableReversalComponent}
                  workbookIsEditable={isWorkbookEditable(dataGrid)}
                />
              </div>
            </SplitterLayout>
          </div>
          <ValidationUIDataGrid
            columns={dataGridColumns}
            items={dataGrid}
            onDataGridChange={editDataGrid}
            onDeleteGridRow={this.onDeleteRow}
            onAddGridRow={this.onAddRow}
            inViewMode={inTaxMode}
          />
        </div>
        {this.renderActionButtons()}
        {this.renderCancelModal()}
        {this.renderTaxModal()}
        <MJEModal
          visible={error !== null}
          header="Error"
          content={<div className="awsui-util-t-c">{error}</div>}
        />
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  const { validationUI } = state;
  const {
    loading,
    error,
    workbookName,
    statistics,
    reversal,
    dataGridColumns,
    dataGrid,
    postingSummary,
    isPosting,
    successfulSaveDate,
    isSaving,
    downloadWorkbookUrl,
    needsRevalidate,
    needsTaxGeneration,
    noTaxAutomation,
    indirectTaxSummary,
    workbookDeleted,
    exitedWorkbook,
    reversalAllJournals,
  } = validationUI;
  const { username } = state.auth;
  return {
    loading,
    error,
    workbookName,
    statistics,
    reversal,
    dataGridColumns,
    dataGrid,
    postingSummary,
    isPosting,
    username,
    successfulSaveDate,
    isSaving,
    downloadWorkbookUrl,
    needsRevalidate,
    needsTaxGeneration,
    noTaxAutomation,
    indirectTaxSummary,
    workbookDeleted,
    exitedWorkbook,
    reversalAllJournals,
  };
};

const mapDispatchToProps = {
  fetchInitialData: actions.fetchInitialData,
  editReversal: actions.editReversal,
  setReversal: actions.setReversal,
  undoReversal: actions.undoReversal,
  editDataGrid: actions.editDataGrid,
  revalidateDataGrid: actions.revalidateDataGrid,
  postFile: actions.postFile,
  clearPostingSummary: actions.clearPostingSummary,
  exitWorkbook: actions.exitWorkbook,
  exitWorkbookReset: actions.exitWorkbookReset,
  saveWorkbook: actions.saveWorkbook,
  saveWorkbookReset: actions.saveWorkbookReset,
  saveWorkbookName: actions.saveWorkbookName,
  downloadDataGrid: actions.downloadDataGrid,
  downloadDataGridReset: actions.downloadDataGridReset,
  deleteRow: actions.deleteRow,
  addRow: actions.addRow,
  generateTaxLines: actions.generateTaxLines,
  deleteWorkbook: actions.deleteWorkbook,
  deleteWorkbookReset: actions.deleteWorkbookReset,
};

const ValidationUIContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ValidationUI);

export default withRouter(ValidationUIContainer);
