import * as CONST from 'common/const';

const initState = {
  error: null,
  isOpen: false,
};

export default function errorReducer(state = initState, action) {
  const { globalError } = action;
  if (globalError) {
    return {
      error: globalError,
      isOpen: true,
    };
  } if (action.type === CONST.CLEAR_GLOBAL_ERROR) {
    return { error: null, isOpen: false };
  }

  return state;
}
