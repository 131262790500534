// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pages-LandingPage-components-Uploader__uploader--3XcDJ{width:80%;margin:0 auto}.pages-LandingPage-components-Uploader__uploader--3XcDJ .pages-LandingPage-components-Uploader__flexContainer--2F0E8{padding:0;margin:0;list-style:none;display:flex;justify-content:center}", ""]);
// Exports
exports.locals = {
	"uploader": "pages-LandingPage-components-Uploader__uploader--3XcDJ",
	"flexContainer": "pages-LandingPage-components-Uploader__flexContainer--2F0E8"
};
module.exports = exports;
