// Constants for Redux Action Names

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const SET_GLOBAL_ERROR = 'SET_GLOBAL_ERROR';
export const CLEAR_GLOBAL_ERROR = 'CLEAR_GLOBAL_ERROR';

export const PRIME_JOURNAL_SUMMARY_FETCH_LINES_START = 'PRIME_JOURNAL_SUMMARY_FETCH_LINES_START';
export const PRIME_JOURNAL_SUMMARY_FETCH_LINES_SUCCESS = 'PRIME_JOURNAL_SUMMARY_FETCH_LINES_SUCCESS';
export const PRIME_JOURNAL_SUMMARY_FETCH_LINES_FAILURE = 'PRIME_JOURNAL_SUMMARY_FETCH_LINES_FAILURE';

export const PRIME_JOURNAL_SUMMARY_FETCH_DATA_START = 'PRIME_JOURNAL_SUMMARY_FETCH_DATA_START';
export const PRIME_JOURNAL_SUMMARY_FETCH_DATA_SUCCESS = 'PRIME_JOURNAL_SUMMARY_FETCH_DATA_SUCCESS';
export const PRIME_JOURNAL_SUMMARY_FETCH_DATA_FAILURE = 'PRIME_JOURNAL_SUMMARY_FETCH_DATA_FAILURE';
export const PRIME_JOURNAL_SUMMARY_UPDATE_PAGE = 'PRIME_JOURNAL_SUMMARY_UPDATE_PAGE';

export const LANDING_PAGE_UPLOAD_FILE_START = 'LANDING_PAGE_UPLOAD_FILE_START';
export const LANDING_PAGE_UPLOAD_FILE_SUCCESS = 'LANDING_PAGE_UPLOAD_FILE_SUCCESS';
export const LANDING_PAGE_UPLOAD_FILE_FAILURE = 'LANDING_PAGE_UPLOAD_FILE_FAILURE';

export const LANDING_PAGE_SEARCH_START = 'LANDING_PAGE_SEARCH_START';
export const LANDING_PAGE_SEARCH_SUCCESS = 'LANDING_PAGE_SEARCH_SUCCESS';
export const LANDING_PAGE_SEARCH_FAILURE = 'LANDING_PAGE_SEARCH_FAILURE';

export const LANDING_PAGE_DOWNLOAD_TEMPLATE_START = 'LANDING_PAGE_DOWNLOAD_TEMPLATE_START';
export const LANDING_PAGE_DOWNLOAD_TEMPLATE_SUCCESS = 'LANDING_PAGE_DOWNLOAD_TEMPLATE_SUCCESS';
export const LANDING_PAGE_DOWNLOAD_TEMPLATE_FAILURE = 'LANDING_PAGE_DOWNLOAD_TEMPLATE_FAILURE';
export const LANDING_PAGE_DOWNLOAD_TEMPLATE_RESET = 'LANDING_PAGE_DOWNLOAD_TEMPLATE_RESET';

export const SEARCH_MODAL_LOAD_START = 'SEARCH_MODAL_LOAD_START';
export const SEARCH_MODAL_LOAD_SUCCESS = 'SEARCH_MODAL_LOAD_SUCCESS';
export const SEARCH_MODAL_LOAD_FAILURE = 'SEARCH_MODAL_LOAD_FAILURE';

export const PRIME_POST_PAGE_FETCH_DATA_START = 'PRIME_POST_PAGE_FETCH_DATA_START';
export const PRIME_POST_PAGE_FETCH_DATA_SUCCESS = 'PRIME_POST_PAGE_FETCH_DATA_SUCCESS';
export const PRIME_POST_PAGE_FETCH_DATA_FAILURE = 'PRIME_POST_PAGE_FETCH_DATA_FAILURE';

export const VALIDATION_UI_EDIT_DATA_GRID = 'VALIDATION_UI_EDIT_DATA_GRID';
export const VALIDATION_UI_EDIT_DATA_GRID_BATCH = 'VALIDATION_UI_EDIT_DATA_GRID_BATCH';
export const VALIDATION_UI_EDIT_REVERSAL = 'VALIDATION_UI_EDIT_REVERSAL';
export const VALIDATION_UI_FETCH_DATA_START = 'VALIDATION_UI_FETCH_DATA_START';
export const VALIDATION_UI_FETCH_DATA_SUCCESS = 'VALIDATION_UI_FETCH_DATA_SUCCESS';
export const VALIDATION_UI_FETCH_DATA_FAILURE = 'VALIDATION_UI_FETCH_DATA_FAILURE';
export const VALIDATION_UI_UNDO_REVERSAL_SUCCESS = 'VALIDATION_UI_UNDO_REVERSAL_SUCCESS';
export const VALIDATION_UI_UPDATE_DATA_GRID = 'VALIDATION_UI_UPDATE_DATA_GRID';
export const VALIDATION_UI_UPDATE_FILE_NAMES = 'VALIDATION_UI_UPDATE_FILE_NAMES';
export const VALIDATION_UI_UPDATE_STATISTICS = 'VALIDATION_UI_UPDATE_STATISTICS';
export const VALIDATION_UI_UPDATE_PAGE = 'VALIDATION_UI_UPDATE_PAGE';

export const VALIDATION_UI_POST_FILE_START = 'VALIDATION_UI_POST_FILE_START';
export const VALIDATION_UI_POST_FILE_SUCCESS = 'VALIDATION_UI_POST_FILE_SUCCESS';
export const VALIDATION_UI_POST_FILE_FAILURE = 'VALIDATION_UI_POST_FILE_FAILURE';
export const VALIDATION_UI_CLEAR_POSTING_SUMMARY = 'VALIDATION_UI_CLEAR_POSTING_SUMMARY';
export const VALIDATION_UI_VALIDATE_WORKBOOK_START = 'VALIDATION_UI_VALIDATE_WORKBOOK_START';
export const VALIDATION_UI_VALIDATE_WORKBOOK_SUCCESS = 'VALIDATION_UI_VALIDATE_WORKBOOK_SUCCESS';
export const VALIDATION_UI_VALIDATE_WORKBOOK_FAILURE = 'VALIDATION_UI_VALIDATE_WORKBOOK_FAILURE';

export const VALIDATION_UI_DOWNLOAD_START = 'VALIDATION_UI_DOWNLOAD_START';
export const VALIDATION_UI_DOWNLOAD_SUCCESS = 'VALIDATION_UI_DOWNLOAD_SUCCESS';
export const VALIDATION_UI_DOWNLOAD_FAILURE = 'VALIDATION_UI_DOWNLOAD_FAILURE';
export const VALIDATION_UI_DOWNLOAD_RESET = 'VALIDATION_UI_DOWNLOAD_RESET';

export const LANDING_PAGE_COPY_FILE_START = 'LANDING_PAGE_COPY_FILE_START';
export const LANDING_PAGE_COPY_FILE_SUCCESS = 'LANDING_PAGE_COPY_FILE_SUCCESS';
export const LANDING_PAGE_COPY_FILE_FAILURE = 'LANDING_PAGE_COPY_FILE_FAILURE';
export const LANDING_PAGE_COPY_FILE_RESET = 'LANDING_PAGE_COPY_FILE_RESET';

export const VALIDATION_UI_SAVE_WORKBOOK_START = 'VALIDATION_UI_SAVE_WORKBOOK_START';
export const VALIDATION_UI_SAVE_WORKBOOK_SUCCESS = 'VALIDATION_UI_SAVE_WORKBOOK_SUCCESS';
export const VALIDATION_UI_SAVE_WORKBOOK_FAILURE = 'VALIDATION_UI_SAVE_WORKBOOK_FAILURE';
export const VALIDATION_UI_SAVE_WORKBOOK_RESET = 'VALIDATION_UI_SAVE_WORKBOOK_RESET';


export const VALIDATION_UI_SAVE_WORKBOOK_NAME_START = 'VALIDATION_UI_SAVE_WORKBOOK_NAME_START';
export const VALIDATION_UI_SAVE_WORKBOOK_NAME_SUCCESS = 'VALIDATION_UI_SAVE_WORKBOOK_NAME_SUCCESS';
export const VALIDATION_UI_SAVE_WORKBOOK_NAME_FAILURE = 'VALIDATION_UI_SAVE_WORKBOOK_NAME_FAILURE';

export const VALIDATION_UI_EXIT_WORKBOOK = 'VALIDATION_UI_EXIT_WORKBOOK';
export const VALIDATION_UI_EXIT_WORKBOOK_RESET = 'VALIDATION_UI_EXIT_WORKBOOK_RESET';

export const LANDING_PAGE_FETCH_REVERSAL_PERIODS_START = 'LANDING_PAGE_FETCH_REVERSAL_PERIODS_START';
export const LANDING_PAGE_FETCH_REVERSAL_PERIODS_SUCCESS = 'LANDING_PAGE_FETCH_REVERSAL_PERIODS_SUCCESS';
export const LANDING_PAGE_FETCH_REVERSAL_PERIODS_FAILURE = 'LANDING_PAGE_FETCH_REVERSAL_PERIODS_FAILURE';

export const LANDING_PAGE_DELETE_WORKBOOK_START = 'LANDING_PAGE_DELETE_WORKBOOK_START';
export const LANDING_PAGE_DELETE_WORKBOOK_SUCCESS = 'LANDING_PAGE_DELETE_WORKBOOK_SUCCESS';
export const LANDING_PAGE_DELETE_WORKBOOK_FAILURE = 'LANDING_PAGE_DELETE_WORKBOOK_FAILURE';

export const LANDING_PAGE_DOWNLOAD_WORKBOOK_START = 'LANDING_PAGE_DOWNLOAD_WORKBOOK_START';
export const LANDING_PAGE_DOWNLOAD_WORKBOOK_SUCCESS = 'LANDING_PAGE_DOWNLOAD_WORKBOOK_SUCCESS';
export const LANDING_PAGE_DOWNLOAD_WORKBOOK_FAILURE = 'LANDING_PAGE_DOWNLOAD_WORKBOOK_FAILURE';
export const LANDING_PAGE_DOWNLOAD_WORKBOOK_RESET = 'LANDING_PAGE_DOWNLOAD_WORKBOOK_RESET';

export const JOURNAL_SUMMARY_DOWNLOAD_START = 'JOURNAL_SUMMARY_DOWNLOAD_START';
export const JOURNAL_SUMMARY_DOWNLOAD_SUCCESS = 'JOURNAL_SUMMARY_DOWNLOAD_SUCCESS';
export const JOURNAL_SUMMARY_DOWNLOAD_FAILURE = 'JOURNAL_SUMMARY_DOWNLOAD_FAILURE';
export const JOURNAL_SUMMARY_DOWNLOAD_RESET = 'JOURNAL_SUMMARY_DOWNLOAD_RESET';

export const VALIDATION_UI_DELETE_ROW_START = 'VALIDATION_UI_DELETE_ROW_START';
export const VALIDATION_UI_DELETE_ROW_SUCCESS = 'VALIDATION_UI_DELETE_ROW_SUCCESS';
export const VALIDATION_UI_DELETE_ROW_FAILURE = 'VALIDATION_UI_DELETE_ROW_FAILURE';

export const VALIDATION_UI_ADD_ROW_START = 'VALIDATION_UI_ADD_ROW_START';
export const VALIDATION_UI_ADD_ROW_SUCCESS = 'VALIDATION_UI_ADD_ROW_SUCCESS';
export const VALIDATION_UI_ADD_ROW_FAILURE = 'VALIDATION_UI_ADD_ROW_FAILURE';
export const VALIDATION_UI_REVALIDATE_COMPLETE = 'VALIDATION_UI_REVALIDATE_COMPLETE';
export const VALIDATION_UI_REVALIDATE_START = 'VALIDATION_UI_REVALIDATE_START';

export const VALIDATION_UI_GENERATE_TAX_LINES_START = 'VALIDATION_UI_GENERATE_TAX_LINES_START';
export const VALIDATION_UI_GENERATE_TAX_LINES_SUCCESS = 'VALIDATION_UI_GENERATE_TAX_LINES_SUCCESS';
export const VALIDATION_UI_GENERATE_TAX_LINES_FAILURE = 'VALIDATION_UI_GENERATE_TAX_LINES_FAILURE';

export const VALIDATION_UI_DELETE_WORKBOOK_START = 'VALIDATION_UI_DELETE_WORKBOOK_START';
export const VALIDATION_UI_DELETE_WORKBOOK_SUCCESS = 'VALIDATION_UI_DELETE_WORKBOOK_SUCCESS';
export const VALIDATION_UI_DELETE_WORKBOOK_FAILURE = 'VALIDATION_UI_DELETE_WORKBOOK_FAILURE';
export const VALIDATION_UI_DELETE_WORKBOOK_RESET = 'VALIDATION_UI_DELETE_WORKBOOK_RESET';

export const PRIME_POST_PAGE_DOWNLOAD_START = 'PRIME_POST_PAGE_DOWNLOAD_START';
export const PRIME_POST_PAGE_DOWNLOAD_SUCCESS = 'PRIME_POST_PAGE_DOWNLOAD_SUCCESS';
export const PRIME_POST_PAGE_DOWNLOAD_FAILURE = 'PRIME_POST_PAGE_DOWNLOAD_FAILURE';
export const PRIME_POST_PAGE_DOWNLOAD_RESET = 'PRIME_POST_PAGE_DOWNLOAD_RESET';
export const REPORTS_UI_REPORT_RUN_START = 'REPORTS_UI_REPORT_RUN_START';
export const REPORTS_UI_REPORT_RUN_SUCCESS = 'REPORTS_UI_REPORT_RUN_SUCESS';
export const REPORTS_UI_REPORT_RUN_FAILURE = 'REPORTS_UI_REPORT_RUN_FAILURE';
export const REPORTS_UI_REPORT_RUN_RESET = 'REPORTS_UI_REPORT_RUN_RESET';

export const REPORTS_UI_GET_REPORT_PERIODS_START = 'REPORTS_UI_GET_REPORT_PERIODS_START';
export const REPORTS_UI_GET_REPORT_PERIODS_FAILURE = 'REPORTS_UI_GET_REPORT_PERIODS_FAILURE';
export const REPORTS_UI_GET_REPORT_PERIODS_SUCCESS = 'REPORTS_UI_GET_REPORT_PERIODS_SUCCESS';

export const REPORTS_HISTORY_GET_START = 'REPORTS_HISTORY_GET_START';
export const REPORTS_HISTORY_GET_SUCESS = 'REPORTS_HISTORY_GET_SUCESS';
export const REPORTS_HISTORY_GET_FAILURE = 'REPORTS_HISTORY_GET_FAILURE';
export const REPORTS_HISTORY_GET_RESET = 'REPORTS_HISTORY_GET_RESET';

export const REPORTS_HISTORY_DOWNLOAD_START = 'REPORTS_HISTORY_DOWNLOAD_START';
export const REPORTS_HISTORY_DOWNLOAD_SUCCESS = 'REPORTS_HISTORY_DOWNLOAD_SUCCESS';
export const REPORTS_HISTORY_DOWNLOAD_FAILURE = 'REPORTS_HISTORY_DOWNLOAD_FAILURE';
export const REPORTS_HISTORY_DOWNLOAD_RESET = 'REPORTS_HISTORY_DOWNLOAD_RESET';

export const LANDING_PAGE_POST_FILE_START = 'LANDING_PAGE_POST_FILE_START';
export const LANDING_PAGE_POST_FILE_SUCCESS = 'LANDING_PAGE_POST_FILE_SUCCESS';
export const LANDING_PAGE_POST_FILE_FAILURE = 'LANDING_PAGE_POST_FILE_FAILURE';

export const LANDING_PAGE_DOWNLOAD_INVOICE_START = 'LANDING_PAGE_DOWNLOAD_INVOICE_START';
export const LANDING_PAGE_DOWNLOAD_INVOICE_SUCCESS = 'LANDING_PAGE_DOWNLOAD_INVOICE_SUCCESS';
export const LANDING_PAGE_DOWNLOAD_INVOICE_FAILURE = 'LANDING_PAGE_DOWNLOAD_INVOICE_FAILURE';
export const LANDING_PAGE_DOWNLOAD_INVOICE_RESET = 'LANDING_PAGE_DOWNLOAD_INVOICE_RESET';
