import React from 'react';
import PropTypes from 'prop-types';
import '@amzn/awsui-components-react/index.css';
import {
  Button, ColumnLayout, Form, FormSection,
} from '@amzn/awsui-components-react';
import { TableView } from 'common/components/dataDisplay';

/*
 * Table to display the meta data associated with this driver data table
 * Including attribute like: column_name, column_description, data_type, etc
 */
class MetaDataBlock extends React.Component {
  static propTypes = {
    handleViewClick: PropTypes.func.isRequired,
    handleDeleteClick: PropTypes.func.isRequired,
    handleCreateClick: PropTypes.func.isRequired,

    templateName: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.string),
    items: PropTypes.arrayOf(PropTypes.shape({})),
    loading: PropTypes.bool,
  };

  static defaultProps = {
    templateName: '',
    columns: [],
    items: [],
    loading: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      elementSelected: [],
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange = (polarisSelectedList) => {
    this.setState({
      elementSelected: polarisSelectedList,
    });
  };

  render() {
    const {
      handleCreateClick,
      handleViewClick,
      handleDeleteClick,
      templateName,
      columns,
      items,
      loading,
    } = this.props;
    const { elementSelected } = this.state;

    const viewDetailsDisabled = elementSelected.length !== 1;
    const deleteDisabled = elementSelected.length === 0;

    const header = (
      <div className="awsui-util-action-stripe">
        <div className="awsui-util-action-stripe-title">
          <h2>
            Meta data table
            {templateName}
          </h2>
        </div>
        <div className="awsui-util-action-stripe-group">
          <Button
            text="View Details"
            type="submit"
            disabled={viewDetailsDisabled}
            onClick={() => handleViewClick(elementSelected)}
          />
          <Button
            text="Delete"
            type="submit"
            disabled={deleteDisabled}
            onClick={() => handleDeleteClick(elementSelected)}
          />
          <Button text="Create new" type="submit" variant="primary" onClick={handleCreateClick} />
        </div>
      </div>
    );

    const columnDefinitions = [];
    for (let index = 0; index < columns.length; index += 1) {
      const columnDefinitionsItem = {};
      const column = columns[index];
      columnDefinitionsItem.id = column;
      columnDefinitionsItem.header = column;
      columnDefinitionsItem.cell = item => item[column];
      columnDefinitionsItem.width = 150;
      columnDefinitionsItem.minWidth = '150px';
      columnDefinitions.push(columnDefinitionsItem);
    }

    const sortableColumns = [];
    for (let index = 0; index < columns.length; index += 1) {
      const sortableColumnsItem = {};
      const column = columns[index];
      sortableColumnsItem.id = column;
      sortableColumnsItem.field = column;
      sortableColumns.push(sortableColumnsItem);
    }

    const visibleColumnOption = [];
    const firstOption = {};
    firstOption.label = 'Properties';
    const options = [];
    for (let index = 0; index < columns.length; index += 1) {
      const visibleColumnOptionItem = {};
      const column = columns[index];
      visibleColumnOptionItem.id = column;
      visibleColumnOptionItem.label = column;
      visibleColumnOptionItem.editable = column !== 'column_name';
      visibleColumnOptionItem.visible = true;
      options.push(visibleColumnOptionItem);
    }
    firstOption.options = options;
    visibleColumnOption.push(firstOption);

    const pageSizeOption = [
      { value: 10, label: '10 items' },
      { value: 20, label: '20 items' },
      { value: 50, label: '50 items' },
    ];

    return (
      <Form>
        <FormSection className="custom-screenshot-hide">
          <ColumnLayout columns={1}>
            <div data-awsui-column-layout-root>
              <TableView
                tableTitle="Meta data table"
                columnDefinitions={columnDefinitions}
                items={items}
                header={header}
                trackBy="column_name"
                searchMessage="Filter"
                pageSize={10}
                loading={loading}
                sortableColumns={sortableColumns}
                pageSizeOption={pageSizeOption}
                visibleColumnOption={visibleColumnOption}
                handleSelectChange={this.handleSelectChange}
              />
            </div>
          </ColumnLayout>
        </FormSection>
      </Form>
    );
  }
}

export default MetaDataBlock;
