import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ensureAuthenticated from 'utils/auth/authenticate';
import ICMJEAppRestrict from '../ICMJEAppRestrict';
import config from '../config/config.json';
import Logger from './logger';

const APIClient = {
  invokeBusinessLogic(resourceName, functionName, parameterJSON, dataJSON, callback) {
    ensureAuthenticated()
      .then((auth) => {
        const session = auth.getSignInUserSession();
        const usernamePayload = session.getIdToken().decodePayload();
        // ['cognito:username'].split('_')[1];

        let url = `${config.businessLogicAPI
          + config.apiStage
          + resourceName}?request=${functionName}&environment=${process.env.NODE_ENV}&username=${
          usernamePayload['cognito:username'].split('_')[1]
        }`;
        // for (const param in parameterJSON) url = `${url}&${param}=${parameterJSON[param]}`;
        const paramString = Object.entries(parameterJSON)
          .map(([k, v]) => `${k}=${v}`)
          .join('&');
        url = `${url}&${paramString}`;

        let opts;
        if (dataJSON === undefined || dataJSON === {}) {
          // GET method
          opts = { methods: 'GET', headers: { Authorization: session.getIdToken().getJwtToken() } };
        } else {
          // POST method
          opts = {
            method: 'POST',
            headers: { Authorization: session.getIdToken().getJwtToken() },
            body: JSON.stringify({ data: dataJSON }),
          };
        }
        fetch(url, opts)
          .then(response => response.json())
          .then((data) => {
            Logger.logInfo(`data fetched = ${data}`);
            callback(null, data);
          })
          .catch((e) => {
            Logger.logError(e);
            return callback(e);
          });
      })
      .catch((/* e */) => {
        ReactDOM.render(
          <BrowserRouter>
            <ICMJEAppRestrict />
          </BrowserRouter>,
          document.getElementById('root'),
        );
      });
  },
};

export default APIClient;
