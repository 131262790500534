import React from 'react';
import PropTypes from 'prop-types';
import '@amzn/awsui-components-react/index.css';
import { ColumnLayout, Button } from '@amzn/awsui-components-react';

/*
 * ActionStrip for edit/update/cancel action
 */
function ActionStrip({
  handleEdit, handleUpdate, handleCancel, disabled,
}) {
  let buttonContent = {};
  if (disabled) {
    buttonContent = <Button text="Edit" type="submit" variant="primary" onClick={handleEdit} />;
  } else {
    buttonContent = (
      <div>
        <Button text="Cancel" type="submit" onClick={handleCancel} />
        <Button text="Update" type="submit" variant="primary" onClick={handleUpdate} />
      </div>
    );
  }
  return (
    <ColumnLayout columns={1}>
      <div data-awsui-column-layout-root>
        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-title" />
          <div className="awsui-util-action-stripe-group">{buttonContent}</div>
        </div>
      </div>
    </ColumnLayout>
  );
}

ActionStrip.propTypes = {
  handleEdit: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,

  disabled: PropTypes.bool,
};

ActionStrip.defaultProps = {
  disabled: false,
};

export default ActionStrip;
