import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import '@amzn/awsui-components-react/index.css';
import {
  ColumnLayout, Form, FormSection, Icon,
} from '@amzn/awsui-components-react';
import XLSXUtils from 'utils/xlsxUtils';
import Logger from 'utils/logger';
import { ErrorTable, ActionStrip } from './utils';

class StepFour extends React.Component {
  static propTypes = {
    fileName: PropTypes.string.isRequired,
    handleTryOtherFileClick: PropTypes.func.isRequired,
    handleUploadClick: PropTypes.func.isRequired,

    fileParsingError: PropTypes.string,
    fileStructureErrorList: PropTypes.arrayOf(PropTypes.shape({})),
    fieldLevelValidationErrorList: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    fileParsingError: null,
    fileStructureErrorList: [],
    fieldLevelValidationErrorList: [],
  };

  constructor(props) {
    super(props);

    this.handleDownloadErrorClick = this.handleDownloadErrorClick.bind(this);
  }

  handleDownloadErrorClick() {
    const {
      fileParsingError,
      fileStructureErrorList,
      fieldLevelValidationErrorList,
      fileName,
    } = this.props;
    const errorContent = [];

    if (fileParsingError !== undefined && fileParsingError !== null) {
      Logger.logInfo(`fileParsingError = ${JSON.stringify(fileParsingError)}`);
      errorContent.push(...fileParsingError);
    }

    if (fileStructureErrorList !== undefined && fileStructureErrorList !== null) {
      Logger.logInfo(`fileStructureErrorList = ${JSON.stringify(fileStructureErrorList)}`);
      errorContent.push(...fileStructureErrorList);
    }

    if (fieldLevelValidationErrorList !== undefined && fieldLevelValidationErrorList !== null) {
      Logger.logInfo(
        `this.props.fieldLevelValidationErrorList = ${JSON.stringify(
          fieldLevelValidationErrorList,
        )}`,
      );
      errorContent.push(...fieldLevelValidationErrorList);
    }

    XLSXUtils.writeSingleSheetTable(
      `upload_errors_${moment(new Date()).format('MM_DD_YYYY')}`,
      fileName,
      errorContent,
      ['row_number', 'col_number', 'err_type', 'err_description'],
    );
  }

  render() {
    const {
      fileParsingError,
      fileStructureErrorList,
      fieldLevelValidationErrorList,
      handleTryOtherFileClick,
      handleUploadClick,
    } = this.props;

    const errorColumns = ['row_number', 'col_number', 'err_type', 'err_description'];
    let downloadDisabled = false;
    let parsingErrorTable = null;
    let fileStructureErrorTable = null;
    let fieldLevelValidationErrorTable = null;
    let validationPassBlock = null;

    if (fileParsingError !== undefined && fileParsingError !== null) {
      downloadDisabled = true;
      parsingErrorTable = (
        <ErrorTable
          errorTitle="Content Parsing Error"
          columns={errorColumns}
          items={fileParsingError}
        />
      );
    }

    if (fileStructureErrorList !== undefined && fileStructureErrorList !== null) {
      downloadDisabled = true;
      fileStructureErrorTable = (
        <ErrorTable
          errorTitle="File Structure Error"
          columns={errorColumns}
          items={fileStructureErrorList}
        />
      );
    }

    if (fieldLevelValidationErrorList !== undefined && fieldLevelValidationErrorList !== null) {
      downloadDisabled = true;
      fieldLevelValidationErrorTable = (
        <ErrorTable
          errorTitle="Field Level Validation Error"
          columns={errorColumns}
          items={fieldLevelValidationErrorList}
        />
      );
    }

    if (!downloadDisabled) {
      validationPassBlock = (
        <div className="awsui-util-t-c">
          <br />
          <div className="awsui-util-font-size-3">
            <Icon variant="success" name="status-positive" />
            All validation succeed
          </div>
          <br />
          <div>Congratulations, your file has passed all validations</div>
          <br />
        </div>
      );
    }

    return (
      <div>
        <Form>
          <FormSection className="custom-screenshot-hide" header="step 4 - Validate your file">
            <ColumnLayout columns={1}>
              <div data-awsui-column-layout-root>
                {validationPassBlock}
                {parsingErrorTable}
                {fileStructureErrorTable}
                {fieldLevelValidationErrorTable}
                <ActionStrip
                  downloadDisabled={downloadDisabled}
                  handleDownloadErrorClick={this.handleDownloadErrorClick}
                  handleTryOtherFileClick={handleTryOtherFileClick}
                  handleUploadClick={handleUploadClick}
                />
              </div>
            </ColumnLayout>
          </FormSection>
        </Form>
      </div>
    );
  }
}

export default withRouter(StepFour);
