import * as CONST from 'common/const';
import createReducer from './createReducer';

export const initialState = {
  error: '',
  primaryAttributes: [],
  secondaryAttributes: [],
};

export default createReducer(initialState, {
  [CONST.SEARCH_MODAL_LOAD_START]: state => ({
    ...state,
    error: '',
    primaryAttributes: [],
    secondaryAttributes: [],
  }),
  [CONST.SEARCH_MODAL_LOAD_SUCCESS]: (state, action) => ({
    ...state,
    error: '',
    primaryAttributes: action.payload.primarySearchableAttributes,
    secondaryAttributes: action.payload.secondarySearchableAttributes,
  }),
  [CONST.SEARCH_MODAL_LOAD_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
    primaryAttributes: [],
    secondaryAttributes: [],
  }),
});
