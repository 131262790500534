import React from 'react';
import { Checkbox, Select, Button } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import MJEModal from 'common/components/mjeModal/MJEModal';

const reversalRowPropTypes = {
  journalName: PropTypes.string.isRequired,
  periods: PropTypes.arrayOf(PropTypes.shape({
    displayValue: PropTypes.string,
    lastDayOfMonth: PropTypes.string,
  })).isRequired,
  methods: PropTypes.arrayOf(PropTypes.shape({
    displayName: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  selected: PropTypes.bool.isRequired,
  selectedPeriod: PropTypes.string,
  selectedMethod: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  hasRequiredFieldError: PropTypes.bool,


  onCheckboxChange: PropTypes.func.isRequired,
  onPeriodChange: PropTypes.func.isRequired,
  onMethodChange: PropTypes.func.isRequired,

  rowClassName: PropTypes.string,
};
const reversalRowDefaultProps = {
  selectedPeriod: null,
  selectedMethod: null,
  rowClassName: '',
  hasRequiredFieldError: false,
};

function ReversalRow({
  icTransactionGroup,
  journalName,
  periods,
  methods,
  selected,
  selectedPeriod,
  selectedMethod,
  disabled,
  hasRequiredFieldError,
  onCheckboxChange,
  onPeriodChange,
  onMethodChange,
  rowClassName,
}) {
  return (
    <div className={`awsui-row ${rowClassName}`}>
      <div className="col-1">
        <Checkbox
          label={icTransactionGroup}
          checked={selected}
          onChange={onCheckboxChange}
          disabled={disabled}
          data-for="reversal"
        />
      </div>
      <div className="col-4" data-tip={journalName} style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
        {journalName}
        <ReactTooltip
          place="bottom"
          type="dark"
          effect="solid"
          event="mouseover"
          eventOff="mouseout"
        />
      </div>
      <div className="col-3">
        <Select
          options={periods.map(period => ({ label: period.displayValue, id: period.displayValue }))}
          selectedOption={selectedPeriod ? { label: selectedPeriod, id: selectedPeriod } : null}
          disabled={!selected || disabled}
          onChange={onPeriodChange}
          placeholder="Select"
        />
        { hasRequiredFieldError && selected && !disabled && !selectedPeriod && <span className="red-text reversal-asterisk-position">*</span> }
      </div>
      <div className="col-3">
        <Select
          options={methods.map(method => ({ label: method.displayName, id: method.displayName }))}
          selectedOption={selectedMethod ? { label: selectedMethod, id: selectedMethod } : null}
          disabled={!selected || disabled}
          onChange={onMethodChange}
          placeholder="Select"
        />
        { hasRequiredFieldError && selected && !disabled && !selectedMethod && <span className="red-text reversal-asterisk-position">*</span> }
      </div>      
    </div>
  );
}
ReversalRow.propTypes = reversalRowPropTypes;
ReversalRow.defaultProps = reversalRowDefaultProps;

class ValidationUIReversal extends React.Component {
  static propTypes = {
    transactions: PropTypes.instanceOf(Map),
    reversalAllJournals: PropTypes.arrayOf(
      PropTypes.shape({
        journalName: PropTypes.string,
        periods: PropTypes.arrayOf(PropTypes.shape({
          displayValue: PropTypes.string,
          lastDayOfMonth: PropTypes.string,
        })),
        methods: PropTypes.arrayOf(PropTypes.shape({
          displayName: PropTypes.string,
          value: PropTypes.string,
        })),
        selected: PropTypes.bool,
        selectedPeriod: PropTypes.string,
        selectedMethod: PropTypes.string,
        disabled: PropTypes.bool,
      }),
    ),
    onReversalChange: PropTypes.func,
    onSetClick: PropTypes.func,
    onUndoClick: PropTypes.func,
    inViewMode: PropTypes.bool,
    disabled: PropTypes.bool,
    workbookIsEditable: PropTypes.bool,
  };

  static defaultProps = {
    transactions: new Map(),
    reversalAllJournals: null,
    onReversalChange: () => { },
    onSetClick: () => { },
    onUndoClick: () => { },
    inViewMode: false,
    disabled: false,
    workbookIsEditable: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasSetReversal: false,
    };
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onPeriodChange = this.onPeriodChange.bind(this);
    this.onMethodChange = this.onMethodChange.bind(this);
    this.onSetClick = this.onSetClick.bind(this);
    this.onUndoClick = this.onUndoClick.bind(this);
    this.applyUndo = this.applyUndo.bind(this);
    this.handleUndoReversalCancelled = this.handleUndoReversalCancelled.bind(this);
  }

  findTransactionBy(batchName, index) {
    const { transactions } = this.props;
    return transactions.get(batchName)[index];
  }

  onCheckboxChange(event, transaction, index) {
    const { onReversalChange } = this.props;
    const selected = event.target.component.checked;
    onReversalChange(
      transaction.batchName,
      index,
      selected,
      transaction.selectedPeriod,
      transaction.selectedMethod,
    );
  }

  onPeriodChange(event, transaction, index) {
    const { onReversalChange } = this.props;
    const selectedPeriod = event.target.component.selectedOption.label;
    onReversalChange(
      transaction.batchName,
      index,
      transaction.selected,
      selectedPeriod,
      transaction.selectedMethod,
    );
  }

  onMethodChange(event, transaction, index) {
    const { onReversalChange } = this.props;
    const selectedMethod = event.target.component.selectedOption.id;
    onReversalChange(
      transaction.batchName,
      index,
      transaction.selected,
      transaction.selectedPeriod,
      selectedMethod,
    );
  }

  onSetClick() {
    const { onSetClick } = this.props;
    onSetClick();
    this.setState({
      hasSetReversal: true,
    });
  }

  onUndoClick() {
    this.setState({
      confirmUndoReversal: true,
    });
  }

  applyUndo() {
    const { onUndoClick } = this.props;
    onUndoClick();
    this.setState({
      hasSetReversal: false,
      confirmUndoReversal: false,
    });
  }

  handleUndoReversalCancelled() {
    this.setState({
      hasSetReversal: true,
      confirmUndoReversal: false,
    });
  }

  render() {
    const { hasSetReversal, confirmUndoReversal } = this.state;
    const {
      transactions, inViewMode, disabled, workbookIsEditable, reversalAllJournals,
    } = this.props;
    const allReversals = Array.from(transactions.values()).flat();
    const allJournalsOption = {
      ...reversalAllJournals,
      methods: (allReversals.length > 0 ? allReversals[0] || {} : {}).methods || [],
      periods: (allReversals.length > 0 ? allReversals[0] || {} : {}).periods || [],
    };
    const allJournalsOptionDisabled = !workbookIsEditable && allReversals.some(transaction => transaction.reversed);
    const isSetDisabled = inViewMode;
    const isUndoDisabled = !hasSetReversal || inViewMode;
    return (
      <div className="awsui-util-container reversals-box-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h4>Reversal</h4>
            </div>
            <div className="awsui-util-action-stripe-group awsui-util-pt-xs">
              <Button
                text="Undo"
                onClick={this.onUndoClick}
                disabled={isUndoDisabled || disabled}
                className="awsui-util-f-r awsui-util-ml-s"
              />
              <Button
                text="Set"
                onClick={this.onSetClick}
                disabled={isSetDisabled || disabled}
                className="awsui-util-f-r"
              />
            </div>
          </div>

        </div>
        <div className="reversal-container awsui-util-container awsui-util-mh-m awsui-util-ph-s awsui-util-pv-n awsui-util-mv-n awsui-grid">
          <div className="reversal-journal-container">
            {Array.from(transactions.keys()).map(batchName => (
              <>
                <h5 className="batch-header">{`Batch: ${batchName}`}</h5>
                <div className="awsui-row awsui-util-ph-n reversalHeaderRow">
                  <div className="col-1" data-tip="IC Transaction Group">
                    <small><b>ICTG</b></small>
                    <ReactTooltip
                      place="top"
                      type="dark"
                      effect="solid"
                      event="mouseover"
                      eventOff="mouseout"
                    />
                  </div>
                  <div className="col-4 journal-name-header">Journal Name</div>
                  <div className="col-3 period-header">Period</div>
                  <div className="col-3 method-header">Method</div>
                </div>
                <div className="awsui-util-p-n awsui-util-pt-s awsui-util-mb-m">
                  {transactions.get(batchName).map((entry, index) => (
                    <ReversalRow
                      key={batchName + entry.icTransactionGroup}
                      icTransactionGroup={entry.icTransactionGroup}
                      journalName={entry.journalName || ''}
                      periods={entry.periods}
                      methods={entry.methods}
                      selected={entry.selected}
                      selectedPeriod={entry.selectedPeriod}
                      selectedMethod={entry.selectedMethod}
                      disabled={entry.disabled || (!workbookIsEditable && entry.reversed) || inViewMode || disabled}
                      hasRequiredFieldError={entry.hasRequiredFieldError}
                      onCheckboxChange={e => this.onCheckboxChange(e, this.findTransactionBy(batchName, index), index)}
                      onPeriodChange={e => this.onPeriodChange(e, this.findTransactionBy(batchName, index), index)}
                      onMethodChange={e => this.onMethodChange(e, this.findTransactionBy(batchName, index), index)}
                      rowClassName="regularReversalRow"
                    />
                  ))}
                </div>
              </>
            ))}
          </div>
          {allJournalsOption && (
            <ReversalRow
              key={allJournalsOption.icTransactionGroup}
              icTransactionGroup="All Journals"
              journalName=""
              periods={allJournalsOption.periods}
              methods={allJournalsOption.methods}
              selected={allJournalsOption.selected}
              selectedPeriod={allJournalsOption.selectedPeriod}
              selectedMethod={allJournalsOption.selectedMethod}
              disabled={allJournalsOptionDisabled || allJournalsOption.disabled || inViewMode || disabled}
              onCheckboxChange={e => this.onCheckboxChange(e, allJournalsOption, -1)}
              onPeriodChange={e => this.onPeriodChange(e, allJournalsOption, -1)}
              onMethodChange={e => this.onMethodChange(e, allJournalsOption, -1)}
              rowClassName="allJournalsReversalRow"
            />
          )}
          {confirmUndoReversal && (
            <MJEModal
              visible={confirmUndoReversal}
              header="Please Confirm"
              content="Revert reversal settings back to loaded conditions?"
              actionType="YES_NO"
              onYes={this.applyUndo}
              onNo={this.handleUndoReversalCancelled}
            />
          )}
        </div>
        <ReactTooltip
          id="reversal"
          place="right"
          type="dark"
          effect="solid"
          event="mouseenter"
          eventOff="mouseleave"
        />
      </div>
    );
  }
}

export default ValidationUIReversal;
