import React from 'react';
import PropTypes from 'prop-types';
import '@amzn/awsui-components-react/index.css';
import { FormField, ColumnLayout } from '@amzn/awsui-components-react';
import { IntegerElement } from 'common/components/dataEntry';

/*
 * Specific setup for integer type
 */
function IntegerRangeBlock({ handleChange, setErrorFlag, value }) {
  return (
    <FormField
      label="Value Range"
      description="Please provide the range of this data. Left empty if no constraint."
    >
      <ColumnLayout columns={4}>
        <div data-awsui-column-layout-root>
          <IntegerElement
            title="Min value"
            name="min"
            value={value.min}
            disabled={false}
            required={false}
            handleChange={handleChange}
            setErrorFlag={setErrorFlag}
          />
          <IntegerElement
            title="Max value"
            name="max"
            value={value.max}
            disabled={false}
            required={false}
            handleChange={handleChange}
            setErrorFlag={setErrorFlag}
          />
        </div>
      </ColumnLayout>
    </FormField>
  );
}

IntegerRangeBlock.propTypes = {
  handleChange: PropTypes.func.isRequired,
  setErrorFlag: PropTypes.func.isRequired,
  value: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
};

IntegerRangeBlock.defaultProps = {};

export default IntegerRangeBlock;
