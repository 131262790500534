import * as CONST from 'common/const';
import createReducer from './createReducer';

export const initialState = {
  runningReport: false,
  reportSuccess: false,
  successfulReportID: '',
  error: null,
  reportPeriods: [],
};

export default createReducer(initialState, {
  [CONST.REPORTS_UI_REPORT_RUN_START]: state => ({
    ...state,
    runningReport: true,
  }),

  [CONST.REPORTS_UI_REPORT_RUN_SUCCESS]: (state, action) => ({
    ...state,
    runningReport: false,
    reportSuccess: true,
    successfulReportID: action.reportID,
  }),

  [CONST.REPORTS_UI_REPORT_RUN_FAILURE]: (state, action) => ({
    ...state,
    runningReport: false,
    error: action.globalError,
    reportSuccess: false,
  }),

  [CONST.REPORTS_UI_REPORT_RUN_RESET]: state => ({
    ...state,
    runningReport: false,
    reportSuccess: false,
    successfulReportID: '',
  }),

  [CONST.REPORTS_UI_GET_REPORT_PERIODS_START]: state => ({
    ...state,
    error: '',
    reportPeriods: [],
  }),

  [CONST.REPORTS_UI_GET_REPORT_PERIODS_SUCCESS]: (state, action) => ({
    ...state,
    error: '',
    reportPeriods: action.payload.periods,
  }),

  [CONST.REPORTS_UI_GET_REPORT_PERIODS_FAILURE]: (state, action) => ({
    ...state,
    error: action.payload,
    reportPeriods: [],
  }),

});
