import * as CONST from 'common/const';
import api from 'utils/api';

export const reportRunStart = () => ({
  type: CONST.REPORTS_UI_REPORT_RUN_START,
});

export const reportRunFailure = (error = null) => ({
  type: CONST.REPORTS_UI_REPORT_RUN_FAILURE,
  globalError: error,
});

export const reportRunSuccess = (reportID = '') => ({
  type: CONST.REPORTS_UI_REPORT_RUN_SUCCESS,
  reportID,
});

export const reportRunReset = () => ({
  type: CONST.REPORTS_UI_REPORT_RUN_RESET,
});

export const reportRun = (type, period) => async (dispatch, getState) => {
  const { username } = getState().auth;
  dispatch(reportRunStart());
  try {
    const result = await api.reportCreate(type.type_name, period.label, username);
    dispatch(reportRunSuccess(result.reportID));
    await api.reportProcess(username, result.reportID);
  } catch (e) {
    dispatch(reportRunFailure(e));
  }
};

export const getReportPeriodsStart = () => ({
  type: CONST.REPORTS_UI_GET_REPORT_PERIODS_START,
});

export const getReportPeriodsFailure = (error = null) => ({
  type: CONST.REPORTS_UI_GET_REPORT_PERIODS_FAILURE,
  payload: error,
});

export const getReportPeriodsSuccess = (data) => ({
  type: CONST.REPORTS_UI_GET_REPORT_PERIODS_SUCCESS,
  payload: data,
});

export const getReportPeriods = () => async (dispatch) => {
  dispatch(getReportPeriodsStart());
  try {
    const result = await api.getReportPeriods();
    dispatch(getReportPeriodsSuccess(result));
  } catch (e) {
    dispatch(getReportPeriodsFailure(e));
  }
};
