// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".common-components-LoadingSpinner-LoadingSpinner__wrapper--K76ij{position:fixed;top:0;bottom:0;left:0;right:0;background:#eee;opacity:70%;z-index:100}.common-components-LoadingSpinner-LoadingSpinner__wrapper--K76ij .common-components-LoadingSpinner-LoadingSpinner__spinner--1Z8c5{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}", ""]);
// Exports
exports.locals = {
	"wrapper": "common-components-LoadingSpinner-LoadingSpinner__wrapper--K76ij",
	"spinner": "common-components-LoadingSpinner-LoadingSpinner__spinner--1Z8c5"
};
module.exports = exports;
