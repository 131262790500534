import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import styles from './LoadingSpinner.scss';

class LoadingSpinner extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    text: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    text: '',
  };

  render() {
    const { className, loading, text } = this.props;
    const containerClassName = `${className} ${styles.wrapper}`;
    return (
      loading && (
        <div className={containerClassName}>
          <Spin className={styles.spinner} tip={text} />
        </div>
      )
    );
  }
}

export default LoadingSpinner;
