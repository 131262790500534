import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import '@amzn/awsui-components-react/index.css';
import 'antd/dist/antd.css';
import { Upload, Icon as AntdIcon } from 'antd';
import { Button, FormSection } from '@amzn/awsui-components-react';

class StepThree extends React.Component {
  static propTypes = {
    handleValidationClick: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      // uploading: false,
    };

    this.onRemove = this.onRemove.bind(this);
    this.beforeUpload = this.beforeUpload.bind(this);
  }

  onRemove(file) {
    this.setState((state) => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: newFileList,
      };
    });
  }

  beforeUpload(file) {
    this.setState(state => ({
      fileList: [...state.fileList, file],
    }));
    return false;
  }

  render() {
    const { handleValidationClick } = this.props;

    // TODO: change to support bulk file upload in future
    const { fileList } = this.state;

    return (
      <div>
        <FormSection
          className="custom-screenshot-hide"
          header="Step 3 - Choose your file for upload"
        >
          Select or drag the file to upload in the below area.
          <br />
          <br />
          <Upload.Dragger
            accept=".xlsx"
            fileList={fileList}
            onRemove={this.onRemove}
            beforeUpload={this.beforeUpload}
          >
            <p className="ant-upload-drag-icon">
              <AntdIcon type="inbox" />
            </p>
            <p className="ant-upload-text">Click or drag file(.xlsx) to this area to upload</p>
            <p className="ant-upload-hint">
              Support for single file upload. Please make sure the format of your data is consistent
              to the table definition.
            </p>
          </Upload.Dragger>
        </FormSection>

        <Button
          text="Submit for validation"
          variant="primary"
          icon="angle-right"
          iconAlign="right"
          disabled={fileList.length === 0}
          className="awsui-util-mb-xl"
          onClick={() => handleValidationClick(fileList)}
        />
      </div>
    );
  }
}

export default withRouter(StepThree);
