import React from 'react';
import { Checkbox } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';

/* This filter, when enabled, filters out all the null values in the Errors column */
export default class ErrorFilter extends React.Component {
  static filterValues(row, columnFilter, columnKey) {
    return !columnFilter.filterTerm || row[columnKey] != null;
  }

  static propTypes = {
    // The column prop is passed in by ReactDataGrid
    // eslint-disable-next-line react/forbid-prop-types
    column: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      filtered: false,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    const { column, onChange } = this.props;
    const { checked } = event.target.component;
    onChange({
      filterTerm: checked,
      column,
      rawValue: checked,
      filterValues: ErrorFilter.filterValues,
    });
    this.setState({ filtered: checked });
  }

  render() {
    const { filtered } = this.state;
    return (
      <Checkbox
        label="See only rows with errors"
        checked={filtered}
        onChange={this.onChange}
        className="awsui-util-pt-s"
      />
    );
  }
}
