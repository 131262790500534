import PropTypes from 'prop-types';

/**
 * React PropType schema for Search Item data
 */
export const SearchableAttributeItem = PropTypes.shape({
  label: PropTypes.string,
  id: PropTypes.string,
  attributeType: PropTypes.string,
  dateRange: PropTypes.bool,
});

export default SearchableAttributeItem;
