import React from 'react';
import PropTypes from 'prop-types';
import '@amzn/awsui-components-react/index.css';
import { FormField, ColumnLayout, Button } from '@amzn/awsui-components-react';
import { FreeTextElement, DropDownElement } from 'common/components/dataEntry';
import CONSTANT from 'utils/constant';
import { TableView } from 'common/components/dataDisplay';

/*
 * Specific setup for enumerated type,
 * Where if user are using a predefined list, they can specify the source system and list name
 * or if using a custom list, they can define the list in following table
 */
class ValidValuesBlock extends React.Component {
  static propTypes = {
    handleDeleteValueClick: PropTypes.func.isRequired,
    changeModalStatus: PropTypes.func.isRequired,
    handleValueChange: PropTypes.func.isRequired,
    setErrorFlag: PropTypes.func.isRequired,

    value: PropTypes.shape({
      listName: PropTypes.string,
      sourceSystem: PropTypes.string,
    }).isRequired,

    validValues: PropTypes.arrayOf(PropTypes.shape({})),
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    validValues: [],
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      indexToRemove: [],
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleDeleteValueClick = this.handleDeleteValueClick.bind(this);
  }

  handleSelectChange(selectedList) {
    this.setState({
      indexToRemove: selectedList.map(element => element.id),
    });
  }

  handleDeleteValueClick() {
    const { handleDeleteValueClick } = this.props;
    const { indexToRemove } = this.state;
    if (indexToRemove.length !== 0) {
      handleDeleteValueClick(indexToRemove);
    }
  }

  render() {
    const {
      changeModalStatus,
      validValues,
      handleValueChange,
      setErrorFlag,
      disabled,
      value,
    } = this.props;

    const header = (
      <div className="awsui-util-action-stripe">
        <div className="awsui-util-action-stripe-title">
          <h4>Create valid value list for your data</h4>
        </div>
        <div className="awsui-util-action-stripe-group">
          <Button
            text="Delete selected values"
            type="submit"
            onClick={this.handleDeleteValueClick}
          />
          <Button
            text="Add a value"
            type="submit"
            variant="primary"
            onClick={() => changeModalStatus(CONSTANT.MODAL_META_ADD_BUTTON_CLICK)}
          />
        </div>
      </div>
    );

    const columnDefinitions = [
      {
        id: 'id',
        header: 'ID',
        cell: item => item.id,
        width: 80,
        minWidth: '80px',
      },
      {
        id: 'value',
        header: 'Valid value',
        cell: item => item.value,
        width: 150,
        minWidth: '100px',
      },
    ];

    const items = [];
    for (let index = 0; index < validValues.length; index += 1) {
      const item = {};
      item.id = index + 1;
      item.value = validValues[index];
      items.push(item);
    }
    const sortableColumns = [{ id: 'id', field: 'id' }, { id: 'value', field: 'value' }];
    const pageSizeOption = [
      { value: 5, label: '5 items' },
      { value: 10, label: '10 items' },
      {
        value: 20,
        label: '20 items',
      },
    ];
    const visibleColumnOption = [
      {
        label: 'Properties',
        options: [
          {
            id: 'id',
            label: 'ID',
            editable: false,
            visible: true,
          },
          {
            id: 'value',
            label: 'Value',
            editable: true,
            visible: true,
          },
        ],
      },
    ];

    // the following cell shall be changed to dropdown in future
    return (
      <div>
        <FormField label="Define valid value for this field" />
        <ul>
          <li>
            <FormField description="If we should validate the value against certain system, please specify the source information here" />
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root>
                <DropDownElement
                  title="Source System"
                  name="sourceSystem"
                  value={value.sourceSystem}
                  options={[
                    {
                      label: 'ICE_DDB',
                      id: 'ICE_DDB',
                    },
                  ]} // now we only support valid values defined in ICTP-Beta dynamoDB
                  disabled={disabled}
                  required={false}
                  description=""
                  handleChange={handleValueChange}
                  setErrorFlag={setErrorFlag}
                />

                <FreeTextElement
                  title="LOV Name"
                  name="listName"
                  value={value.listName}
                  disabled={disabled}
                  required={false}
                  description=""
                  handleChange={handleValueChange}
                  setErrorFlag={setErrorFlag}
                />
              </div>
            </ColumnLayout>
          </li>

          <li>
            <FormField description="Or if there is no existing system to validate this data, please provide the valid value list here" />
            <br />
            <TableView
              tableTitle="valid value list"
              columnDefinitions={columnDefinitions}
              items={items}
              header={header}
              trackBy="value"
              searchMessage="Search a valid value"
              pageSize={10}
              loading={false}
              sortableColumns={sortableColumns}
              pageSizeOption={pageSizeOption}
              visibleColumnOption={visibleColumnOption}
              handleInputChange={v => v /* this.setState({ input: v }) */}
              handleSelectChange={this.handleSelectChange}
            />
          </li>
        </ul>
      </div>
    );
  }
}

export default ValidValuesBlock;
