import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Icon } from '@amzn/awsui-components-react';
import '@amzn/awsui-components-react/index.css';

const propTypes = {
  type: PropTypes.oneOf(['INFO', 'WARNING', 'ERROR', '']),
  actionType: PropTypes.oneOf(['OK_CANCEL', 'OK_ONLY', 'YES_NO', '']), // Default - No buttons
  header: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onYes: PropTypes.func,
  onNo: PropTypes.func,
  visible: PropTypes.bool.isRequired,
  size: PropTypes.string,
  yesButtonText: PropTypes.string,
  noButtonText: PropTypes.string,
};

const defaultProps = {
  type: '',
  actionType: '',
  content: {},
  onNo: () => { },
  onYes: () => { },
  size: 'small',
  yesButtonText: 'Yes',
  noButtonText: 'No',
};

function MJEModal({
  type,
  header,
  content,
  onYes,
  onNo,
  actionType,
  visible,
  size,
  yesButtonText,
  noButtonText,
}) {
  let yesButtonString = yesButtonText;
  let noButtonString = noButtonText;

  if (actionType === 'OK_CANCEL' || actionType === 'OK_ONLY') {
    yesButtonString = 'OK';
    noButtonString = 'Cancel';
  }

  let modalContent = content;

  if (type !== '') {
    let spanClassName = '';
    let iconClassName = '';

    if (type === 'INFO') {
      spanClassName = 'awsui-util-status-info';
      iconClassName = 'status-info';
    } else if (type === 'WARNING') {
      spanClassName = 'awsui-util-status-negative';
      iconClassName = 'status-warning';
    } else if (type === 'ERROR') {
      spanClassName = 'awsui-util-status-negative';
      iconClassName = 'status-negative';
    }

    modalContent = (
      <div className="awsui-grid">
        <div className="awsui-row">
          <div className="col-2 awsui-util-t-c">
            <span className={spanClassName}>
              <Icon name={iconClassName} size="big" />
            </span>
          </div>
          <div className="col-10">{content}</div>
        </div>
      </div>
    );
  }

  return (
    <Modal
      visible={visible}
      size={size}
      header={header}
      footer={
        actionType !== '' && (
          <div className="awsui-util-f-r">
            {actionType !== 'OK_ONLY' && (
              <Button text={noButtonString} variant="link" onClick={onNo} />
            )}
            <Button text={yesButtonString} variant="primary" onClick={onYes} />
          </div>
        )
      }
      onDismiss={onNo}
    >
      {modalContent}
    </Modal>
  );
}

MJEModal.propTypes = propTypes;
MJEModal.defaultProps = defaultProps;

export default MJEModal;
