import React from 'react';
import ReactDataGrid from 'react-data-grid';
import ReactTooltip from 'react-tooltip';
import { Data, Menu, Editors } from 'react-data-grid-addons';
import { Button } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import RDGStyle from 'react-data-grid/dist/react-data-grid.css';
import CONSTANT from 'utils/constant';
import logger from 'utils/logger';
import MJEModal from 'common/components/mjeModal/MJEModal';
import FORMATTERS from 'utils/formatUtils';
import ReactDOM from 'react-dom';
import ErrorFilter from './ErrorFilter';

const { Selectors } = Data;
const {
  ContextMenu, MenuItem, ContextMenuTrigger,
} = Menu;

const { SimpleTextEditor } = Editors;

const sanitizeHtml = require('sanitize-html');

// This code is to make sure react-data-grid.css is bundled.
// eslint-disable-next-line no-console
console.log(RDGStyle);

class NumericColumnEditor extends SimpleTextEditor {
  setInputRef = (input) => {
    super.input = input;
  };

  getValue() {
    const updated = {};
    updated[this.props.column.key] = this.getInputNode().value;
    return updated;
  }

  getInputNode() {
    // eslint-disable-next-line react/no-find-dom-node
    const domNode = ReactDOM.findDOMNode(this);
    if (domNode.tagName === 'INPUT') {
      return domNode;
    }

    return domNode.querySelector('input:not([type=hidden])');
  }

  render() {
    const { onBlur, value } = this.props;
    return (<input ref={this.setInputRef} type="text" onBlur={onBlur} className="form-control rdg-text-editor text-align-right" defaultValue={value} />);
  }
}

class ValidationUIDataGrid extends React.Component {
  static isCellEditable(columnName, rowData) {
    if (rowData == null) {
      return false;
    }

    const { JOURNAL_STATUS } = CONSTANT;
    return rowData.processing_status === JOURNAL_STATUS.SYSTEM_SAVED
      || rowData.processing_status === JOURNAL_STATUS.USER_SAVED
      || rowData.processing_status === JOURNAL_STATUS.NO_APPROVER;
  }

  static handleTooltipContent(dataTip) {
    if (!dataTip) {
      return null;
    }
    const items = JSON.parse(dataTip);
    const itemsHtml = items.map(x => `<li>${x.error_message || x}</li>`).join('');
    return sanitizeHtml(`<ul>${itemsHtml}</ul>`);
  }

  static propTypes = {
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        seqNum: PropTypes.number,
        header: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.oneOf(['required', 'conditional', 'system']),
      }),
    ).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
          PropTypes.arrayOf(PropTypes.string),
          PropTypes.objectOf(PropTypes.arrayOf(
            PropTypes.objectOf(PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.bool,
            ])),
          )),
          PropTypes.objectOf(PropTypes.bool),
        ]),
      ),
    ).isRequired,
    inViewMode: PropTypes.bool,
    onDataGridChange: PropTypes.func,
    onDeleteGridRow: PropTypes.func,
    onAddGridRow: PropTypes.func,
  };

  static defaultProps = {
    inViewMode: false,
    onDataGridChange: () => { },
    onDeleteGridRow: () => { },
    onAddGridRow: () => { },
  };


  constructor(props) {
    super(props);
    this.state = {
      enableHeaderFilters: false,
      filters: {},
    };
    // this.onGridSort = this.onGridSort.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onGridRowsUpdated = this.onGridRowsUpdated.bind(this);
    this.getRows = this.getRows.bind(this);
    this.getRow = this.getRow.bind(this);
    this.getSize = this.getSize.bind(this);
    this.deleteRowConfirmed = this.deleteRowConfirmed.bind(this);
    this.deleteRowCancelled = this.deleteRowCancelled.bind(this);
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }


  // onGridSort(sortColumn, sortDirection) {
  //   this.setState({ sortColumn, sortDirection });
  // }

  onFilterClick() {
    this.setState((prevState) => {
      const enableHeaderFilters = !prevState.enableHeaderFilters;
      if (!enableHeaderFilters) {
        return {
          enableHeaderFilters,
          filters: {},
        };
      }
      return { enableHeaderFilters };
    });
  }

  onFilterChange(filter) {
    const { filters } = this.state;
    const newFilters = { ...filters };
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    this.setState({ filters: newFilters });
  }

  onGridRowsUpdated({ fromRow, toRow, updated }) {
    const { onDataGridChange } = this.props;
    const rows = this.getRows();
    const [start, end] = fromRow <= toRow ? [fromRow, toRow] : [toRow, fromRow];
    for (let i = start; i <= end; i += 1) {
      Object.keys(updated).forEach(attribute => onDataGridChange(
        rows[i].index, attribute, updated[attribute],
      ));
    }
  }


  getRows() {
    const { items } = this.props;
    const { filters } = this.state;
    return Selectors.getRows({
      rows: items,
      filters,
    });
  }

  getRow(i) {
    return this.getRows()[i];
  }

  getSize() {
    return this.getRows().length;
  }

  static renderHeader(column) {
    return (
      <div
        data-tip={column.description}
        data-for="grid-header-tooltip"
        className={`data-grid-header data-grid-header-${column.type}`}
      >
        {column.header}
      </div>
    );
  }

  static renderCell(columnName, rowData, formatterFunction, dataType) {
    let tooltipItems;
    let className = '';
    let cellValue;
    if (rowData.errors && rowData.errors[columnName]) {
      tooltipItems = rowData.errors[columnName];
      const isWarning = rowData.errors[columnName]
        .map(error => error.warning)
        .reduce((i, j) => i && j);
      className = isWarning || rowData.errorsEdited[columnName] ? 'data-grid-warning' : 'data-grid-error';
    }

    if (!ValidationUIDataGrid.isCellEditable(columnName, rowData)) {
      className = 'data-grid-uneditable';
    }

    switch (columnName) {
      case 'index':
        cellValue = rowData[columnName] + 1;
        break;
      case 'error_display':
        if (rowData[columnName] && rowData[columnName].length > 0) {
          cellValue = rowData[columnName].join('; ');
          tooltipItems = rowData[columnName];
        }
        break;
      default:
        cellValue = rowData[columnName];
    }

    // If renderCell function is invoked when entering edit mode the formatter function
    // is not provided
    if (formatterFunction != null) {
      cellValue = formatterFunction(cellValue);
    }

    if (FORMATTERS.isNumericDataType(dataType)) {
      className += ' text-align-right';
    }

    return (
      <div data-tip={tooltipItems ? JSON.stringify(tooltipItems) : ''} data-for="data-grid-tooltip" className={`data-grid-cell ${className}`}>
        {cellValue}
      </div>
    );
  }

  deleteRow(rowIndex) {
    const row = this.getRow(rowIndex);
    this.setState({
      deletingRow: row,
      confirmRowDeletion: true,
    });
  }

  deleteRowConfirmed() {
    const { deletingRow } = this.state;
    const { onDeleteGridRow } = this.props;
    onDeleteGridRow({
      workbook_id: deletingRow.workbook_id,
      journal_header_id: deletingRow.journal_header_id,
      seq_id: deletingRow.seq_id,
      index: deletingRow.index,
    });
    this.setState({
      confirmRowDeletion: false,
    });
  }

  deleteRowCancelled() {
    logger.logInfo('deleteRow cancelled!');
    this.setState({
      confirmRowDeletion: false,
    });
  }

  addRow(rowIndex, type) {
    const { onAddGridRow } = this.props;
    const row = this.getRow(rowIndex);
    onAddGridRow(row, type);
  }


  render() {
    const { columns, inViewMode } = this.props;
    const {
      enableHeaderFilters,
      confirmRowDeletion,
      deletingRow,
    } = this.state;

    const columnDefs = columns.map((column, index) => {
      let width;
      switch (column.name) {
        case 'index':
          width = 50;
          break;
        case 'error_display':
          width = 250;
          break;
        case 'exchange_rate':
          width = 200;
          break;
        default:
          width = column.header.length * 8 + 20;
      }

      const formatterFunction = FORMATTERS.resolveFormatter(column.dataType);
      const columnEditor = FORMATTERS.isNumericDataType(column.dataType)
        ? NumericColumnEditor : SimpleTextEditor;

      let columnDef = {
        key: column.name,
        name: column.header,
        width,
        editable: rowData => !inViewMode && column.type !== 'system' && ValidationUIDataGrid.isCellEditable(column.name, rowData),
        // sortable: true,
        filterable: index !== 0,
        frozen: index === 0,
        resizable: true,
        headerRenderer: ValidationUIDataGrid.renderHeader(column),
        cellContentRenderer: ({ rowData }) => ValidationUIDataGrid.renderCell(
          column.name, rowData, formatterFunction, column.dataType,
        ),
        editor: columnEditor,
      };

      if (column.name === 'error_display') {
        columnDef = { ...columnDef, filterRenderer: ErrorFilter };
      }

      return columnDef;
    });
    let previousJournalDividerId = null;
    let previousTransactionDividerId = null;
    const customRowRenderer = ({ renderBaseRow, ...rowProps }) => {
      let rowContainerClass = '';
      if (rowProps.idx > 0) {
        if (rowProps.row.ic_transaction_group + rowProps.row.journal_batch_id
          !== previousTransactionDividerId) {
          rowContainerClass = 'transaction-bordered-container';
        } else if (rowProps.row.ic_matching_id + rowProps.row.journal_batch_id
          !== previousJournalDividerId) {
          rowContainerClass = 'journal-bordered-container';
        }
      }
      previousJournalDividerId = rowProps.row.ic_matching_id + rowProps.row.journal_batch_id;
      previousTransactionDividerId = rowProps.row.ic_transaction_group
                                      + rowProps.row.journal_batch_id;
      return <div className={rowContainerClass}>{renderBaseRow(rowProps)}</div>;
    };

    const rowsCount = this.getSize();

    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h4>
                Journal Entry Lines (
                {rowsCount}
                )
              </h4>
            </div>
            <div className="awsui-util-action-stripe-group awsui-util-pt-xs">
              <Button
                text="Filter Rows"
                icon="filter"
                iconAlign="right"
                onClick={this.onFilterClick}
              />
            </div>
          </div>
        </div>
        <div className="data-grid">
          <ReactDataGrid
            columns={columnDefs}
            rowGetter={this.getRow}
            rowsCount={rowsCount}
            minColumnWidth={50}
            minHeight={400}
            enableCellSelect
            enableRowSelect
            enableHeaderFilters={enableHeaderFilters}
            // sortDirection={sortDirection}
            // sortColumn={sortColumn}
            // onGridSort={this.onGridSort}
            onAddFilter={this.onFilterChange}
            onGridRowsUpdated={this.onGridRowsUpdated}
            onScroll={ReactTooltip.rebuild}
            rowRenderer={customRowRenderer}
            contextMenu={inViewMode ? null : (
              <GridContextMenu
                id="contexMenuId"
                onDeleteRow={(e, { rowIdx }) => this.deleteRow(rowIdx)}
                onAddRowToJournal={(e, { rowIdx }) => this.addRow(rowIdx, 'ROW')}
                onAddJournalToBatch={(e, { rowIdx }) => this.addRow(rowIdx, 'JOURNAL')}
                onCopyRowToJournal={(e, { rowIdx }) => this.addRow(rowIdx, 'COPY_TO_JOURNAL')}
                rowGetter={this.getRow}
              />
            )}
            RowsContainer={ContextMenuTrigger}
          />
          <ReactTooltip
            id="grid-header-tooltip"
            place="top"
            type="dark"
            effect="solid"
            multiline
            event="mouseenter"
            eventOff="mouseleave"
          />
          <ReactTooltip
            id="data-grid-tooltip"
            place="top"
            type="dark"
            effect="solid"
            event="mouseenter"
            eventOff="mouseleave"
            clickable
            multiline
            html
            delayHide={1000}
            getContent={ValidationUIDataGrid.handleTooltipContent}
          />
        </div>
        {confirmRowDeletion && (
          <MJEModal
            visible={confirmRowDeletion}
            header="Please Confirm"
            content={`Are you sure you want to delete journal entry line #${(deletingRow.index + 1)}?`}
            actionType="YES_NO"
            onYes={this.deleteRowConfirmed}
            onNo={this.deleteRowCancelled}
          />
        )}
      </div>
    );
  }
}

function GridContextMenu({
  idx,
  id,
  rowIdx,
  onDeleteRow,
  onAddRowToJournal,
  onAddJournalToBatch,
  onCopyRowToJournal,
  rowGetter,
}) {
  let shouldBeDisabled = false;
  if (rowGetter) {
    const row = rowGetter(rowIdx);
    shouldBeDisabled = !ValidationUIDataGrid.isCellEditable({}, row);
  }
  return (
    <ContextMenu id={id}>
      <MenuItem data={{ rowIdx, idx }} onClick={onAddRowToJournal} disabled={shouldBeDisabled}>
        Add Line to this Journal
      </MenuItem>
      <MenuItem data={{ rowIdx, idx }} onClick={onAddJournalToBatch} disabled={shouldBeDisabled}>
        Add Journal to this Batch
      </MenuItem>
      <MenuItem data={{ rowIdx, idx }} onClick={onCopyRowToJournal} disabled={shouldBeDisabled}>
        Copy Line to this Journal
      </MenuItem>
      <div className="react-contextmenu-item--divider" />
      <MenuItem data={{ rowIdx, idx }} onClick={onDeleteRow} disabled={shouldBeDisabled}>
        Delete Line
      </MenuItem>
    </ContextMenu>
  );
}
// case 'COPY_TO_JOURNAL':

export default ValidationUIDataGrid;
