import PropTypes from 'prop-types';
import { date } from './customProps';

/**
 * React PropType schema for MJE data
 */
export const MJE = PropTypes.shape({
  AGREEMENT_LINE_NUMBER: PropTypes.string,
  Account: PropTypes.string,
  Accounted_CR: PropTypes.string,
  Accounted_DR: PropTypes.string,
  Accounting_Date: PropTypes.string,
  Company: PropTypes.string,
  Cost_Center: PropTypes.string,
  Curr_Conv_Date: PropTypes.string,
  Curr_Conversion_Type: PropTypes.string,
  Curr_Rate: PropTypes.string,
  Entered_CR: PropTypes.string,
  Entered_DR: PropTypes.string,
  Geo_Market: PropTypes.string,
  IC_Journal_Type: PropTypes.string,
  IC_Matching_ID: PropTypes.string,
  Journal_Category: PropTypes.string,
  Journal_Currency: PropTypes.string,
  Journal_Description: PropTypes.string,
  Journal_Name: PropTypes.string,
  Line_Description: PropTypes.string,
  Location: PropTypes.string,
  Product_Line: PropTypes.string,
  Project: PropTypes.string,
  Reversal_Period: PropTypes.string,
  Tax_Category: PropTypes.string,
  Tax_Sub_Category: PropTypes.string,
  Trading_Partner: PropTypes.string,
  Transaction_Type: PropTypes.string,
});

export const JournalHeader = PropTypes.shape({
  // Shared Props
  journal_header_id: PropTypes.number,
  journal_line_id: PropTypes.number,
  item_type: PropTypes.string,
  create_user: PropTypes.string,
  create_date: date,
  last_update_user: PropTypes.string,
  last_update_date: date,
  workbook_id: PropTypes.string,
  journal_batch_id: PropTypes.string,

  // Header-Specific Props
  processing_status: PropTypes.string,
  type: PropTypes.string,
  workbook_name: PropTypes.string,
  journal_batch_name: PropTypes.string,
  upload_name: PropTypes.string,
  journal_create_method: PropTypes.string,
  document_type: PropTypes.string,
  accounting_date: date,
  approver_company: PropTypes.string,
  journal_currency: PropTypes.string,
  journal_name: PropTypes.string,
  journal_category: PropTypes.string,
  transaction_type: PropTypes.string,
  ic_transaction_group: PropTypes.number,
  journal_desc: PropTypes.string,
  exchange_rate: PropTypes.number,
  currency_conv_type: PropTypes.string,
  currency_conv_date: date,
  reversal_period: PropTypes.string,
  credit_memo_invoice_yr: PropTypes.number,
  ic_transaction_id: PropTypes.string,
  invoice_status: PropTypes.string,
  invoice_num: PropTypes.number,
  invoice_link: PropTypes.string,
  reversed: PropTypes.bool,
  reversal_method: PropTypes.string,
  orig_journal_id: PropTypes.number,
  approve_user: PropTypes.string,
  approve_date: date,
});

export const JournalLine = PropTypes.shape({
  // Shared Props
  journal_header_id: PropTypes.number,
  journal_line_id: PropTypes.number,
  item_type: PropTypes.string,
  create_user: PropTypes.string,
  create_date: date,
  last_update_user: PropTypes.string,
  last_update_date: date,
  workbook_id: PropTypes.string,
  journal_batch_id: PropTypes.string,

  // Line-Specific Props
  line_desc: PropTypes.string,
  company: PropTypes.string,
  location: PropTypes.string,
  cost_center: PropTypes.string,
  account: PropTypes.string,
  product_line: PropTypes.string,
  channel_geo: PropTypes.string,
  project_code: PropTypes.string,
  entered_dr: PropTypes.number,
  entered_cr: PropTypes.number,
  ic_journal_type: PropTypes.string,
  ic_matching_id: PropTypes.number,
  entry_recurring: PropTypes.string,
  provider_or_recipient: PropTypes.string,
  accounted_dr: PropTypes.number,
  accounted_cr: PropTypes.number,
  trading_partner: PropTypes.string,
  time_reqd: PropTypes.string,
  upstream_system: PropTypes.string,
  process_documented: PropTypes.string,
  close_mgr_task_num: PropTypes.number,
  agreement_line_num: PropTypes.number,
  product_cat_name: PropTypes.string,
  product_subcat_name: PropTypes.string,
  indirect_tax_rate: PropTypes.number,
  ic_event_type: PropTypes.string,
  ic_line_offset: PropTypes.number,
  ic_partner_offset: PropTypes.number,
  prov_legal_ent_name: PropTypes.string,
  prov_jurisdiction: PropTypes.string,
  rec_legal_ent_name: PropTypes.string,
  rec_jurisdiction: PropTypes.string,
  indirect_tax_reg_code: PropTypes.string,
  indirect_tax_rate_code: PropTypes.string,
  system_derived_tax: PropTypes.bool,
  tax_ref_link: PropTypes.number,
  tax_gen_type: PropTypes.string,
  ledger: PropTypes.string,
});

export const Journal = PropTypes.shape({
  header: JournalHeader,
  lines: PropTypes.arrayOf(JournalLine),
});

export const JournalBatch = PropTypes.shape({
  journals: PropTypes.arrayOf(Journal),
});

export const Workbook = PropTypes.shape({
  journalBatches: PropTypes.arrayOf(JournalBatch),
});

export const WorkbookCopyResponse = PropTypes.shape({
  workbookID: PropTypes.string,
});

export const FetchReversalPeriodsResponse = PropTypes.shape({
  methods: PropTypes.arrayOf(PropTypes.shape({
    displayName: PropTypes.string,
    value: PropTypes.string,
  })),
  periods: PropTypes.arrayOf(PropTypes.shape({
    displayValue: PropTypes.string,
    lastDayOfMonth: PropTypes.string,
  })),
});

export default MJE;
