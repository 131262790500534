import FileUpload from './FileUpload';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import StepSix from './StepSix';

export {
  StepOne, StepTwo, StepThree, StepFour, StepFive, StepSix,
};

export default FileUpload;
