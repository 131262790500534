import React from 'react';
import PropTypes from 'prop-types';
import '@amzn/awsui-components-react/index.css';
import {
  Button, ColumnLayout, FormField, Input,
} from '@amzn/awsui-components-react';

/*
 * Search bar with key word as table name
 */
class SearchBar extends React.Component {
  static propTypes = {
    handleQueryClick: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      templateName: '',
    };
  }

  render() {
    const { templateName } = this.state;
    const { handleQueryClick } = this.props;

    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <h2>Query Meta Data</h2>
              <FormField>
                <Input
                  placeholder="Enter template name here"
                  value={templateName}
                  onChange={event => this.setState({ templateName: event.target.component.value })}
                />
              </FormField>
              <br />
              <Button
                text="Search"
                variant="primary"
                icon="search"
                className="awsui-util-mb-xl"
                type="submit"
                onClick={() => handleQueryClick(templateName)}
              />
            </div>
          </ColumnLayout>
        </div>
      </div>
    );
  }
}

export default SearchBar;
