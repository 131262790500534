import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Table, ColumnLayout, Button,
} from '@amzn/awsui-components-react';
import ReactTooltip from 'react-tooltip';

const tableColumns = [
  {
    id: 'index',
    header: '#',
    cell: item => item.index,
  },
  {
    id: 'journal_batch_name',
    header: 'Journal Batch',
    cell: item => item.journal_batch_name,
  },
  {
    id: 'ic_transaction_group',
    header: 'Transaction Group',
    cell: item => item.ic_transaction_group,
  },
  {
    id: 'journal_name',
    header: 'Journal Name',
    cell: item => item.journal_name,
  },
  {
    id: 'indirect_tax_rate',
    header: 'Rate',
    cell: item => item.indirect_tax_rate,
  },
  {
    id: 'journalIsValid',
    header: 'Is Valid',
    cell: item => (
      <div style={{
        backgroundColor: item.journalIsValid ? '#ffffff' : '#f8c6c6', // bright red for false
        padding: '8px', // Add padding for better spacing
        borderRadius: '4px', // Add border radius for rounded corners
      }}
      >
        {item.journalIsValid ? 'True' : 'False'}
      </div>
    ),
  },
  {
    id: 'numLinesCreated',
    header: '#Lines Created',
    cell: item => item.numLinesCreated,
  },
];

class TaxAutomationModal extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    workbookName: PropTypes.string,
    noTaxAutomation: PropTypes.bool,
    indirectTaxSummary: PropTypes.arrayOf(PropTypes.shape({
      index: PropTypes.number,
      journal_batch_name: PropTypes.string,
      ic_transaction_group: PropTypes.string,
      journal_name: PropTypes.string,
      indirect_tax_rate: PropTypes.string,
      numLinesCreated: PropTypes.number,
      journalIsValid: PropTypes.bool,
    })),

    onClickBack: PropTypes.func,
    onClickView: PropTypes.func,
    onClickPost: PropTypes.func,
    onDismiss: PropTypes.func,
  };

  static defaultProps = {
    visible: false,
    workbookName: '',
    noTaxAutomation: null,
    indirectTaxSummary: [],
    onClickBack: () => {},
    onClickView: () => {},
    onClickPost: () => {},
    onDismiss: () => {},
  };

  renderFooter() {
    const { onClickBack, onClickView, onClickPost, noTaxAutomation, indirectTaxSummary } = this.props;
    const disablePostButton = indirectTaxSummary.some(item => item.journalIsValid === false);

    return (
      <ColumnLayout columns={2}>
        <div data-awsui-column-layout-root="true">
          <div>
            <Button
              text="Back"
              onClick={onClickBack}
              data-tip="Go back to entry to make changes"
              data-for="indirect-tax-buttons"
            />
            <Button
              text="View Complete Entry"
              onClick={onClickView}
              data-tip="View entry with automated tax lines"
              data-for="indirect-tax-buttons"
              disabled={!!noTaxAutomation}
            />
          </div>
          <div>
            <Button
              text="Post"
              variant="primary"
              className="awsui-util-f-r"
              onClick={onClickPost}
              data-tip="Post entry to G/L"
              data-for="indirect-tax-buttons"
              disabled={disablePostButton}
            />
          </div>
          <ReactTooltip
            id="indirect-tax-buttons"
            place="bottom"
            type="dark"
            effect="solid"
            event="mouseenter"
            eventOff="mouseleave"
          />
        </div>
      </ColumnLayout>
    );
  }

  render() {
    const {
      visible, workbookName, noTaxAutomation, indirectTaxSummary, onDismiss,
    } = this.props;
    return (
      <Modal
        visible={visible}
        header={workbookName}
        footer={this.renderFooter()}
        size={noTaxAutomation === true ? "medium" : "large"}
        onDismiss={onDismiss}
        className="tax-modal"
      >
        { noTaxAutomation ? (
            <div>
              <p style={{textAlign: 'center', marginTop: '20px'}}>No auto tax lines generated for the workbook</p>
            </div>
        ) : (
        <Table
          header={<h4>Summary of Indirect Tax Auto-generated Lines:</h4>}
          columnDefinitions={tableColumns}
          items={indirectTaxSummary}
        />
        )}
      </Modal>
    );
  }
}

export default TaxAutomationModal;
