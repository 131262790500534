import React from 'react';
import PropTypes from 'prop-types';
import { FormField } from '@amzn/awsui-components-react';
import { DatePicker } from 'antd';
import ErrorBoundary from 'common/components/err/errorBoundary';
import '@amzn/awsui-components-react/index.css';
import 'antd/dist/antd.css';
import 'index.css';
import config from 'config/config.json';
import moment from 'moment';
import Logger from 'utils/logger';

// Component for date input
class DateElement extends React.Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    setErrorFlag: PropTypes.func.isRequired,

    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,

    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,

    required: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    name: '',
    title: '',
    description: '',

    required: false,
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      errorText: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date, dateString) {
    const { errorText } = this.state;
    const {
      handleChange, setErrorFlag, name, min, max, required,
    } = this.props;

    // according to ant design library, date with invalid format won't trigger this function
    Logger.logInfo(`dateString = ${dateString}`);
    if (dateString === '' && required) this.setState({ errorText: 'This field is required' });
    else if (min !== undefined && Date.parse(dateString) < Date.parse(min)) this.setState({ errorText: `Only date after ${min} is allowed` });
    else if (max !== undefined && Date.parse(dateString) > Date.parse(max)) this.setState({ errorText: `Only date before ${max} is allowed` });
    else this.setState({ errorText: '' });
    Logger.logInfo(`errorText = ${errorText}`);
    handleChange(name, dateString);
    setErrorFlag(name, errorText);
  }

  render() {
    const { errorText } = this.state;
    const {
      value, disabled, required, description, title,
    } = this.props;

    let datePicker = '';
    if (value === undefined || value === '') {
      datePicker = (
        <DatePicker format={config.dateFormat} onChange={this.handleChange} disabled={disabled} />
      );
    } else {
      datePicker = (
        <DatePicker
          format={config.dateFormat}
          onChange={this.handleChange}
          value={moment(value)}
          disabled={disabled}
        />
      );
    }

    return (
      <ErrorBoundary content={title}>
        <FormField
          label={title + (required ? '*' : '')}
          description={description}
          errorText={errorText}
        >
          {datePicker}
        </FormField>
      </ErrorBoundary>
    );
  }
}

export default DateElement;
