import * as CONST from 'common/const';
import api from 'utils/api';
import logger from 'utils/logger';
import moment from 'moment';
import config from 'config/config.json';
import CONSTANT from '../utils/constant';
import ArrayUtils from '../utils/arrayUtils';

const updateReversalGroupingBy = (index, getState) => {
  const { reversal, dataGrid } = getState().validationUI;
  const { reversal_period, ic_transaction_group, journal_batch_name } = dataGrid[index];
  const allReversals = Array.from(reversal.values()).flat();
  let hasMixedReversalPeriod = false;
  for (let i = 0; i < dataGrid.length; i += 1) {
    const row = dataGrid[i];
    if (row.journal_batch_name === journal_batch_name && row.ic_transaction_group === ic_transaction_group && row.reversal_period !== reversal_period) {
      hasMixedReversalPeriod = true;
      break;
    }
  }
  if (hasMixedReversalPeriod || reversal_period == null) {
    allReversals.map((item) => {
      if (item.selected && item.icTransactionGroup === ic_transaction_group && item.batchName === journal_batch_name) {
        return {
          ...item,
          selected: false,
          selectedPeriod: null,
          selectedMethod: null,
        };
      }
      return item;
    });
    // Resetting selected method since previous selection was cleared out
    dataGrid.filter(x => x.ic_transaction_group === ic_transaction_group).map(x => ({
      ...x,
      reversal_method: null,
    }));
  } else { // Then we probably need to set the selected period in the reversal grouping if different
    allReversals.map((item) => {
      if (item.icTransactionGroup === ic_transaction_group && item.lastDayOfMonth !== reversal_period && item.batchName === journal_batch_name) {
        const period = item.periods.find(x => x.lastDayOfMonth === reversal_period);
        return {
          ...item,
          selectedPeriod: period == null ? null : period.displayValue,
          selected: period != null,
        };
      }
      return item;
    });
  }
};

export const editDataGrid = (index, column, value) => (dispatch, getState) => {
  dispatch({
    type: CONST.VALIDATION_UI_EDIT_DATA_GRID,
    index,
    column,
    value,
    last_update_user: getState().auth.username,
    last_update_date: moment()
      .format(config.dateFormat)
      .toUpperCase(),
  });
  if (column === 'reversal_period') {
    updateReversalGroupingBy(index, getState);
  }
};

export const editReversal = (
  batchName,
  index,
  selected,
  selectedPeriod,
  selectedMethod,
) => ({
  type: CONST.VALIDATION_UI_EDIT_REVERSAL,
  batchName,
  index,
  selected,
  selectedPeriod,
  selectedMethod,
});

export const fetchDataStart = () => ({
  type: CONST.VALIDATION_UI_FETCH_DATA_START,
});

export const fetchDataSuccess = () => ({
  type: CONST.VALIDATION_UI_FETCH_DATA_SUCCESS,
});

export const fetchDataFailure = (error = null) => ({
  type: CONST.VALIDATION_UI_FETCH_DATA_FAILURE,
  globalError: error,
});

export const undoReversalSuccess = () => ({
  type: CONST.VALIDATION_UI_UNDO_REVERSAL_SUCCESS,
});

export const updatePage = (data = {}) => ({
  type: CONST.VALIDATION_UI_UPDATE_PAGE,
  data,
});

export const updateFileNames = (data = []) => ({
  type: CONST.VALIDATION_UI_UPDATE_FILE_NAMES,
  data,
});

export const updateStatistics = (data = {}) => ({
  type: CONST.VALIDATION_UI_UPDATE_STATISTICS,
  data,
});

export const updateDataGrid = (data = {}) => ({
  type: CONST.VALIDATION_UI_UPDATE_DATA_GRID,
  data,
});

const dataGridHelper = (rawDataGrid) => {
  const dataGrid = [];
  (rawDataGrid || []).forEach((row, index) => {
    if (row.errors && Object.keys(row.errors).length > 0) {
      dataGrid.push({
        ...row,
        index,
        errorsEdited: Object.assign(
          {},
          ...Object.keys(row.errors).map(column => ({
            [column]: false,
          })),
        ),
      });
    } else {
      dataGrid.push({
        ...row,
        index,
      });
    }
  });

  return dataGrid;
};

function addReversalItemsInTransactionToList(batchName, ictg, rowsInTransaction, serverReversalsList, listToAdd) {
  const reversalItems = serverReversalsList.filter(x => x.icTransactionGroup === ictg);
  reversalItems.forEach((r) => {
    const firstRowMatchingReversalItem = rowsInTransaction.find(
      x => x.journal_name === r.journalName && x.ic_transaction_group === ictg
    );
    const selectedPeriod = (
      firstRowMatchingReversalItem == null || firstRowMatchingReversalItem.reversal_period == null
        ? null
        : moment(firstRowMatchingReversalItem.reversal_period, 'DD-MMM-YYYY').format('MMM-YYYY').toUpperCase()
    );
    const selectedMethod = firstRowMatchingReversalItem == null
      ? null
      : firstRowMatchingReversalItem.reversal_method;
    listToAdd.push({
      ...r,
      selectedPeriod,
      selectedMethod,
      icTransactionGroupId: firstRowMatchingReversalItem == null
        ? null
        : firstRowMatchingReversalItem.ic_transaction_id,
      batchName,
      selected: firstRowMatchingReversalItem != null && firstRowMatchingReversalItem.reversal_period != null,
      hasRequiredFieldError: selectedMethod == null || selectedPeriod == null,
    });
  });
}

function buildBatchesAndReversalsMap(dataGrid, reversalsList) {
  const allRowsGroupedByBatchName = ArrayUtils.groupBy(dataGrid, 'journal_batch_name');
  const resultMap = new Map();
  for (const [batchName, rowsInBatch] of Object.entries(allRowsGroupedByBatchName)) {
    const allTransactionsInBatch = ArrayUtils.groupBy(rowsInBatch, 'ic_transaction_group');
    const listOfReversalsInBatch = resultMap.get(batchName) || [];

    for (const [ictg, rowsInTransaction] of Object.entries(allTransactionsInBatch)) {
      addReversalItemsInTransactionToList(batchName, ictg, rowsInTransaction, reversalsList, listOfReversalsInBatch);
    }
    resultMap.set(batchName, listOfReversalsInBatch);
  }
  return resultMap;
}


export const parseFetchValidationResponse = (response, dispatch) => {
  const result = response.validationResponse;

  const dataGrid = dataGridHelper(result.dataGrid);

  const reversal = (result.reversal || []).map(entry => ({
    ...entry,
    disabled: entry.disabled,
  }));

  const dataGridColumns = [...result.dataGridColumns];

  dataGridColumns.unshift({
    description: 'Index',
    header: '#',
    name: 'index',
    seqNum: 0,
    type: 'system',
  });

  const batchesAndReversalsMap = buildBatchesAndReversalsMap(dataGrid, reversal);


  const data = {
    ...result,
    reversal: batchesAndReversalsMap,
    dataGridColumns,
    dataGrid,
    originalModels: {
      reversal: new Map(batchesAndReversalsMap),
      dataGrid: Object.assign([], dataGrid.map(x => Object.assign({}, x))),
    },
  };

  dispatch(updatePage(data));
  dispatch(fetchDataSuccess());
};


export const fetchInitialData = (requestType, requestId, inTaxMode) => async (dispatch) => {
  dispatch(fetchDataStart());

  try {
    const response = await api.fetchValidation(requestType, requestId, inTaxMode);
    parseFetchValidationResponse(response, dispatch);
  } catch (error) {
    logger.logError('fetchValidation failure:\n', error.stack);
    dispatch(
      fetchDataFailure(error),
    );
  }
};

export const setReversalValues = (
  dataGrid, transactions, dispatch, getState, selectedPeriod, selectedMethod,
) => {
  const indexes = dataGrid.filter(row => transactions.some(t => t.icTransactionGroup === row.ic_transaction_group && t.batchName === row.journal_batch_name))
    .map(row => row.index);

  dispatch({
    type: CONST.VALIDATION_UI_EDIT_DATA_GRID_BATCH,
    indexes,
    reversal_period: !selectedPeriod ? null : selectedPeriod.lastDayOfMonth,
    reversal_method: !selectedMethod ? null : selectedMethod.value,
    last_update_user: getState().auth.username,
    last_update_date: moment()
      .format(config.dateFormat)
      .toUpperCase(),
  });
};

export const setReversal = () => (dispatch, getState) => {
  dispatch(fetchDataStart());

  const { reversal, dataGrid, reversalAllJournals } = getState().validationUI;
  const allJournalsItem = reversalAllJournals;
  const allReversals = Array.from(reversal.values()).flat();
  const firstReversalItem = allReversals[0];
  const allPeriods = firstReversalItem.periods || [];
  const allMethods = firstReversalItem.methods || [];
  // If 'All Journals' is checked
  if (allJournalsItem.selected) {
    const selectedPeriod = allPeriods.find(
      x => x.displayValue === allJournalsItem.selectedPeriod,
    );
    const selectedMethod = allMethods.find(
      x => x.displayName === allJournalsItem.selectedMethod,
    );
    setReversalValues(dataGrid, allReversals, dispatch, getState, selectedPeriod, selectedMethod);
  } else {
    const reversalGroups = new Map();
    for (let i = 0; i < allReversals.length; i++) {
      const item = allReversals[i];
      const selectedPeriod = allPeriods.find(x => x.displayValue === item.selectedPeriod) || null;
      const selectedMethod = allMethods.find(x => x.displayName === item.selectedMethod) || null;
      const clear = !item.selected && !item.disabled;
      if (!clear && (!selectedPeriod || !selectedMethod)) {
        item.hasRequiredFieldError = true;
        continue;
      }

      const key = clear ? 'clear' : `${selectedPeriod.displayValue || null}-${selectedMethod.displayName || null}`;
      const group = reversalGroups.get(key) || {
        selectedPeriod,
        selectedMethod,
        clear,
        transactions: [],
      };
      group.transactions.push(item);
      reversalGroups.set(key, group);
    }
    reversalGroups.forEach((group, key) => {
      setReversalValues(dataGrid, group.transactions, dispatch, getState, group.clear ? null : group.selectedPeriod, group.clear ? null : group.selectedMethod);
    });
  }
  return Promise.resolve().then(() => {
    dispatch(fetchDataSuccess());
  });
};

export const undoReversal = () => (dispatch, getState) => {
  const { reversal, dataGrid, originalModels } = getState().validationUI;
  const allReversals = Array.from(reversal.values()).flat();
  const allOriginalReversals = Array.from(originalModels.reversal.values()).flat();

  for (let index = 0; index < allReversals.length; index += 1) {
    const reversalItem = allReversals[index];
    let originalReversalItem;
    if (index < allOriginalReversals.length) {
      originalReversalItem = allOriginalReversals[index];
    }
    if (originalReversalItem != null && originalReversalItem.icTransactionGroup === reversalItem.icTransactionGroup) {
      dispatch(editReversal(
        originalReversalItem.batchName,
        index,
        originalReversalItem.selected,
        originalReversalItem.selectedPeriod,
        originalReversalItem.selectedMethod,
      ));
    }
    for (let rowIndex = 0; rowIndex < dataGrid.length; rowIndex += 1) {
      const row = dataGrid[rowIndex];
      if (row.ic_transaction_group === reversalItem.icTransactionGroup
        && rowIndex < originalModels.dataGrid.length) {
        const originalRow = originalModels.dataGrid[rowIndex];
        row.reversal_period = originalRow.reversal_period;
        row.reversal_method = originalRow.reversal_method;
      }
    }
  }
};

async function saveDataGrid(dataGrid, dataGridChanges, userName) {
  try {
    const updatedRowList = dataGrid.filter(row => dataGridChanges.indexOf(row.seq_id) > -1);
    const result = await api.updateWorkbook({ updatedRowList, userName });
    logger.logInfo('saveDataGrid result: ', result);
  } catch (error) {
    logger.logError('saveDataGrid failure:\n', error.stack);
    throw error;
  }
}

export const revalidateComplete = () => ({
  type: CONST.VALIDATION_UI_REVALIDATE_COMPLETE,
});

export const revalidateStart = () => ({
  type: CONST.VALIDATION_UI_REVALIDATE_START,
});


export const revalidateDataGrid = (workbook_id, callback = null) => async (dispatch, getState) => {
  dispatch(revalidateStart());
  dispatch(fetchDataStart());
  let validated = false;

  try {
    const { dataGrid, dataGridChanges } = getState().validationUI;
    const { username } = getState().auth;
    await saveDataGrid(dataGrid, dataGridChanges, username);

    const runWorkflowResult = await api.runMJEWorkflow(workbook_id);
    if (runWorkflowResult.status === CONSTANT.WORKFLOW_STATUS.FAILED) {
      dispatch(fetchDataFailure(CONSTANT.WORKFLOW_FAILURE_ERROR_MESSAGE));
      return;
    }
    if (runWorkflowResult.status === CONSTANT.WORKFLOW_STATUS.TIMEOUT) {
      dispatch(fetchDataFailure(CONSTANT.WORKFLOW_TIMEOUT_ERROR_MESSAGE));
      return;
    }

    const fetchValidationResult = await api.fetchValidation(
      CONSTANT.FILE_REQUEST_TYPES.WORKBOOK,
      workbook_id,
      false,
    );
    const result = fetchValidationResult.validationResponse;

    validated = result !== null && result.statistics !== null;
    if (validated && result.statistics.numErrors <= 0 && callback != null) {
      callback(false);
      return;
    }
    dispatch(updateStatistics({ statistics: result.statistics }));
    parseFetchValidationResponse(fetchValidationResult, dispatch);
    dispatch(fetchDataSuccess());
    dispatch(revalidateComplete());
  } catch (error) {
    logger.logError('revalidate failure:\n', error.stack);
    dispatch(fetchDataFailure(CONSTANT.GENERIC_ERROR_MESSAGE));
  }

  if (callback != null) {
    callback(validated ? true : null);
  }
};

export const postFileStart = () => ({
  type: CONST.VALIDATION_UI_POST_FILE_START,
});

export const postFileSuccess = (payload = {}) => ({
  type: CONST.VALIDATION_UI_POST_FILE_SUCCESS,
  payload,
});

export const postFileFailure = (error = null) => ({
  type: CONST.VALIDATION_UI_POST_FILE_FAILURE,
  globalError: error,
});

export const clearPostingSummarySuccess = () => ({
  type: CONST.VALIDATION_UI_CLEAR_POSTING_SUMMARY,
});

export const postFile = payload => async (dispatch /* , getState */) => {
  dispatch(postFileStart());
  try {
    const response = await api.postFile(payload);
    dispatch(postFileSuccess(response));
  } catch (e) {
    dispatch(postFileFailure(e));
  }
};

export const clearPostingSummary = () => (dispatch) => {
  dispatch(clearPostingSummarySuccess());
};

export const exitWorkbook = () => ({
  type: CONST.VALIDATION_UI_EXIT_WORKBOOK,
});

export const exitWorkbookReset = () => ({
  type: CONST.VALIDATION_UI_EXIT_WORKBOOK_RESET,
});

export const saveWorkbookStart = () => ({
  type: CONST.VALIDATION_UI_SAVE_WORKBOOK_START,
});

export const saveWorkbookSuccess = payload => ({
  type: CONST.VALIDATION_UI_SAVE_WORKBOOK_SUCCESS,
  payload,
});

export const saveWorkbookFailure = (error = null) => ({
  type: CONST.VALIDATION_UI_SAVE_WORKBOOK_FAILURE,
  globalError: error,
});

export const saveWorkbook = userName => async (dispatch, getState) => {
  dispatch(saveWorkbookStart());

  try {
    const { dataGrid, dataGridChanges } = getState().validationUI;
    const updatedRowList = dataGrid.filter(row => dataGridChanges.indexOf(row.seq_id) > -1);
    await api.saveWorkbook({ updatedRowList, userName });

    dispatch(saveWorkbookSuccess(new Date()));
  } catch (error) {
    logger.logError('save workbook failure:\n', error);
    dispatch(saveWorkbookFailure(error));
  }
};

export const saveWorkbookReset = () => ({
  type: CONST.VALIDATION_UI_SAVE_WORKBOOK_RESET,
});


export const saveWorkbookNameStart = () => ({
  type: CONST.VALIDATION_UI_SAVE_WORKBOOK_NAME_START,
});

export const saveWorkbookNameSuccess = payload => ({
  type: CONST.VALIDATION_UI_SAVE_WORKBOOK_NAME_SUCCESS,
  payload,
});

export const saveWorkbookNameFailure = (error = null) => ({
  type: CONST.VALIDATION_UI_SAVE_WORKBOOK_NAME_FAILURE,
  globalError: error,
});

export const saveWorkbookName = payload => async (dispatch, getState) => {
  dispatch(saveWorkbookNameStart());

  try {
    await api.saveWorkbookName(payload);
    const { dataGrid } = getState().validationUI;
    dataGrid.map(row => ({ ...row, workbook_name: payload.workbookName }));
    dispatch(saveWorkbookNameSuccess(payload.workbookName));
    // dispatch(fetchDataSuccess());
  } catch (error) {
    logger.logError('save workbook name failure:\n', error);
    dispatch(saveWorkbookNameFailure(error));
  }
};

export const downloadDataGridStart = () => ({
  type: CONST.VALIDATION_UI_DOWNLOAD_START,
});

export const downloadDataGridSuccess = (data = {}) => ({
  type: CONST.VALIDATION_UI_DOWNLOAD_SUCCESS,
  payload: data,
});

export const downloadDataGridFailure = (error = null) => ({
  type: CONST.VALIDATION_UI_DOWNLOAD_FAILURE,
  globalError: error,
});

export const downloadDataGridReset = () => ({
  type: CONST.VALIDATION_UI_DOWNLOAD_RESET,
});

export const downloadDataGrid = (requestType, requestId) => async (dispatch) => {
  dispatch(downloadDataGridStart());
  try {
    const result = await api.downloadDataGrid(requestType, requestId);
    logger.logInfo(result);
    dispatch(downloadDataGridSuccess(result));
  } catch (e) {
    dispatch(downloadDataGridFailure(e));
  }
};


export const deleteRowStart = () => ({
  type: CONST.VALIDATION_UI_DELETE_ROW_START,
});

export const deleteRowSuccess = (data = {}) => ({
  type: CONST.VALIDATION_UI_DELETE_ROW_SUCCESS,
  payload: data,
});

export const deleteRowFailure = (error = null) => ({
  type: CONST.VALIDATION_UI_DELETE_ROW_FAILURE,
  globalError: error,
});


export const deleteRow = payload => async (dispatch, getState) => {
  // Removing a client side only row
  if (payload.seq_id == null) {
    const { dataGrid } = getState().validationUI;
    dataGrid.splice(payload.index, 1);
    for (let { index } = payload; index < dataGrid.length; index += 1) {
      const element = dataGrid[index];
      element.index -= 1;
    }
    return Promise.resolve().then(() => {
      dispatch(fetchDataSuccess());
    });
  }
  // Removing a server side row
  dispatch(deleteRowStart());
  try {
    const result = await api.deleteValidationUIRow(
      payload.workbook_id,
      payload.journal_header_id,
      payload.seq_id,
    );
    logger.logInfo(result);
    parseFetchValidationResponse(result, dispatch);
    dispatch(deleteRowSuccess(result));
  } catch (e) {
    dispatch(deleteRowFailure(e));
  }
};


export const addRowStart = () => ({
  type: CONST.VALIDATION_UI_ADD_ROW_START,
});

export const addRowSuccess = (data = {}) => ({
  type: CONST.VALIDATION_UI_ADD_ROW_SUCCESS,
  payload: data,
});

export const addRowFailure = (error = null) => ({
  type: CONST.VALIDATION_UI_ADD_ROW_FAILURE,
  globalError: error,
});


export const addRowToJournal = row => async (dispatch, getState) => {
  const createUser = getState().auth.username;

  dispatch(addRowStart());
  try {
    const result = await api.insertValidationUIRow(row.workbook_id, {
      journal_header_id: row.journal_header_id,
      ic_matching_id: row.ic_matching_id,
      workbook_id: row.workbook_id,
      reversed: false,
      processing_status: row.processing_status,
      ic_transaction_group: row.ic_transaction_group,
      journal_batch_id: row.journal_batch_id,
      journal_name: row.journal_name,
      journal_desc: row.journal_desc,
      journal_batch_name: row.journal_batch_name,
      prepare_user: createUser,
    });
    parseFetchValidationResponse(result, dispatch);
    dispatch(addRowSuccess(result));
  } catch (e) {
    dispatch(addRowFailure(e));
  }

  return Promise.resolve().then(() => {
    dispatch(fetchDataSuccess());
  });
};

export const addJournalToBatch = row => async (dispatch, getState) => {
  const createUser = getState().auth.username;

  dispatch(addRowStart());
  try {
    const result = await api.insertValidationUIRow(row.workbook_id, {
      workbook_id: row.workbook_id,
      reversed: false,
      processing_status: row.processing_status,
      journal_batch_id: row.journal_batch_id,
      journal_batch_name: row.journal_batch_name,
      prepare_user: createUser,
    });
    parseFetchValidationResponse(result, dispatch);
    dispatch(addRowSuccess(result));
  } catch (e) {
    dispatch(addRowFailure(e));
  }

  return Promise.resolve().then(() => {
    dispatch(fetchDataSuccess());
  });
};

export const copyLineToJournal = row => async (dispatch, getState) => {
  const createUser = getState().auth.username;

  dispatch(addRowStart());
  try {
    const result = await api.insertValidationUIRow(
      row.workbook_id,
      { ...row, prepare_user: createUser },
    );
    parseFetchValidationResponse(result, dispatch);
    dispatch(addRowSuccess(result));
  } catch (e) {
    dispatch(addRowFailure(e));
  }

  return Promise.resolve().then(() => {
    dispatch(fetchDataSuccess());
  });
};

export const addRow = (row, type) => async (dispatch) => {
  switch (type) {
    case 'ROW':
      dispatch(addRowToJournal(row));
      break;
    case 'JOURNAL':
      dispatch(addJournalToBatch(row));
      break;
    case 'COPY_TO_JOURNAL':
      dispatch(copyLineToJournal(row));
      break;
    default:
      break;
  }
};

export const generateTaxLinesStart = () => ({
  type: CONST.VALIDATION_UI_GENERATE_TAX_LINES_START,
});

export const generateTaxLinesSuccess = (data = {}) => ({
  type: CONST.VALIDATION_UI_GENERATE_TAX_LINES_SUCCESS,
  payload: data,
});

export const generateTaxLinesFailure = (error = null) => ({
  type: CONST.VALIDATION_UI_GENERATE_TAX_LINES_FAILURE,
  globalError: error,
});

export const generateTaxLines = workbook_id => async (dispatch) => {
  dispatch(generateTaxLinesStart());
  try {
    const runWorkflowResult = await api.runIPEWorkflow(workbook_id);

    if (runWorkflowResult.status === CONSTANT.WORKFLOW_STATUS.FAILED) {
      dispatch(fetchDataFailure(CONSTANT.WORKFLOW_FAILURE_ERROR_MESSAGE));
      return;
    }
    if (runWorkflowResult.status === CONSTANT.WORKFLOW_STATUS.TIMEOUT) {
      dispatch(fetchDataFailure(CONSTANT.WORKFLOW_TIMEOUT_ERROR_MESSAGE));
      return;
    }

    const result = await api.getIndirectTaxSummary(workbook_id);

    // Show indirect tax summary
    dispatch(generateTaxLinesSuccess(result));
  } catch (e) {
    dispatch(generateTaxLinesFailure(e));
  }
};

export const deleteWorkbookStart = () => ({
  type: CONST.VALIDATION_UI_DELETE_WORKBOOK_START,
});

export const deleteWorkbookSuccess = (payload = null) => ({
  type: CONST.VALIDATION_UI_DELETE_WORKBOOK_SUCCESS,
  payload,
});

export const deleteWorkbookFailure = (error = null) => ({
  type: CONST.VALIDATION_UI_DELETE_WORKBOOK_FAILURE,
  globalError: error,
});

export const deleteWorkbookReset = () => ({
  type: CONST.VALIDATION_UI_DELETE_WORKBOOK_RESET,
});

export const deleteWorkbook = payload => async (dispatch) => {
  dispatch(deleteWorkbookStart());
  try {
    const result = await api.deleteWorkbook(payload);
    dispatch(deleteWorkbookSuccess(result));
  } catch (e) {
    dispatch(deleteWorkbookFailure(e));
  }
};
