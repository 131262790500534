import { createStore, applyMiddleware /* , compose */ } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers';

export default function configureStore(initialState) {
  const middleware = applyMiddleware(thunk, logger);
  const store = createStore(rootReducer, initialState, middleware);
  return store;
}
