import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import '@amzn/awsui-components-react/index.css';
import { Form, FormSection, ColumnLayout } from '@amzn/awsui-components-react';
import getModalMessage from 'common/components/feedback/modal';
import CustomProps from 'common/prop-types';
import CONSTANT from 'utils/constant';
import Logger from 'utils/logger';
import APIClient from 'utils/apiClient';
import MetaBasicInfoBlock from './MetaBasicInfoBlock';
import MetaDataTypeTiles from './MetaDataTypeTiles';
import ValidValuesBlock from './ValidValuesBlock';
import ActionStrip from './ActionStrip';
import IntegerRangeBlock from './IntegerRangeBlock';
import DecimalRangeBlock from './DecimalRangeBlock';

/*
 * Parent element that wrap all the above component
 */
class CreateMetaData extends React.Component {
  static propTypes = {
    history: CustomProps.history({
      templateName: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    const templateName = props.history.location.state
      ? props.history.location.state.templateName
      : '';

    this.state = {
      data: {
        dataType: 'Integer',
        required: 'true',
        isKey: 'false',
        isHeader: 'false',
        validValues: [],
        templateName,
      },
      err: {}, // whether this entry is prompt with an error message
      status: CONSTANT.MODAL_EMPTY,
      valueToAdd: '',
    };

    this.removeKeyFromData = this.removeKeyFromData.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.setErrorFlag = this.setErrorFlag.bind(this);
    this.recoverEmptyState = this.recoverEmptyState.bind(this);
    this.changeModalStatus = this.changeModalStatus.bind(this);
    this.handleAddConfirm = this.handleAddConfirm.bind(this);
    this.handleDeleteValueClick = this.handleDeleteValueClick.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.readyForSubmit = this.readyForSubmit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  setErrorFlag(name, errorText) {
    const { err } = this.state;
    const oldErr = Object.assign({}, err);
    if (errorText === '') delete oldErr[name];
    else oldErr[name] = true;
    this.setState({ err: oldErr });
  }

  handleSubmit() {
    const { data } = this.state;

    Logger.logInfo(data);
    if (!this.readyForSubmit()) {
      this.setState({ status: CONSTANT.MODAL_INPUT_ERROR });
      return;
    }

    if (data.dataType !== 'Enumerated') {
      this.removeKeyFromData('validValues');
      this.removeKeyFromData('sourceSystem');
      this.removeKeyFromData('listName');
    }

    if (data.dataType === 'Enumerated' && data.validValues.length !== 0) {
      this.removeKeyFromData('sourceSystem');
      this.removeKeyFromData('listName');
    }

    if (data.dataType !== 'Integer' && data.dataType !== 'Decimal') {
      this.removeKeyFromData('max');
      this.removeKeyFromData('min');
    }
    this.setState({ status: CONSTANT.MODAL_SUBMITTING });
    APIClient.invokeBusinessLogic('ddb', 'CreateMetaData', {}, data, (err, d) => {
      if (!err) {
        switch (d.status) {
          case CONSTANT.RESPONSE_SUCCESS:
            this.setState({ status: CONSTANT.MODAL_CREATE_SUCCESS });
            break;
          case CONSTANT.RESPONSE_DUPLICATE:
            this.setState({ status: CONSTANT.MODAL_CREATE_DUPLICATE });
            break;
          case CONSTANT.RESPONSE_ERROR:
            this.setState({ status: CONSTANT.MODAL_CREATE_ERROR });
            break;
          case CONSTANT.RESPONSE_REQUEST_ERROR:
            this.setState({ status: CONSTANT.MODAL_REQUEST_ERROR });
            break;
          default:
        }
      } else {
        Logger.logError(`Backend crashed when createMetaDta ${data}`);
        this.setState({ status: CONSTANT.MODAL_CREATE_ERROR });
      }
    });
  }

  // helper function
  removeKeyFromData(key) {
    const { data } = this.state;
    const oldData = Object.assign({}, data);
    delete oldData[key];
    this.setState({ data: oldData });
  }

  handleValueChange(name, value) {
    const { data } = this.state;
    const oldData = Object.assign({}, data, { [name]: value });
    this.setState({ data: oldData });
  }

  /* used only for cancel add a value modal */
  recoverEmptyState() {
    this.setState({
      status: CONSTANT.MODAL_EMPTY,
      valueToAdd: '',
    });
  }

  /* used only for cancel add a value modal */
  changeModalStatus(status) {
    this.setState({ status });
  }

  handleAddConfirm() {
    const { data, valueToAdd } = this.state;

    if (!data.validValues.includes(valueToAdd)) {
      this.setState({
        status: CONSTANT.MODAL_EMPTY,
        valueToAdd: '',
        data: {
          ...data,
          validValues: [...data.validValues, valueToAdd],
        },
      });
    } else {
      this.setState({
        valueToAdd: '',
        status: CONSTANT.MODAL_META_ADD_DUPLICATE_VALUE,
      });
    }
  }

  handleDeleteValueClick(indexToRemove) {
    const { data } = this.state;
    if (indexToRemove.length !== 0) {
      indexToRemove.sort();
      indexToRemove.reverse();
      const copiedValueList = data.validValues.slice();
      // for (const index of indexToRemove) {
      //   // index starts from 1
      //   copiedValueList.splice(index - 1, 1);
      // }

      const resultList = copiedValueList.reduce((r, i) => r.splice(i - 1, 1));
      this.handleValueChange('validValues', resultList);
    }
  }

  handleClear() {
    // set state back to original values
    this.setState({
      data: { dataType: 'Integer', required: 'true', validValues: [] },
      valueToAdd: '',
      status: CONSTANT.MODAL_EMPTY,
    });
  }

  readyForSubmit() {
    const { data, err } = this.state;
    let checkSuccess = true;

    if (
      data.dataType === 'Enumerated'
      && (data.validValues.length === 0
        && (data.sourceSystem === undefined
          || data.sourceSystem === ''
          || data.listName === undefined
          || data.listName === ''))
    ) {
      checkSuccess = false;
    } else if (Object.keys(err).length > 0) {
      checkSuccess = false;
    }
    return checkSuccess;
  }

  render() {
    const { data, status } = this.state;

    let additionContent;
    if (data.dataType === 'Integer') {
      additionContent = (
        <IntegerRangeBlock
          value={data}
          handleChange={this.handleValueChange}
          setErrorFlag={this.setErrorFlag}
        />
      );
    } else if (data.dataType === 'Decimal') {
      additionContent = (
        <DecimalRangeBlock
          value={data}
          handleChange={this.handleValueChange}
          setErrorFlag={this.setErrorFlag}
        />
      );
    } else if (data.dataType === 'Enumerated') {
      additionContent = (
        <div>
          <ValidValuesBlock
            value={data}
            validValues={data.validValues}
            handleValueChange={this.handleValueChange}
            setErrorFlag={this.setErrorFlag}
            changeModalStatus={this.changeModalStatus}
            handleDeleteValueClick={this.handleDeleteValueClick}
          />
        </div>
      );
    }

    return (
      <div>
        <Form>
          <FormSection className="custom-screenshot-hide" header="Define Meta information for MJE">
            <MetaBasicInfoBlock
              value={data}
              handleValueChange={this.handleValueChange}
              setErrorFlag={this.setErrorFlag}
            />
            <MetaDataTypeTiles
              name="dataType"
              value={data.dataType}
              handleTileClick={this.handleValueChange}
            />
            <ColumnLayout columns={1}>
              <div data-awsui-column-layout-root>{additionContent}</div>
            </ColumnLayout>
            <ActionStrip handleClear={this.handleClear} handleSubmit={this.handleSubmit} />
            {getModalMessage(status, this)}
          </FormSection>
        </Form>
      </div>
    );
  }
}

export default withRouter(CreateMetaData);
