const CONSTANT = Object.freeze({
  // backend response related
  RESPONSE_SUCCESS: 'SUCCESS',
  RESPONSE_DUPLICATE: 'DUPLICATE',
  RESPONSE_ERROR: 'ERROR',
  RESPONSE_KEY_NOT_EXIST: 'KEY_NOT_EXIST',
  RESPONSE_REQUEST_ERROR: 'REQUEST_ERROR',

  // data type for mje data
  DATA_TYPE_FREETEXT: 'FreeText',
  DATA_TYPE_ENUMERATED: 'Enumerated',
  DATA_TYPE_BOOLEAN: 'Boolean',
  DATA_TYPE_DATE: 'Date',
  DATA_TYPE_DECIMAL: 'Decimal',
  DATA_TYPE_INTEGER: 'Integer',

  // General modal state
  MODAL_EMPTY: 'MODAL_EMPTY',
  MODAL_REQUIRED_EMPTY: 'MODAL_REQUIRED_EMPTY',
  MODAL_SUBMITTING: 'MODAL_SUBMITTING',
  MODAL_INPUT_ERROR: 'MODAL_INPUT_ERROR',
  MODAL_QUERYING: 'MODAL_QUERYING',

  // general create request related modal state
  MODAL_CREATE_SUCCESS: 'MODAL_CREATE_SUCCESS',
  MODAL_CREATE_DUPLICATE: 'MODAL_CREATE_DUPLICATE',
  MODAL_CREATE_ERROR: 'MODAL_CREATE_ERROR',

  // general query request related modal state
  MODAL_QUERY_SUCCESS: 'MODAL_QUERY_SUCCESS',
  MODAL_QUERY_ERROR: 'MODAL_QUERY_ERROR',

  // general delete request related modal state
  MODAL_DELETE_SUCCESS: 'MODAL_DELETE_SUCCESS',
  MODAL_DELETE_ERROR: 'MODAL_DELETE_ERROR',

  // general update request related modal state
  MODAL_UPDATE_SUCCESS: 'MODAL_UPDATE_SUCCESS',
  MODAL_UPDATE_ERROR: 'MODAL_UPDATE_ERROR',

  // Modal state unique to 'create meta data' page
  MODAL_META_ADD_BUTTON_CLICK: 'MODAL_META_ADD_BUTTON_CLICK',
  MODAL_META_ADD_DUPLICATE_VALUE: 'MODAL_META_ADD_DUPLICATE_VALUE',

  // modal state unique to 'query meta data page'
  MODAL_META_QUERY_KEY_NOT_EXIST: 'MODAL_META_QUERY_KEY_NOT_EXIST',

  // Modal state unique to MJE file upload
  MODAL_FETCHING_META_DATA: 'MODAL_FETCHING_META_DATA',
  MODAL_TEMPLATE_NOT_EXIST: 'MODAL_TEMPLATE_NOT_EXIST',
  MODAL_VALIDATING: 'MODAL_VALIDATING',
  MODAL_VALIDATE_ERROR: 'MODAL_VALIDATE_ERROR',
  MODAL_VALIDATE_SUCCESS: 'MODAL_VALIDATE_SUCCESS',
  MODAL_UPLOADING: 'MODAL_UPLOADING',
  MODAL_UPLOAD_SUCCESS: 'MODAL_UPLOAD_SUCCESS',
  MODAL_UPLOAD_ERROR: 'MODAL_UPLOAD_ERROR',
  MODAL_FILE_FORMAT_ERROR: 'MODAL_FILE_FORMAT_ERROR',

  // Modal state unique to query MJE data
  MODAL_FETCHING_KEY: 'MODAL_FETCHING_KEY',
  MODAL_KEY_COLUMN_NOT_EXIST: 'MODAL_KEY_COLUMN_NOT_EXIST',
  MODAL_MJE_NOT_EXIST: 'MODAL_MJE_NOT_EXIST',

  // debugging purposes
  MODAL_REQUEST_ERROR: 'MODAL_REQUEST_ERROR',

  // upload status
  UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
  UPLOAD_ERROR: 'UPLOAD_ERROR',

  // state for step component for ant design library
  STEP_WAIT: 'wait',
  STEP_PROCESS: 'process',
  STEP_FINISH: 'finish',
  STEP_ERROR: 'error',

  GENERIC_ERROR_MESSAGE: 'There was a problem with your request. Please try again later or contact Technical support.',
  WORKFLOW_FAILURE_ERROR_MESSAGE: 'Encountered system error while processing the entry. Please try again later or contact Technical Support.',
  WORKFLOW_TIMEOUT_ERROR_MESSAGE: 'System timed out while processing the entry. Please try again later or contact Technical Support.',
  MAX_FILE_SIZE_EXCEEDED_MESSAGE: 'Your upload is larger than 5 Mb. Please reduce the size of the workbook and remove non-ICMJE tabs.',
  UNSUPPORTED_FILE_TYPE_MESSAGE: 'You have attempted to upload an unsupported file type. Accepted types are: ',
  MAX_FILE_SIZE_ALLOWED: 5242880,

  FILE_REQUEST_TYPES: {
    JOURNAL: 'journal',
    JOURNALS: 'journals',
    BATCH: 'batch',
    WORKBOOK: 'workbook',
  },

  JOURNAL_STATUS: {
    SYSTEM_SAVED: 'System Saved',
    USER_SAVED: 'User Saved',
    FUTURE_REVERSAL: 'Future Reversal',
    GL_POSTED: 'GL Posted',
    NO_APPROVER: 'No Approver',
    GL_PROCESSING: 'GL Processing',
    GL_FAILED: 'GL Failed',
  },

  WORKFLOW_STATUS: {
    PENDING: 'Pending',
    STARTED: 'Started',
    FINISHED: 'Finished',
    VALIDATION_FINISHED: 'Validation Finished',
    FAILED: 'Failed',
    TIMEOUT: 'Timeout',
  },

  ACCEPTED_FILE_TYPES: [".xlsx", ".xlsm"],

  COGNITO_ROLE_ATTRIBUTE: 'custom:Role',
  USER_ROLE: {
    ADMIN: 'admin',
    AUTHORIZED_USER: 'authorizedUser',
    FINANCE_USER: 'financeUser',
  },
});

export default CONSTANT;
