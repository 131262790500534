import * as CONST from 'common/const';
import api from 'utils/api';
import logger from 'utils/logger';


export const updatePage = (data = {}) => ({
  type: CONST.PRIME_JOURNAL_SUMMARY_UPDATE_PAGE,
  data,
});

export const fetchInitialDataStart = () => ({
  type: CONST.PRIME_JOURNAL_SUMMARY_FETCH_DATA_START,
});

export const fetchInitialDataSuccess = () => ({
  type: CONST.PRIME_JOURNAL_SUMMARY_FETCH_DATA_SUCCESS,
});

export const fetchInitialDataFailure = (error = null) => ({
  type: CONST.PRIME_JOURNAL_SUMMARY_FETCH_DATA_FAILURE,
  globalError: error,
});

const dataGridHelper = (rawDataGrid) => {
  const dataGrid = [];

  (rawDataGrid || []).forEach((row, index) => {
    dataGrid.push({
      ...row,
      index,
    });
  });

  const calculateSum = (arr, key) => arr
    .map(item => Number(item[key]) || 0.0)
    .reduce((n, i) => n + i, 0.0);

  const sumEntDr = calculateSum(dataGrid, 'entered_dr');
  const sumEntCr = calculateSum(dataGrid, 'entered_cr');
  const sumAccDr = calculateSum(dataGrid, 'accounted_dr');
  const sumAccCr = calculateSum(dataGrid, 'accounted_cr');
  dataGrid.push({
    isFooterRow: true,
    index: 'Total',
    entered_dr: sumEntDr,
    entered_cr: sumEntCr,
    accounted_dr: sumAccDr,
    accounted_cr: sumAccCr,
  });

  return dataGrid;
};

export const fetchInitialData = requestId => async (dispatch) => {
  dispatch(fetchInitialDataStart());

  try {
    const response = await api.fetchJournal(requestId);

    const header = response.header || {};

    const conversion = response.conversion || {};

    const dataGridColumns = [...response.linesColumns];

    dataGridColumns.unshift({
      description: 'Index',
      header: 'Line',
      name: 'index',
      seqNum: 0,
      type: 'system',
    });

    const dataGrid = dataGridHelper(response.lines);

    const data = {
      header,
      conversion,
      dataGridColumns,
      dataGrid,
      requestId,
    };

    dispatch(updatePage(data));
    dispatch(fetchInitialDataSuccess());
  } catch (error) {
    logger.logError('fetchJournal failure:\n', error.stack);
    dispatch(
      fetchInitialDataFailure(error),
    );
  }
};

export const downloadJournalStart = () => ({
  type: CONST.JOURNAL_SUMMARY_DOWNLOAD_START,
});

export const downloadJournalSuccess = (data = { }) => ({
  type: CONST.JOURNAL_SUMMARY_DOWNLOAD_SUCCESS,
  payload: data,
});

export const downloadJournalFailure = (error = null) => ({
  type: CONST.JOURNAL_SUMMARY_DOWNLOAD_FAILURE,
  globalError: error,
});

export const downloadJournalReset = () => ({
  type: CONST.JOURNAL_SUMMARY_DOWNLOAD_RESET,
});

export const downloadJournal = (requestId) => async (dispatch) => {
  dispatch(downloadJournalStart());
  try {
    const result = await api.downloadJournalSummary(requestId);
    logger.logInfo(result);
    dispatch(downloadJournalSuccess(result));
  } catch (e) {
    dispatch(downloadJournalFailure(e));
  }
};
