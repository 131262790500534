import React from 'react';
import PropTypes from 'prop-types';
import '@amzn/awsui-components-react/index.css';
import {
  Form, FormSection, ColumnLayout, Button,
} from '@amzn/awsui-components-react';
import { DropDownElement, FreeTextElement } from 'common/components/dataEntry';

class SearchBar extends React.Component {
  static propTypes = {
    handleFetchKey: PropTypes.func.isRequired,
    handleQueryClick: PropTypes.func.isRequired,

    mjeKeys: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    mjeKeys: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      templateName: 'ICE_MJE',
      data: {},
      // name: '',
      // err: {},
    };

    this.handleValueChange = this.handleValueChange.bind(this);
  }

  handleValueChange(name, value) {
    const { data } = this.state;
    const newData = Object.assign({}, data, { [name]: value });
    this.setState({ data: newData });
  }

  render() {
    const { mjeKeys, handleFetchKey, handleQueryClick } = this.props;
    const { templateName, data } = this.state;

    // TODO: fetching existing template from database in future
    const templateOptions = [{ label: 'ICE_MJE', id: 'ICE_MJE' }];
    return (
      <Form>
        <FormSection className="custom-screenshot-hide" header="Query MJE">
          <h4>Query by Journal Name</h4>
          <ColumnLayout columns={4}>
            <div data-awsui-column-layout-root>
              <DropDownElement
                title="MJE Template Type"
                name="template_Name"
                value={templateName}
                options={templateOptions}
                disabled={false}
                required
                handleChange={(name, value) => value /* this.setState({ name: value }) */}
                setErrorFlag={(name, errorText) => errorText}
              />

              {mjeKeys.map(keyColumn => (
                <FreeTextElement
                  key={keyColumn}
                  title={keyColumn}
                  name={keyColumn}
                  value={data.keyColumn}
                  disabled={false}
                  required={false}
                  description=""
                  handleChange={this.handleValueChange}
                  setErrorFlag={(name, errorText) => errorText}
                />
              ))}
            </div>
          </ColumnLayout>

          <br />

          <h4>
            Query by
            {templateName}
            {' '}
id
          </h4>
          <ColumnLayout columns={4}>
            <div data-awsui-column-layout-root>
              <DropDownElement
                title="MJE Template Type"
                name="template_Name"
                value={templateName}
                options={templateOptions}
                disabled={false}
                required={false}
                handleChange={(name, value) => value /* this.setState({ name: value }) */}
                setErrorFlag={(name, errorText) => errorText}
              />

              <FreeTextElement
                title="MJE id"
                name="id"
                value={data.id}
                disabled={false}
                required
                description=""
                handleChange={this.handleValueChange}
                setErrorFlag={(name, errorText) => errorText}
              />
            </div>
          </ColumnLayout>

          <br />
          <br />
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title" />
            <div className="awsui-util-action-stripe-group">
              <Button
                text="Fetch key"
                variant="normal"
                icon="search"
                className="awsui-util-mb-xl"
                type="submit"
                onClick={() => handleFetchKey(templateName)}
              />

              <Button
                text="Search"
                variant="primary"
                icon="search"
                disabled={mjeKeys.length === 0 && (data.id === undefined || data.id === '')}
                className="awsui-util-mb-xl"
                type="submit"
                onClick={() => handleQueryClick(templateName, data)}
              />
            </div>
          </div>
        </FormSection>
      </Form>
    );
  }
}

export default SearchBar;
