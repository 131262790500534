// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pages-Reports-Reports__wrapper--2bB7u .pages-Reports-Reports__divider--37aoo{background:#d5dbdb;display:block;clear:both;width:100%;min-width:100%;height:.06em}.pages-Reports-Reports__wrapper--2bB7u .pages-Reports-Reports__centerAlignContent--3zFTQ{text-align:center}.pages-Reports-Reports__wrapper--2bB7u .pages-Reports-Reports__leftAlignContent--1b45E{text-align:left}.pages-Reports-Reports__wrapper--2bB7u .pages-Reports-Reports__runActionWrapper--3bC1h{align-items:center}.pages-Reports-Reports__wrapper--2bB7u .pages-Reports-Reports__runActionWrapper--3bC1h .awsui-select-keyboard-area{width:14em}.pages-Reports-Reports__wrapper--2bB7u .pages-Reports-Reports__filterInput--2QOz2::placeholder{font-style:italic}.pages-Reports-Reports__wrapper--2bB7u .ReactTable .rt-tbody .rt-td:last-child{overflow:visible !important}.pages-Reports-Reports__wrapper--2bB7u .ReactTable .rt-tbody{min-height:4em !important}.pages-Reports-Reports__wrapper--2bB7u .ReactTable .rt-noData{margin-top:1em;background:rgba(255,255,255,0);color:#555}.pages-Reports-Reports__wrapper--2bB7u .ReactTable .rt-th{text-align:left}.pages-Reports-Reports__wrapper--2bB7u .button-active .awsui-button-icon{color:var(--awsui-color-background-button-primary-default)}", ""]);
// Exports
exports.locals = {
	"wrapper": "pages-Reports-Reports__wrapper--2bB7u",
	"divider": "pages-Reports-Reports__divider--37aoo",
	"centerAlignContent": "pages-Reports-Reports__centerAlignContent--3zFTQ",
	"leftAlignContent": "pages-Reports-Reports__leftAlignContent--1b45E",
	"runActionWrapper": "pages-Reports-Reports__runActionWrapper--3bC1h",
	"filterInput": "pages-Reports-Reports__filterInput--2QOz2"
};
module.exports = exports;
