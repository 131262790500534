import React from 'react';
import PropTypes from 'prop-types';
import { FormField, RadioGroup } from '@amzn/awsui-components-react';
import ErrorBoundary from 'common/components/err/errorBoundary';
import '@amzn/awsui-components-react/index.css';
import 'antd/dist/antd.css';
import 'index.css';

// Component for binary selection input
class RadioGroupElement extends React.Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    setErrorFlag: PropTypes.func.isRequired,

    items: PropTypes.arrayOf(PropTypes.shape({})),

    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.string,

    required: PropTypes.bool,
  };

  static defaultProps = {
    items: [],

    name: '',
    title: '',
    description: '',
    value: '',

    required: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      errorText: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { errorText } = this.state;
    const {
      required, handleChange, setErrorFlag, name,
    } = this.props;
    const selectedValue = event.target.component.value;

    if (selectedValue === '' && required) this.setState({ errorText: 'This field is required' });
    else this.setState({ errorText: '' });
    handleChange(name, selectedValue);
    setErrorFlag(name, errorText);
  }

  render() {
    const { errorText } = this.state;
    const {
      title, required, description, value, items,
    } = this.props;

    return (
      <ErrorBoundary content={title}>
        <FormField
          label={title + (required ? '*' : '')}
          description={description}
          errorText={errorText}
        >
          <RadioGroup value={value} items={items} onChange={this.handleChange} />
        </FormField>
      </ErrorBoundary>
    );
  }
}

export default RadioGroupElement;
