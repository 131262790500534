import React from 'react';
import PropTypes from 'prop-types';
import '@amzn/awsui-components-react/index.css';
import { Button } from '@amzn/awsui-components-react';

function ActionStrip({
  downloadDisabled,
  handleUploadClick,
  handleTryOtherFileClick,
  handleDownloadErrorClick,
}) {
  return (
    <div className="awsui-util-action-stripe">
      <div className="awsui-util-action-stripe-title" />
      <div className="awsui-util-action-stripe-group">
        <Button
          text="Download Errors"
          disabled={!downloadDisabled}
          onClick={handleDownloadErrorClick}
        />
        <Button
          text="Validate another file"
          type="submit"
          variant="primary"
          onClick={handleTryOtherFileClick}
        />
        <Button
          text="Upload"
          type="submit"
          variant="primary"
          disabled={downloadDisabled}
          onClick={handleUploadClick}
        />
      </div>
    </div>
  );
}

ActionStrip.propTypes = {
  downloadDisabled: PropTypes.bool,
  handleDownloadErrorClick: PropTypes.func.isRequired,
  handleTryOtherFileClick: PropTypes.func.isRequired,
  handleUploadClick: PropTypes.func.isRequired,
};

ActionStrip.defaultProps = {
  downloadDisabled: true,
};

export default ActionStrip;
