/* eslint-disable no-console */
import { CognitoAuth } from 'amazon-cognito-auth-js';
import CONSTANT from '../constant';

const authConfig = {
  AppWebDomain: process.env.USER_POOL_SUB_DOMAIN,
  ClientId: process.env.USER_POOL_APP_CLIENT_ID,
  UserPoolId: process.env.USER_POOL_ID,
  RedirectUriSignIn: process.env.REDIRECT_URI_SIGNIN,
  RedirectUriSignOut: process.env.REDIRECT_URI_SIGNOUT,
  TokenScopesArray: [
    'openid',
    'email',
    'profile',
  ],
};

// This code removes the "?code=..." from the URL. It is because the grant code is not reusable.
//  Sometimes the SDK will report weird message because of using old grant code.
function removeQueryFromLocation() {
  // Replace the href because the Cognito passes the OAuth2 grant code in the query string
  // And the grant code is not reusable
  if (window.history.length > 0) {
    const newHref = window.location.href.split('?')[0];
    window.history.replaceState(undefined, 'ICE', newHref);
  }
}

export function getUsernameFromCognitoSession(session) {
  try {
    const { identities } = session.getIdToken().decodePayload();
    if (identities.length < 1) {
      return null; // No valid identities; return null.
    }
    return identities[0].userId;
  } catch (e) {
    return null; // Received Invalid object; return null.
  }
}

export function getJwtTokenFromCognitoSession(session) {
  return session.getIdToken().getJwtToken();
}

/**
 * Ensures there is a valid Cognito Auth session and returns the Cognito Auth object.
 *
 * A valid Cognito Auth session means the browser has unexpired Cognito access token
 * and identity token. The identity token will be included in HTTP requests under the
 * "Authorization" header when calling backend APIs.
 *
 * Depending on the state of the Cognito tokens in the browser's local storage, one of the
 * following will happen (although the code for them is mostly handled by Cognito library):
 * 1. If there are unexpired access and identity tokens in the local storage, there is
 *    already a valid session, and nothing need to be done.
 * 2. If the access and identity tokens in the local storage has expired, but the refresh
 *    token, which is usually long-lived, hasn't expired, the refresh token will be used
 *    to fetch new access and identity tokens from Cognito server.
 * 3. If the refresh token also expired, or there are no Cognito tokens in the local
 *    storage, the whole sign-in chain will be triggered (Cognito -> Federate -> Midway
 *    and then back). Since we are using the code grant flow here, Cognito will first
 *    return a grant code, instead of the tokens directly, to the application. The app
 *    then uses the grant code to exchange for new access, identity and refresh tokens.
 */
export function ensureAuthenticated(appStart = false) {
  return new Promise((resolve, reject) => {
    // Construct a new Cognito Auth object and configure to use code grant flow
    const auth = new CognitoAuth(authConfig);
    auth.useCodeGrantFlow();

    // Register callback functions for calls to Cognito server
    auth.userhandler = {
      onFailure: (err) => {
        console.log('Received error from Cognito: ', err);
        removeQueryFromLocation();

        // Cached localStorage contained invalid/expired refresh token;
        // Clear cached Cognito data and try again.
        if (err === '{"error":"invalid_grant"}') {
          localStorage.clear();
          ensureAuthenticated()
            .then(result => resolve(result))
            .catch(err2 => reject(err2));
        } else {
          reject(err);
        }
      },
      onSuccess: (result) => {
        console.log('Response from Cognito:', result);
        removeQueryFromLocation();
        resolve(auth);
      },
    };

    const { href } = window.location;
    // Get the cached session, if any
    const session = auth.getSignInUserSession();
    const idTokenPayload = session.getIdToken() ? session.getIdToken().decodePayload() : null;

    // Actual auth logic starts here //

    // During app startup, if there is no "custom:Role" in the cached identity token,
    // or if "custom:Role" is financeUser, we clear the cache and start over, no matter
    // the cached token is valid or not. We do this because:
    // 1. If custom:Role is not in the token, the token was issued before custom:Role is introduced.
    // 2. If custom:Role has value of financeUser, the user might have been granted access
    //    since the token was issued, and custom:Role might have changed.
    // In either case, we need the whole auth chain to be triggered, so the Cognito user info can be
    // updated by new Federate claims.
    if (appStart && idTokenPayload && Object.keys(idTokenPayload).length > 0
        && (!idTokenPayload[CONSTANT.COGNITO_ROLE_ATTRIBUTE]
            || idTokenPayload[CONSTANT.COGNITO_ROLE_ATTRIBUTE]
            === CONSTANT.USER_ROLE.FINANCE_USER)) {
      localStorage.clear();
      ensureAuthenticated()
        .then(result => resolve(result))
        .catch(err => reject(err));
    } else if (session.isValid()) {
      // Otherwise, if cached tokens are valid, return the auth object directly
      resolve(auth);
    } else if (href.indexOf('?') > 0) {
      // This case handles Cognito Authentication Redirect.
      // When Cognito redirects back to MJE app, it puts the grant code as query parameter
      // in the URL (e.g. https://mje-url?code=abcdefg...). We then need to use this grant
      // code to exchange for tokens from Cognito.
      // Luckily Cognito library handles the parsing. The parsing is done asynchronously,
      // and the result will be passed to the userHandler. Once the result is parsed,
      // onSuccess userhandler will resolve the promise.
      auth.parseCognitoWebResponse(href);
    } else {
      // If the cached access/identity tokens have expired but the refresh token has not,
      // the refresh token will be used to fetch new access/identity tokens.
      // If the refresh token also expired, or there are no Cognito tokens in the local
      // storage, the whole sign-in chain will be triggered.
      // Cognito library handles all that in getSession().
      console.log('No valid cached session');
      auth.getSession();
    }
  });
}

export default ensureAuthenticated;
