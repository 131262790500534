import React from 'react';
import PropTypes from 'prop-types';
import '@amzn/awsui-components-react/index.css';
import { TableView } from 'common/components/dataDisplay';

function ErrorTable({ errorTitle, columns, items }) {
  const header = <div>{errorTitle}</div>;
  const columnDefinitions = [];

  for (let index = 0; index < columns.length; index += 1) {
    const columnDefinitionsItem = {};
    const column = columns[index];
    columnDefinitionsItem.id = column;
    columnDefinitionsItem.header = column;
    columnDefinitionsItem.cell = item => item[column];
    columnDefinitionsItem.width = 150;
    columnDefinitionsItem.minWidth = '150px';
    columnDefinitions.push(columnDefinitionsItem);
  }

  const sortableColumns = [];
  for (let index = 0; index < columns.length; index += 1) {
    const sortableColumnsItem = {};
    const column = columns[index];
    sortableColumnsItem.id = column;
    sortableColumnsItem.field = column;
    sortableColumns.push(sortableColumnsItem);
  }

  const visibleColumnOption = [];
  const firstOption = {};
  firstOption.label = 'Properties';
  const options = [];
  for (let index = 0; index < columns.length; index += 1) {
    const visibleColumnOptionItem = {};
    const column = columns[index];
    visibleColumnOptionItem.id = column;
    visibleColumnOptionItem.label = column;
    visibleColumnOptionItem.editable = column !== 'column_name';
    visibleColumnOptionItem.visible = true;
    options.push(visibleColumnOptionItem);
  }
  firstOption.options = options;
  visibleColumnOption.push(firstOption);

  const pageSizeOption = [
    { value: 10, label: '10 items' },
    { value: 20, label: '20 items' },
    { value: 50, label: '50 items' },
  ];

  return (
    <TableView
      tableTitle={errorTitle}
      columnDefinitions={columnDefinitions}
      items={items}
      header={header}
      trackBy="id"
      searchMessage="Filter"
      pageSize={10}
      loading={false}
      sortableColumns={sortableColumns}
      pageSizeOption={pageSizeOption}
      visibleColumnOption={visibleColumnOption}
    />
  );
}

ErrorTable.propTypes = {
  errorTitle: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

ErrorTable.defaultProps = {
  errorTitle: '',
  columns: [],
  items: [],
};

export default ErrorTable;
