import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import '@amzn/awsui-components-react/index.css';
import {
  Button, FormSection, Spinner, Icon,
} from '@amzn/awsui-components-react';
import CONSTANT from 'utils/constant';

class StepFive extends React.Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
    handleNextClick: PropTypes.func.isRequired,
    handleUploadRetry: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.getBackgroundContent = this.getBackgroundContent.bind(this);
  }

  getBackgroundContent() {
    const { status, handleNextClick, handleUploadRetry } = this.props;
    switch (status) {
      case CONSTANT.MODAL_UPLOADING:
        return (
          <div className="awsui-util-t-c">
            <div className="awsui-util-font-size-3">
              <Spinner />
              Uploading your file
            </div>
          </div>
        );

      case CONSTANT.MODAL_UPLOAD_SUCCESS:
        return (
          <div className="awsui-util-t-c">
            <div className="awsui-util-font-size-3">
              <Icon variant="success" name="status-positive" />
              Upload Success
            </div>
            <br />
            <div>Your file have been successfully uploaded</div>
            <br />
            <br />
            <Button variant="primary" text="View upload summary" onClick={handleNextClick} />
          </div>
        );

      case CONSTANT.MODAL_UPLOAD_ERROR:
        return (
          <div className="awsui-util-t-c">
            <div className="awsui-util-font-size-3">
              <Icon variant="error" name="status-negative" />
              Upload failed
            </div>
            <br />
            <div>
              Sorry, file upload failed due to some unknown error. Please retry or contact us.
            </div>
            <br />
            <br />
            <div>
              <span>
                <Button text="Retry" onClick={handleUploadRetry} />
                <Button
                  icon="external"
                  text="Customer service"
                  iconAlign="right"
                  onClick={() => {}}
                />
              </span>
            </div>
          </div>
        );

      default:
        return <div className="awsui-util-t-c"> Wrong status</div>;
    }
  }

  render() {
    return (
      <div>
        <FormSection className="custom-screenshot-hide" header="Step 5 - File Upload">
          <div className="awsui-util-container">
            <br />
            {this.getBackgroundContent()}
          </div>
        </FormSection>
      </div>
    );
  }
}

export default withRouter(StepFive);
