import React from 'react';
// import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'antd/dist/antd.css';
import '@amzn/awsui-components-react/index.css';
import CustomProps from 'common/prop-types';
import CONSTANT from 'utils/constant';
import Logger from 'utils/logger';
import APIClient from 'utils/apiClient';
import config from 'config/config.json';
import getModalMessage from 'common/components/feedback/modal';
import XLSXUtils from 'utils/xlsxUtils';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import StepSix from './StepSix';
import { ProgressBar } from './utils';

/*
 * There are in total six different steps in File upload workflow
 * This component wrap the progress bar and the content of current step
 */
class FileUpload extends React.Component {
  static propTypes = {
    history: CustomProps.history().isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      templateName: '',
      stepStatus: [CONSTANT.STEP_PROCESS].concat(new Array(5).fill(CONSTANT.STEP_WAIT)),
      status: CONSTANT.MODAL_EMPTY,
      metaItems: [],
      metaColumns: [],
      fileName: '',
      fileData: null,
      fileParsingError: null,
      fileStructureErrorList: [],
      fieldLevelValidationErrorList: [],
      totalRecordCount: 0,
      totalMJECount: 0,
      validationStartTime: null,
      validationEndTime: null,
      uploadStartTime: null,
      uploadEndTime: null,
    };

    this.handleNextClick = this.handleNextClick.bind(this);
    this.handleQueryTemplateClick = this.handleQueryTemplateClick.bind(this);
    this.handleViewClick = this.handleViewClick.bind(this);
    this.handleDownloadClick = this.handleDownloadClick.bind(this);
    this.handleValidationClick = this.handleValidationClick.bind(this);
    this.handleTryOtherFileClick = this.handleTryOtherFileClick.bind(this);
    this.handleUploadClick = this.handleUploadClick.bind(this);
    this.handleUploadRetry = this.handleUploadRetry.bind(this);
    this.getStepContent = this.getStepContent.bind(this);
  }

  static getCurrentTime = () => moment().format(config.systemDateFormat);

  getStepContent() {
    const {
      currentIndex,
      templateName,
      status,
      metaItems,
      metaColumns,
      fileName,
      fileParsingError,
      fileStructureErrorList,
      fieldLevelValidationErrorList,
      totalRecordCount,
      totalMJECount,
      validationStartTime,
      validationEndTime,
      uploadStartTime,
      uploadEndTime,
    } = this.state;

    switch (currentIndex) {
      case 0:
        return <StepOne handleQueryTemplateClick={this.handleQueryTemplateClick} />;
      case 1:
        return (
          <StepTwo
            templateName={templateName}
            items={metaItems}
            columns={metaColumns}
            handleDownloadClick={this.handleDownloadClick}
            handleViewClick={this.handleViewClick}
            handleNextClick={this.handleNextClick}
          />
        );
      case 2:
        return <StepThree handleValidationClick={this.handleValidationClick} />;
      case 3:
        return (
          <StepFour
            fileName={fileName}
            fileParsingError={fileParsingError}
            fileStructureErrorList={fileStructureErrorList}
            fieldLevelValidationErrorList={fieldLevelValidationErrorList}
            handleTryOtherFileClick={this.handleTryOtherFileClick}
            handleUploadClick={this.handleUploadClick}
          />
        );
      case 4:
        return (
          <StepFive
            status={status}
            handleNextClick={this.handleNextClick}
            handleUploadRetry={this.handleUploadRetry}
          />
        );
      case 5:
        return (
          <StepSix
            templateName={templateName}
            totalRecordCount={totalRecordCount}
            totalMJECount={totalMJECount}
            validationStartTime={validationStartTime}
            validationEndTime={validationEndTime}
            uploadStartTime={uploadStartTime}
            uploadEndTime={uploadEndTime}
          />
        );

      default:
        return (
          <div>
Error state with step =
            {currentIndex}
          </div>
        );
    }
  }

  handleNextClick() {
    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + 1,
    }));
  }

  handleQueryTemplateClick(newTemplateName) {
    const { templateName } = this.state;

    if (!newTemplateName) {
      this.setState({ status: CONSTANT.MODAL_REQUIRED_EMPTY });
    } else {
      this.setState({
        templateName: newTemplateName,
        status: CONSTANT.MODAL_FETCHING_META_DATA,
      });
      APIClient.invokeBusinessLogic(
        'ddb',
        'QueryMetaData',
        { template_name: newTemplateName },
        {},
        (err, data) => {
          if (!err) {
            switch (data.status) {
              case CONSTANT.RESPONSE_SUCCESS:
                this.setState({
                  status: CONSTANT.MODAL_QUERY_SUCCESS,
                  metaItems: data.items,
                  metaColumns: data.columns,
                });
                this.handleNextClick();
                break;
              case CONSTANT.RESPONSE_KEY_NOT_EXIST:
                this.setState({ status: CONSTANT.MODAL_META_QUERY_KEY_NOT_EXIST });
                break;
              case CONSTANT.RESPONSE_ERROR:
                this.setState({ status: CONSTANT.MODAL_QUERY_ERROR });
                break;
              case CONSTANT.RESPONSE_REQUEST_ERROR:
                this.setState({ status: CONSTANT.MODAL_REQUEST_ERROR });
                break;
              default:
            }
          } else {
            Logger.logError(`Backend crashed when query meta data for template ${templateName}`);
            this.setState({ status: CONSTANT.MODAL_QUERY_ERROR });
          }
        },
      );
    }
  }

  handleViewClick(elementSelected) {
    // can not directly pass elementSelected[0] since valid_values are modified to String
    const { metaItems } = this.state;
    const { history } = this.props;
    const itemToView = metaItems[elementSelected[0].index - 1];

    history.push({
      pathname: '/MJE/UpdateMetaData',
      state: { metaDefinition: itemToView },
    });
  }

  /* Download logic is included in XLSX writeFile */
  handleDownloadClick() {
    const { templateName, metaItems } = this.state;
    XLSXUtils.writeEmptyXLSX(templateName, metaItems);
  }

  async handleValidationClick(fileList) {
    const { metaItems, templateName } = this.state;

    this.setState({
      status: CONSTANT.MODAL_VALIDATING,
      fileName: fileList[0].name,
      validationStartTime: FileUpload.getCurrentTime(),
    });

    try {
      const fileData = await XLSXUtils.loadFile(fileList[0]);
      this.setState({ fileData });
      Logger.logInfo(`fileData = ${fileData}`);
      Logger.logInfo(`meta = ${JSON.stringify(metaItems)}`);
      // TODO: call validation api
      APIClient.invokeBusinessLogic(
        'validate',
        'ValidateMJEFile',
        {},
        { metaData: metaItems, fileData },
        (err, data) => {
          if (!err) {
            Logger.logInfo(`validation response = ${JSON.stringify(data)}`);
            switch (data.status) {
              case CONSTANT.RESPONSE_SUCCESS:
                this.setState({
                  status: CONSTANT.MODAL_VALIDATE_SUCCESS,
                  fileParsingError: data.PARSING_ERROR,
                  fileStructureErrorList: data.FILE_STRUCTURE_ERROR,
                  fieldLevelValidationErrorList: data.FIELD_LEVEL_ERROR,
                  validationEndTime: FileUpload.getCurrentTime(),
                });
                this.handleNextClick();
                // TODO: display errors, download button, and upload button
                // (might be disabled due to error)
                break;
              case CONSTANT.RESPONSE_ERROR:
                this.setState({ status: CONSTANT.MODAL_VALIDATE_ERROR });
                break;
              case CONSTANT.RESPONSE_REQUEST_ERROR:
                this.setState({ status: CONSTANT.MODAL_REQUEST_ERROR });
                break;
              default:
            }
          } else {
            Logger.logError(`Backend crash when validate a file for template ${templateName}`);
            this.setState({ status: CONSTANT.MODAL_VALIDATE_ERROR });
          }
        },
      );
    } catch (e) {
      // error parsing the file, validation tables
      this.setState({ status: CONSTANT.MODAL_FILE_FORMAT_ERROR });
    }
  }

  handleTryOtherFileClick() {
    this.setState({
      status: CONSTANT.MODAL_QUERY_SUCCESS,
      fileName: undefined,
      fileData: undefined,
      fileParsingError: undefined,
      fileStructureErrorList: undefined,
      fieldLevelValidationErrorList: undefined,
      currentIndex: 2,
    });
  }

  handleUploadClick() {
    const { metaItems, fileData, templateName } = this.state;

    this.setState({
      status: CONSTANT.MODAL_UPLOADING,
      uploadStartTime: FileUpload.getCurrentTime(),
    });

    this.handleNextClick();
    APIClient.invokeBusinessLogic(
      'ddb',
      'UploadMJEFile',
      {},
      { metaData: metaItems, fileData },
      (err, data) => {
        if (!err) {
          switch (data.status) {
            case CONSTANT.RESPONSE_SUCCESS:
              this.setState({
                totalRecordCount: data.totalRecordCount,
                totalMJECount: data.totalMJECount,
                uploadEndTime: FileUpload.getCurrentTime(),
                status: CONSTANT.MODAL_UPLOAD_SUCCESS,
              });
              break;
            case CONSTANT.RESPONSE_ERROR:
              Logger.logError(`Backend crash when upload a file for template ${templateName}`);
              this.setState({ status: CONSTANT.MODAL_UPLOAD_ERROR });
              break;
            case CONSTANT.MODAL_REQUEST_ERROR:
              this.setState({ status: CONSTANT.MODAL_REQUEST_ERROR });
              break;
            default:
          }
        } else {
          Logger.logError(`Backend crash when upload a file for template ${templateName}`);
          this.setState({ status: CONSTANT.MODAL_UPLOAD_ERROR });
        }
      },
    );
  }

  handleUploadRetry() {
    this.handleUploadClick();
  }

  render() {
    const { stepStatus, currentIndex, status } = this.state;
    return (
      <div>
        <ProgressBar stepStatus={stepStatus} currentIndex={currentIndex} />
        {this.getStepContent()}
        {getModalMessage(status, this)}
      </div>
    );
  }
}

export default withRouter(FileUpload);
