import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'actions';
import PropTypes from 'prop-types';
import CustomProps from 'common/prop-types';

import ReactTable from 'react-table';
import 'react-table/react-table.css';

import MJEBanner from 'common/components/mjeBanner/MJEBanner';
import {
  Button, RadioButton, Icon, Spinner,
} from '@amzn/awsui-components-react';
import '@amzn/awsui-components-react/index.css';
import styles from './ReportsHistory.scss';

const myDashboardColumnsFilter = ({ filter, onChange }) => (
  <input
    placeholder="Filter"
    className={styles.filterInput}
    onChange={event => onChange(event.target.value)}
    value={filter ? filter.value : ''}
  />
);

const filterMethod = (filter, row) => {
  const id = filter.pivotId || filter.id;
  return row[id]
    ? String(row[id].toLowerCase()).indexOf(filter.value.trim().toLowerCase()) !== -1
    : false;
};

const RADIO_BUTTON_COLUMN_ID = 999;

let columItems = [
  {
    Header: '#',
    id: 0,
    width: 100,
    accessor: 'entry_id',
    headerClassName: styles.leftAlignContent,
    filterable: false,
  },
  {
    Header: 'Report',
    accessor: 'type',
    width: 350,
    headerClassName: styles.leftAlignContent,
    Filter: myDashboardColumnsFilter,
  },
  {
    Header: 'Report ID',
    accessor: 'report_id',
    headerClassName: styles.leftAlignContent,
    Filter: myDashboardColumnsFilter,
  },
  {
    Header: 'Period',
    accessor: 'period',
    headerClassName: styles.leftAlignContent,
    Filter: myDashboardColumnsFilter,
    width: 100,
  },
  {
    Header: 'Run Date',
    accessor: 'run_date',
    headerClassName: styles.leftAlignContent,
    Filter: myDashboardColumnsFilter,
  },
  {
    Header: 'Status',
    accessor: 'status',
    headerClassName: styles.leftAlignContent,
    width: 100,
    Filter: myDashboardColumnsFilter,
    Cell: (row) => {
      const icon = (row.original.status === 'ready' ? 'positive' : 'negative');
      return (row.original.status === 'running'
        ? (
          <span className="awsui-util-status-inactive">
            <Spinner />
            {' '}
Running
          </span>
        )
        : (
          <span className={`awsui-util-status-${icon}`}>
            {' '}
            <Icon name={`status-${icon}`} />
            {' '}
&nbsp;
            {row.original.status.charAt(0).toUpperCase() + row.original.status.slice(1)}
          </span>
        ));
    },
  },
];

export class ReportsHistory extends Component {
  static propTypes = {
    fetching: PropTypes.bool,
    runLogs: PropTypes.arrayOf(PropTypes.object),
    downloading: PropTypes.bool,
    fileURL: PropTypes.string,
    // Redux Functions
    getReportsHistory: PropTypes.func.isRequired,
    downloadReportReset: PropTypes.func.isRequired,
    // Router Props
    history: CustomProps.history(),
  };

    static defaultProps = {
      fetching: true,
      runLogs: [],
      downloading: false,
      fileURL: null,

      history: {},
    };

    constructor(props) {
      super(props);

      this.state = {
        selectedReportLog: null,
        showDashboardFilter: false,
      };
      this.buildMyDashboardHeaderColumns = this.buildMyDashboardHeaderColumns.bind(this);
      this.selectReportLog = this.selectReportLog.bind(this);
      this.toggleFilter = this.toggleFilter.bind(this);
      this.handleDownload = this.handleDownload.bind(this);
      this.componentDidMount = this.componentDidMount.bind(this);
      this.componentWillUnmount = this.componentWillUnmount.bind(this);
    }

    componentDidMount() {
      const { getReportsHistory,getReportsHistoryReset } = this.props;
      getReportsHistoryReset();
      getReportsHistory();
      this.intervalID = setInterval(getReportsHistory, 30000);
    }

    componentDidUpdate() {
      const { fileURL } = this.props;
      if (fileURL !== null) {
        window.open(fileURL);
        const { downloadReportReset } = this.props;
        this.setState(() => ({ selectedReportLog: null }));
        downloadReportReset();
      }
    }

    componentWillUnmount() {
      clearInterval(this.intervalID);
    }

    selectReportLog(reportLog) {
      this.setState(() => ({ selectedReportLog: reportLog }));
    }

    toggleFilter() {
      const { showDashboardFilter } = this.state;
      this.setState(() => ({ showDashboardFilter: !showDashboardFilter }));
    }

    buildMyDashboardHeaderColumns() {
      const { selectedReportLog } = this.state;
      if (columItems.find(x => x.id === RADIO_BUTTON_COLUMN_ID) != null) {
        columItems = columItems.filter(x => x.id !== RADIO_BUTTON_COLUMN_ID);
      }
      columItems = [
        ...columItems,
        {
          id: RADIO_BUTTON_COLUMN_ID,
          Header: 'Select Report',
          Cell: row => (
            <RadioButton
              type="radio"
              className="awsui-util-ml-m"
              value={row.original.entry_id.toString()}
              label=""
              name="typeSelector"
              onChange={() => this.selectReportLog(row.original.entry_id)}
              checked={
              selectedReportLog != null
              && selectedReportLog === row.original.entry_id
            }
              disabled={row.original.status !== 'ready'}
            />
          ),
          sortable: false,
          filterable: false,
          width: 100,
        },
      ];
    }

    handleDownload() {
      const { selectedReportLog } = this.state;
      const { runLogs, downloadReport } = this.props;
      const fileName = runLogs[selectedReportLog - 1].file;
      downloadReport(fileName);
    }

    render() {
      const bannerText = 'MJE 1.0 - Reporting History';
      const {
        showDashboardFilter,
        selectedReportLog,
      } = this.state;

      const {
        history,
        runLogs,
        fetching,
        downloading,
      } = this.props;
      this.buildMyDashboardHeaderColumns();
      return (
        <div className={styles.wrapper}>
          <MJEBanner bannerText={bannerText} />
          <br />
          <div className="awsui-util-container">
            <div className="awsui-util-container-header">
              <div className="awsui-util-action-stripe">
                <div className="awsui-util-action-stripe-title">
                  <h2>
                      MJE 30 Day Reporting History
                  </h2>
                </div>
                <div className="awsui-util-action-stripe-group">
                  <Button
                    icon="filter"
                    iconAlign="right"
                    className={`button-${showDashboardFilter ? 'active' : ''}`}
                    onClick={this.toggleFilter}
                  >
                      Filter Rows
                  </Button>
                </div>
              </div>
            </div>
            <div data-awsui-column-layout-root>
              <ReactTable
                data={runLogs}
                minRows={0}
                columns={columItems}
                filterable={showDashboardFilter}
                defaultFilterMethod={filterMethod}
                sortable
                loading={fetching}
                wrapLines={false}
                resizableColumns
                stickyHeader
                showPagination
                noMatch="noMatchContent"
              />
            </div>
            <div className="awsui-util-action-stripe">
              <div className="awsui-util-action-stripe-title">
                <Button
                  onClick={() => history.push('/MJE/Reports')}
                >
                    Go Back
                </Button>
              </div>
              <div className={`awsui-util-action-stripe-group ${styles.downloadActionWrapper}`}>
                <Button
                  onClick={this.handleDownload}
                  disabled={!(selectedReportLog != null) || downloading}
                  loading={downloading}
                  variant="primary"
                >
                  {downloading ? 'Downloading...' : 'Download' }
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

const mapStateToProps = (state) => {
  const {
    fetching,
    runLogs,
    downloading,
    fileURL,
  } = state.reportsHistory;
  return {
    fetching,
    runLogs,
    downloading,
    fileURL,
  };
};

const mapDispatchToProps = {
  getReportsHistory: actions.getReportsHistory,
  getReportsHistoryReset: actions.getReportsHistoryReset,
  downloadReport: actions.downloadReport,
  downloadReportReset: actions.downloadReportReset,
};

const ReportsHistoryContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsHistory);

export default withRouter(ReportsHistoryContainer);
