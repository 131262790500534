/* eslint-disable no-console */
const Logger = {
  // TODO: need to replace with invoking logging service in future
  logError(error, errorInfo) {
    console.log(error, errorInfo);
  },
  logInfo(message) {
    console.log(message);
  },
};

export default Logger;
