const FORMATTERS = {
    DataTypes: {
        INTEGER: 'Integer',
        DECIMAL: 'Decimal',
        CURRENCY: 'Currency',
        TEXT: 'Text',
    },
    currencyTextFormatter: (value) => {
        if (value == null) {
            return null;
        }
        if (value === '') {
            return '';
        }
        let result = value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
        const decimalPointIndex = result.indexOf('.');

        if (decimalPointIndex > 0) {
            let decimals = result.substring(decimalPointIndex + 1);
            if (decimals.length === 1) {
                decimals += '0';
            } else if (decimals.length > 2) {
                decimals = decimals.substring(0, 2);
            }
            result = result.substring(0, decimalPointIndex + 1) + decimals;
        }
        else {
            result += '.00';
        }

        return value < 0 ? `(${result.substring(1)})` : result;
    },
    decimalTextFormatter: (value) => {
        if (value == null) {
            return null;
        }
        if (value === '') {
            return '';
        }
        const result = value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

        return value < 0 ? `(${result.substring(1)})` : result;
    },
    integerTextFormatter: (value) => {
        if (value == null) {
            return null;
        }
        if (value === '') {
            return '';
        }
        const result = value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

        return value < 0 ? `(${result.substring(1)})` : result;
    },
    defaultTextFormatter: value => value,
    resolveFormatter: (dataType) => {
        switch (dataType) {
            case FORMATTERS.DataTypes.CURRENCY:
                return FORMATTERS.currencyTextFormatter;
            case FORMATTERS.DataTypes.DECIMAL:
                return FORMATTERS.decimalTextFormatter;
            case FORMATTERS.DataTypes.INTEGER:
                return FORMATTERS.integerTextFormatter;
            default:
                return FORMATTERS.defaultTextFormatter;
        }
    },
    isNumericDataType: (dataType) => {
        switch (dataType) {
            case FORMATTERS.DataTypes.CURRENCY:
            case FORMATTERS.DataTypes.DECIMAL:
            case FORMATTERS.DataTypes.INTEGER:
                return true;
            default:
                return false;
        }
    },
};

export default FORMATTERS;
