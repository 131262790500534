import * as CONST from 'common/const';
import createReducer from './createReducer';

export const initialState = {
  loading: false,
  error: null,
  downloadUrl: null,
};

export default createReducer(initialState, {
  [CONST.PRIME_POST_PAGE_FETCH_DATA_START]: state => ({
    ...state,
    loading: true,
    error: null,
  }),

  [CONST.PRIME_POST_PAGE_FETCH_DATA_SUCCESS]: state => ({
    ...state,
    loading: false,
    error: null,
  }),

  [CONST.PRIME_POST_PAGE_FETCH_DATA_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }),

  [CONST.PRIME_POST_PAGE_DOWNLOAD_START]: state => ({
    ...state,
    loading: true,
    error: null,
  }),

  [CONST.PRIME_POST_PAGE_DOWNLOAD_SUCCESS]: (state, action) => ({
    ...state,
    downloadUrl: action.payload,
    loading: false,
  }),

  [CONST.PRIME_POST_PAGE_DOWNLOAD_FAILURE]: state => ({
    ...state,
    downloadUrl: null,
    loading: false,
  }),

  [CONST.PRIME_POST_PAGE_DOWNLOAD_RESET]: state => ({
    ...state,
    downloadUrl: null,
  }),
});
