import { combineReducers } from 'redux';
import auth from './auth';
import landingPage from './landingPage';
import primeJournalSummary from './primeJournalSummary';
import primePostPage from './primePostPage';
import validationUI from './validationUI';
import errorReducer from './errorReducer';
import searchModal from './searchModal';
import reports from './reports';
import reportsHistory from './reportsHistory';


export default combineReducers({
  auth,
  landingPage,
  primeJournalSummary,
  primePostPage,
  validationUI,
  errorReducer,
  searchModal,
  reports,
  reportsHistory,
});
