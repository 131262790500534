import * as CONST from 'common/const';
import createReducer from './createReducer';

export const initialState = {
  username: null,
  jwtToken: null,
  error: null,
};

export default createReducer(initialState, {
  [CONST.LOGIN_SUCCESS]: (state, { payload }) => ({
    ...state,
    username: payload.username,
    jwtToken: payload.jwtToken,
    error: null,
  }),

  [CONST.LOGIN_FAILURE]: (state, error) => ({
    ...state,
    username: null,
    jwtToken: null,
    error,
  }),
});
