import React from 'react';
import PropTypes from 'prop-types';
import '@amzn/awsui-components-react/index.css';
import { FormField, ColumnLayout } from '@amzn/awsui-components-react';
import { CustomTileElement } from 'common/components/dataEntry';

/*
 * Data type selection
 * At current stage, we only support integer, decimal, boolean, enumerated, date and free-text
 * Validation happens per data type
 */
function MetaDataTypeTiles({
  value, disabled, name, handleTileClick,
}) {
  return (
    <ColumnLayout columns={1}>
      <div data-awsui-column-layout-root>
        <FormField
          label="Data type*"
          description="Please choose a type for the data defined. Validation will be enforced accordingly when uploading/inputting real data in future."
        >
          <ColumnLayout columns={3}>
            <div data-awsui-column-layout-root>
              <CustomTileElement
                selected={value}
                disabled={disabled}
                label="Integer"
                description="The data must be a valid integer. Ex: -1, 0, 1, etc"
                handleClick={v => handleTileClick(name, v)}
              />
              <CustomTileElement
                selected={value}
                disabled={disabled}
                label="Decimal"
                description="The data must be a valid decimal. Ex: -1, 0.2, 1e-5, etc"
                handleClick={v => handleTileClick(name, v)}
              />
              <CustomTileElement
                selected={value}
                disabled={disabled}
                label="Boolean"
                description="The data must be either Y or N"
                handleClick={v => handleTileClick(name, v)}
              />
              <CustomTileElement
                selected={value}
                disabled={disabled}
                label="Enumerated"
                description="The data must be choosen from a predefined list of values"
                handleClick={v => handleTileClick(name, v)}
              />
              <CustomTileElement
                selected={value}
                disabled={disabled}
                label="Date"
                description="The data must be a valid date from calendar"
                handleClick={v => handleTileClick(name, v)}
              />
              <CustomTileElement
                selected={value}
                disabled={disabled}
                label="FreeText"
                description="No strict rules on this data. It can be any alphanumeric text input."
                handleClick={v => handleTileClick(name, v)}
              />
            </div>
          </ColumnLayout>
        </FormField>
      </div>
    </ColumnLayout>
  );
}

MetaDataTypeTiles.propTypes = {
  handleTileClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.shape({}).isRequired,

  disabled: PropTypes.bool,
};

MetaDataTypeTiles.defaultProps = {
  disabled: false,
};

export default MetaDataTypeTiles;
