import React from 'react';
import { withRouter } from 'react-router-dom';
import '@amzn/awsui-components-react/index.css';
import CONSTANT from 'utils/constant';
import APIClient from 'utils/apiClient';
import Logger from 'utils/logger';
import getModalMessage from 'common/components/feedback/modal';
import SearchBar from './SearchBar';
import MJEHeaderSection from './MJEHeaderSection';
import MJELinesSection from './MJELinesSection';

class QueryMJE extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      templateName: undefined,
      mjeKeys: [],
      status: CONSTANT.MODAL_EMPTY,
      items: [],
      columns: [],
    };

    this.handleFetchKey = this.handleFetchKey.bind(this);
    this.handleQueryClick = this.handleQueryClick.bind(this);
    this.getMJEContent = this.getMJEContent.bind(this);
  }

  getMJEContent() {
    const {
      header, templateName, items, columns,
    } = this.state;

    Logger.logInfo(this.state);
    if (header !== undefined) {
      return (
        <div>
          <MJEHeaderSection header={header} />
          <MJELinesSection templateName={templateName} items={items} columns={columns} />
        </div>
      );
    }

    return null;
  }

  handleFetchKey(templateName) {
    Logger.logInfo(templateName);
    this.setState({
      templateName,
      status: CONSTANT.MODAL_FETCHING_KEY,
    });
    APIClient.invokeBusinessLogic('ddb', 'FetchMJEKeys', { template_name: templateName }, {}, (err, data) => {
      if (!err) {
        switch (data.status) {
          case CONSTANT.RESPONSE_SUCCESS:
            this.setState({
              status: CONSTANT.MODAL_QUERY_SUCCESS,
              mjeKeys: data.keyColumns,
            });
            break;
          case CONSTANT.RESPONSE_KEY_NOT_EXIST:
            this.setState({ status: CONSTANT.MODAL_KEY_COLUMN_NOT_EXIST });
            break;
          case CONSTANT.RESPONSE_ERROR:
            this.setState({ status: CONSTANT.MODAL_REQUEST_ERROR });
            break;
          default:
            break;
        }
      } else {
        this.setState({ status: CONSTANT.MODAL_QUERY_ERROR });
      }
    });
  }

  handleQueryClick(templateName, gsiValueJSON) {
    Logger.logInfo(`handleQueryClick = ${templateName} ${gsiValueJSON}`);
    this.setState({ status: CONSTANT.MODAL_QUERYING });

    APIClient.invokeBusinessLogic(
      'ddb',
      'QueryMJE',
      Object.assign({}, gsiValueJSON, { template_name: templateName }),
      {},
      (err, data) => {
        if (!err) {
          switch (data.status) {
            case CONSTANT.RESPONSE_SUCCESS:
              this.setState({
                status: CONSTANT.MODAL_QUERY_SUCCESS,
                header: data.header,
                items: data.items,
                columns: data.columns,
              });
              break;
            case CONSTANT.RESPONSE_KEY_NOT_EXIST:
              this.setState({ status: CONSTANT.MODAL_MJE_NOT_EXIST });
              break;
            case CONSTANT.RESPONSE_ERROR:
              this.setState({ status: CONSTANT.MODAL_QUERY_ERROR });
              break;
            case CONSTANT.RESPONSE_REQUEST_ERROR:
              this.setState({ status: CONSTANT.MODAL_REQUEST_ERROR });
              break;
            default:
              break;
          }
        } else {
          Logger.logError(`Backend crash when query templateName = ${templateName}`);
          this.setState({ status: CONSTANT.MODAL_QUERY_ERROR });
        }
      },
    );
  }

  render() {
    const { mjeKeys, status } = this.state;
    return (
      <div>
        <SearchBar
          mjeKeys={mjeKeys}
          handleValueChange={this.handleValueChange}
          setErrorFlag={this.setErrorFlag}
          handleFetchKey={this.handleFetchKey}
          handleQueryClick={this.handleQueryClick}
        />
        {this.getMJEContent()}
        {getModalMessage(status, this)}
      </div>
    );
  }
}

export default withRouter(QueryMJE);
