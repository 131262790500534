const ArrayUtils = {
  /**
     * Checks if two arrays contain the same elements regardless of order.
     * @param {*} a Array a
     * @param {*} b Array b
     * @returns false if the counts do not match for any element, true otherwise.
     */
  equalsIgnoreOrder(a, b) {
    if (a.length !== b.length) return false;
    const uniqueValues = new Set([...a, ...b]);
    for (const v of uniqueValues) {
      const aCount = a.filter((e) => e === v).length;
      const bCount = b.filter((e) => e === v).length;
      if (aCount !== bCount) return false;
    }
    return true;
  },
  isNullOrEmpty(array) {
    return array == null || array.length === 0;
  },
  toArray(input, separator) {
    if (input == null || input.trim() === '') {
      return [];
    }
    const result = input.split(separator);
    if (result.length === 1 && result[0].trim() === '') {
      return [];
    }
    return result;
  },
  groupBy(data, key) { // `data` is an array of objects, `key` is the key (or property accessor) to group by
    // reduce runs this anonymous function on each element of `data` (the `item` parameter,
    // returning the `storage` parameter at the end
    return data.reduce((storage, item) => {
      // get the first instance of the key by which we're grouping
      const group = item[key];

      // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
      storage[group] = storage[group] || [];

      // add this item to its group within `storage`
      storage[group].push(item);

      // return the updated storage to the reduce function, which will then loop through the next
      return storage;
    }, {}); // {} is the initial value of the storage
  },
  groupBy2Keys(data, key1, key2, keysSeparator = ',') {
    return data.reduce(function (rv, x) {
        (rv[x[key1] + keysSeparator + x[key2]] = rv[x[key1] + keysSeparator + x[key2]] || []).push(x);
        return rv;
    }, {});
  },
};

export default ArrayUtils;
