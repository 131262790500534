/**
 * This file contains PropType validators for
 * React Router objects. Using React Router's
 * `withRouter` method injects several properties
 * into your React components which you may use
 * to interact with browser navigation.
 *
 * For more information on `withRouter`, see
 * React Router's documentation page here:
 * Link: https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/withRouter.md
 */
import PropTypes from 'prop-types';

/**
 * React Router's Location object.
 *
 * Takes a `state` object containing mappings
 * between expected browser location state
 * parameters and PropType validators to
 * match their expected types.
 *
 * Link: https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/location.md
 */
export const location = (state = {}) => PropTypes.shape({
  // The path of the URL
  pathname: PropTypes.string.isRequired,

  // The URL query string
  search: PropTypes.string.isRequired,

  // The URL hash fragment
  hash: PropTypes.string.isRequired,

  // Location-specific state provided when location was pushed onto history stack.
  // State can be undefined; Don't mark as isRequired
  state: PropTypes.shape({
    ...state,
  }),
});

/**
 * React Router's Match object.
 *
 * Takes a `params` object containing mappings
 * between expected query parameter keys and
 * PropType validators to match their expected
 * types.
 *
 * Link: https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/match.md
 */
export const match = (params = {}) => PropTypes.shape({
  // `true` if the entire URL was matched (no trailing characters)
  isExact: PropTypes.bool.isRequired,

  // The path pattern used to match
  path: PropTypes.string.isRequired,

  // The matched portion of the URL
  url: PropTypes.string.isRequired,

  // Key/value pairs parsed from URL's dynamic path segments
  params: PropTypes.shape({
    ...params,
  }).isRequired,
});

/**
 * React Router's History object.
 *
 * Takes a `state` object containing mappings
 * between expected browser location state
 * parameters and PropType validators to
 * match their expected types.
 *
 * This object gets passed to the `location`
 * validator defined above.
 *
 * Link: https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/history.md
 */
export const history = (state = {}) => PropTypes.shape({
  // Number of entries in history stack
  length: PropTypes.number.isRequired,

  // The current action
  action: PropTypes.oneOf(['PUSH', 'REPLACE', 'POP']).isRequired,

  // The current browser location
  location: location(state).isRequired,

  // Navigation Functions
  push: PropTypes.func.isRequired, // Pushes new entry on the history stack
  replace: PropTypes.func.isRequired, // Replaces current entry on history stack
  go: PropTypes.func.isRequired, // Move pointer in history stack by `n` entries
  goBack: PropTypes.func.isRequired, // Equivalent to `go(-1)`
  goForward: PropTypes.func.isRequired, // Equivalent to `go(1)`
  block: PropTypes.func.isRequired, // Prevent Navigation
});

export default {
  history,
  location,
  match,
};
