import React from 'react';
import PropTypes from 'prop-types';
import '@amzn/awsui-components-react/index.css';
import { FormField, ColumnLayout } from '@amzn/awsui-components-react';
import { DecimalElement } from 'common/components/dataEntry';

/*
 * Specific setup for decimal type
 */
function DecimalRangeBlock({ value, handleChange, setErrorFlag }) {
  return (
    <FormField
      label="Value Range"
      description="Please provide the range of this data. Left empty if no constraint."
    >
      <ColumnLayout columns={4}>
        <div data-awsui-column-layout-root>
          <DecimalElement
            title="Min value"
            name="min"
            value={value.min}
            disabled={false}
            required={false}
            handleChange={handleChange}
            setErrorFlag={setErrorFlag}
          />
          <DecimalElement
            title="Max value"
            name="max"
            value={value.max}
            disabled={false}
            required={false}
            handleChange={handleChange}
            setErrorFlag={setErrorFlag}
          />
        </div>
      </ColumnLayout>
    </FormField>
  );
}

DecimalRangeBlock.propTypes = {
  handleChange: PropTypes.func.isRequired,
  setErrorFlag: PropTypes.func.isRequired,

  value: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
};

DecimalRangeBlock.defaultProps = {};

export default DecimalRangeBlock;
