import React from 'react';
import PropTypes from 'prop-types';
import { FormField, Select } from '@amzn/awsui-components-react';
import ErrorBoundary from 'common/components/err/errorBoundary';
import '@amzn/awsui-components-react/index.css';
import 'antd/dist/antd.css';
import 'index.css';

// Component for enum input
class DropDownElement extends React.Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    setErrorFlag: PropTypes.func.isRequired,

    options: PropTypes.arrayOf(PropTypes.shape({})),

    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.string,

    required: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    options: [],

    name: '',
    title: '',
    description: '',
    value: '',

    required: false,
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      errorText: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { errorText } = this.state;
    const {
      handleChange, setErrorFlag, required, name,
    } = this.props;
    const selectedValue = event.target.component.selectedId;

    if (selectedValue === '' && required) this.setState({ errorText: 'This field is required' });
    else this.setState({ errorText: '' });
    handleChange(name, selectedValue);
    setErrorFlag(name, errorText);
  }

  render() {
    const { errorText } = this.state;
    const {
      title, required, description, value, options, disabled,
    } = this.props;

    return (
      <ErrorBoundary content={title}>
        <FormField
          label={title + (required ? '*' : '')}
          description={description}
          errorText={errorText}
        >
          <Select
            options={options}
            loadingText="Loading..."
            selectedId={value}
            placeholder={value}
            enableFiltering
            onChange={this.handleChange}
            disabled={disabled}
          />
        </FormField>
      </ErrorBoundary>
    );
  }
}

export default DropDownElement;
