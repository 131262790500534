import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Upload, Form } from 'antd';
import { Icon, Button, Modal } from '@amzn/awsui-components-react';
import ReactTooltip from 'react-tooltip';
import styles from './Uploader.scss';
import CONSTANT from "../../../utils/constant";

class Uploader extends React.Component {
  static propTypes = {
    onFileChosen: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      localError: null,
    };

    this.onChange = this.onChange.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.validFileType = this.validFileType.bind(this);
  }

  onChange({ file }) {
    const { onFileChosen } = this.props;
    if (this.validFileType(file.name)) {
      onFileChosen({
        file,
      });
    }
  }

  beforeUpload = (file) => {
    if (this.validFileType(file.name)) {
      return false;
    }

    this.setState(() => ({
      localError: `The file (${file.name}) is not a supported excel type. Only ${CONSTANT.ACCEPTED_FILE_TYPES.join(", ")} files are accepted.`,
    }));
  };

  onDismiss() {
    this.setState(() => ({
      localError: null,
    }));
  }

  validFileType(fileName) {
    for (let i = 0; i < CONSTANT.ACCEPTED_FILE_TYPES.length; i++) {
      if (fileName.toLowerCase().endsWith(CONSTANT.ACCEPTED_FILE_TYPES[i])) {
        return true;
      }
    }
    return false;
  }

  render() {
    // TODO: change to support bulk file upload in future
    const {
      header, text, buttonText, buttonTooltip,
    } = this.props;
    const {
      localError,
    } = this.state;

    return (
      <div className={styles.uploader}>
        <Upload.Dragger
          beforeUpload={this.beforeUpload}
          accept={CONSTANT.ACCEPTED_FILE_TYPES.join(",")}
          onChange={this.onChange}
        >
          <h2 className="awsui-util-font-size-4">{header}</h2>
          <ul className={styles.flexContainer}>
            <li>
              <Icon size="large" variant="disabled" name="upload" />
            </li>
            <li>&nbsp;&nbsp;&nbsp;</li>
            <li>
              <Button
                className="awsui-util-mt-s"
                variant="primary"
                text={buttonText}
                data-tip={buttonTooltip}
              />
              <ReactTooltip
                place="bottom"
                type="dark"
                effect="solid"
                event="mouseover"
                eventOff="mouseout"
              />
            </li>
          </ul>
          <p className="awsui h1">{text}</p>
        </Upload.Dragger>

        <Modal
            visible={localError != null}
            content={<div>{localError}</div>}
            header={(<div>Upload Failed</div>)}
            onDismiss={this.onDismiss}
        />
      </div>
    );
  }
}

export default withRouter(Form.create()(Uploader));
