import React from 'react';
import PropTypes from 'prop-types';
import { Modal as AMZModal } from '@amzn/awsui-components-react';
import CommonProps from 'common/prop-types';
import styles from './Modal.scss';

function Header({ className, children }) {
  const newClassName = `${styles.header} ${className}`;
  return <div className={newClassName}>{children}</div>;
}

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Header.defaultProps = {
  className: '',
  children: null,
};

function Footer({ className, children }) {
  const newClassName = `${styles.footer} ${className}`;
  return <div className={newClassName}>{children}</div>;
}

Footer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Footer.defaultProps = {
  className: '',
  children: null,
};

function Body({ className, children }) {
  const newClassName = `${styles.body} ${className}`;
  return <div className={newClassName}>{children}</div>;
}

Body.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Body.defaultProps = {
  className: '',
  children: null,
};

const ChildType = PropTypes.oneOfType([
  CommonProps.componentOf(Body),
  CommonProps.componentOf(Header),
  CommonProps.componentOf(Footer),
]);

class Modal extends React.Component {
  static propTypes = {
    className: PropTypes.string,

    children: PropTypes.oneOfType([ChildType, PropTypes.arrayOf(ChildType)]),
  };

  static defaultProps = {
    className: '',
    children: null,
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.getChildByType = this.getChildByType.bind(this);
    this.getHeader = this.getHeader.bind(this);
    this.getFooter = this.getFooter.bind(this);
    this.getBody = this.getBody.bind(this);
  }

  getChildByType(typename) {
    const { children } = this.props;

    if (children === null) {
      return null;
    }

    if (children instanceof Array) {
      const child = (children || []).filter(node => node.type === typename);
      return child.length === 1 ? child[0] : null;
    }

    if (children.type === typename) {
      return children;
    }

    return null;
  }

  getHeader() {
    return this.getChildByType(Header);
  }

  getFooter() {
    return this.getChildByType(Footer);
  }

  getBody() {
    return this.getChildByType(Body);
  }

  render() {
    const { className } = this.props;
    const newClassName = `${styles.wrapper} ${className}`;

    const header = this.getHeader();
    const footer = this.getFooter();
    const body = this.getBody();

    return (
      <AMZModal visible className={newClassName} header={header} footer={footer} content={body} />
    );
  }
}

export { Header, Footer, Body };

export default Modal;
