import React from 'react';
import PropTypes from 'prop-types';
import '@amzn/awsui-components-react/index.css';
import {
  Table,
  TableFiltering,
  TablePagination,
  TableSelection,
  TableSorting,
  TablePreferences,
  TablePageSizeSelector,
  TableWrapLines,
  TableContentSelector,
  Button,
} from '@amzn/awsui-components-react';
import ErrorBoundary from 'common/components/err/errorBoundary';

// Component for table view
function TableView({
  tableTitle,
  columnDefinitions,
  loading,
  items,
  header,
  searchMessage,
  handleInputChange,
  handleSelectChange,
  trackBy,
  pageSize,
  sortableColumns,
  pageSizeOption,
  visibleColumnOption,
}) {
  const noMatchContent = (
    <div className="awsui-util-t-c">
      <div className="awsui-util-pt-xs awsui-util-mb-xs">
        <b>No matches</b>
      </div>
      <p className="awsui-util-mb-s">We can’t find a match.</p>
      <div className="awsui-util-mb-l">
        <Button text="Clear filter" />
      </div>
    </div>
  );

  const empty = (
    <div className="awsui-util-t-c">
      <div className="awsui-util-pt-xs awsui-util-mb-xs">
        <br />
        <b> No resources </b>
      </div>
      <p className="awsui-util-mb-s">No resources to display</p>
    </div>
  );

  return (
    <ErrorBoundary content={tableTitle}>
      <Table
        loadingText="Loading Resource"
        columnDefinitions={columnDefinitions}
        empty={empty}
        items={items}
        wrapLines={false}
        resizableColumns
        stickyHeader
        loading={loading}
        header={header}
        noMatch={noMatchContent}
      >
        <TableFiltering
          filteringPlaceholder={searchMessage}
          onFilteringChange={event => handleInputChange(event.detail.filteringText)}
        />
        <TablePagination pageSize={pageSize} />
        <TableSelection
          trackBy={trackBy}
          onSelectionChange={event => handleSelectChange(event.detail.selectedItems)}
        />
        <TableSorting sortableColumns={sortableColumns} />
        <TablePreferences title="Preferences" confirmLabel="Confirm" cancelLabel="Cancel">
          <TablePageSizeSelector title="Page size" options={pageSizeOption} />
          <TableWrapLines
            label="Wrap lines"
            description="Check to see all the text and wrap the lines"
          />
          <TableContentSelector title="Select visible columns" options={visibleColumnOption} />
        </TablePreferences>
      </Table>
    </ErrorBoundary>
  );
}

TableView.propTypes = {
  handleInputChange: PropTypes.func,
  handleSelectChange: PropTypes.func,

  columnDefinitions: PropTypes.arrayOf(PropTypes.shape({})),
  sortableColumns: PropTypes.arrayOf(PropTypes.shape({})),
  pageSizeOption: PropTypes.arrayOf(PropTypes.shape({})),
  visibleColumnOption: PropTypes.arrayOf(PropTypes.shape({})),

  tableTitle: PropTypes.string,
  loading: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  searchMessage: PropTypes.string,
  trackBy: PropTypes.string,
  pageSize: PropTypes.number,
};

TableView.defaultProps = {
  handleInputChange: () => {},
  handleSelectChange: () => {},

  columnDefinitions: [],
  sortableColumns: [],
  pageSizeOption: [],
  visibleColumnOption: [],

  tableTitle: '',
  loading: false,
  items: [],
  header: '',
  searchMessage: '',
  trackBy: '',
  pageSize: 10,
};

export default TableView;
