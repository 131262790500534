import React from 'react';
import PropTypes from 'prop-types';
import '@amzn/awsui-components-react/index.css';
import { ColumnLayout } from '@amzn/awsui-components-react';
import { FreeTextElement, RadioGroupElement } from 'common/components/dataEntry';

/*
 * Basic meta information block, including:
 * Table Name
 * Column Name
 * Column description
 * Whether this field is required/optional
 * Whether this field belongs to header
 */
function MetaBasicInfoBlock({ value, handleValueChange, setErrorFlag }) {
  const requiredOption = [
    { value: 'true', label: 'Required' },
    { value: 'false', label: 'Optional' },
  ];
  const headerOption = [{ value: 'true', label: 'Header' }, { value: 'false', label: 'Line' }];
  const keyOption = [{ value: 'true', label: 'Key' }, { value: 'false', label: 'Not Key' }];

  return (
    <ColumnLayout columns={1}>
      <div data-awsui-column-layout-root>
        <FreeTextElement
          title="Template Name"
          name="templateName"
          value={value.templateName}
          disabled={false}
          required
          description="Enter the category this newly defined type of data belongs to. Similar to the table name in excel."
          handleChange={handleValueChange}
          setErrorFlag={setErrorFlag}
        />

        <FreeTextElement
          title="Column Name"
          name="columnName"
          value={value.columnName}
          disabled={false}
          required
          description="Please provide a name of this type of data. Similar to the naming a column in excel."
          handleChange={handleValueChange}
          setErrorFlag={setErrorFlag}
        />

        <FreeTextElement
          title="Column Description"
          name="columnDescription"
          disabled={false}
          required
          value={value.columnDescription}
          description="Please provide a short description for the data type you are creating for future reference."
          handleChange={handleValueChange}
          setErrorFlag={setErrorFlag}
        />

        <RadioGroupElement
          title="Belongs to search key"
          name="isKey"
          disabled={false}
          required
          value={value.isKey}
          items={keyOption}
          description="Please specify whether this field is part of MJE search key combination (uniquely identify a MJE in a single file upload)"
          handleChange={handleValueChange}
          setErrorFlag={setErrorFlag}
        />

        <RadioGroupElement
          title="Belongs to header/line Section"
          name="isHeader"
          disabled={false}
          required
          value={value.isHeader}
          items={headerOption}
          description="Please specify whether this field belongs to template header"
          handleChange={handleValueChange}
          setErrorFlag={setErrorFlag}
        />

        <RadioGroupElement
          title="Required/Optional"
          name="required"
          disabled={false}
          required
          value={value.required}
          items={requiredOption}
          description="Please specify whether this field can be left as empty"
          handleChange={handleValueChange}
          setErrorFlag={setErrorFlag}
        />
      </div>
    </ColumnLayout>
  );
}

MetaBasicInfoBlock.propTypes = {
  handleValueChange: PropTypes.func.isRequired,
  setErrorFlag: PropTypes.func.isRequired,

  value: PropTypes.shape({
    templateName: PropTypes.string,
    required: PropTypes.oneOf(['true', 'false']),
    isHeader: PropTypes.oneOf(['true', 'false']),
    isKey: PropTypes.oneOf(['true', 'false']),
    columnName: PropTypes.string,
    columnDescription: PropTypes.string,
  }).isRequired,
};

MetaBasicInfoBlock.defaultProps = {};

export default MetaBasicInfoBlock;
