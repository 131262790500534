import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'actions';
import { LoadingSpinner } from 'common/components';
import PropTypes from 'prop-types';
import CustomProps from 'common/prop-types';

import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ReactTooltip from 'react-tooltip';


import MJEBanner from 'common/components/mjeBanner/MJEBanner';
import {
  Button, Modal, RadioButton, Select, Popover, Icon,
} from '@amzn/awsui-components-react';
import '@amzn/awsui-components-react/index.css';
import styles from './Reports.scss';

const RADIO_BUTTON_COLUMN_ID = 999;

const reportTypes = [
  {
    type_id: 1,
    type_name: 'VT Manual Journal-GL Data',
  },
  {
    type_id: 2,
    type_name: 'VAT MJE Attributes Report',
  },
  {
    type_id: 3,
    type_name: 'AMZ VT Month End Manually Submitted Journals',
  },
  {
    type_id: 4,
    type_name: 'AMZ VT Invoice Report',
  },
  {
    type_id: 5,
    type_name: 'AMZ VT MJE Cost Plus Trending Report',
  },
  {
    type_id: 6,
    type_name: 'AMZ India Sales Register Report',
  },
  {
    type_id: 7,
    type_name: 'AMZ Tax MJE GL Report',
  },
];

let columnItems = [
  {
    Header: '#',
    id: 0,
    width: 120,
    accessor: 'type_id',
    headerClassName: styles.leftAlignContent,
    filterable: false,
  },
  {
    Header: 'Report',
    accessor: 'type_name',

    headerClassName: styles.leftAlignContent,
  },
];

export class ReportsComponent extends React.Component {
    static propTypes = {
      runningReport: PropTypes.bool,
      reportSuccess: PropTypes.bool,
      successfulReportID: PropTypes.string,
      error: PropTypes.string,
      reportPeriods: PropTypes.arrayOf(PropTypes.string),

      // Redux Functions
      reportRun: PropTypes.func.isRequired,
      reportRunReset: PropTypes.func.isRequired,
      getReportPeriods: PropTypes.func,

      // Router Props
      history: CustomProps.history(),
    };

    static defaultProps = {
      getReportPeriods: () => { },
      history: {},
      runningReport: false,
      reportSuccess: false,
      successfulReportID: '',
      reportPeriods: [],
      error: '',
    };

    constructor(props) {
      super(props);

      this.state = {
        selectedReport: null,
        selectedPeriod: null,
      };
      this.buildMyDashboardHeaderColumns = this.buildMyDashboardHeaderColumns.bind(this);
      this.selectReport = this.selectReport.bind(this);
      this.selectPeriod = this.selectPeriod.bind(this);
      this.handleRunClick = this.handleRunClick.bind(this);
      this.handleSuccessModalDismiss = this.handleSuccessModalDismiss.bind(this);
      this.handleSuccessModalHistoryClick = this.handleSuccessModalHistoryClick.bind(this);
    }

    componentDidMount() {
      const { getReportPeriods } = this.props;
      getReportPeriods();
    }

    selectReport(reportType) {
      this.setState(() => ({ selectedReport: reportType }));
    }

    selectPeriod(selectedPeriod) {
      this.setState(() => ({ selectedPeriod }));
    }

    buildMyDashboardHeaderColumns() {
      const { selectedReport } = this.state;
      if (columnItems.find(x => x.id === RADIO_BUTTON_COLUMN_ID) != null) {
        columnItems = columnItems.filter(x => x.id !== RADIO_BUTTON_COLUMN_ID);
      }
      columnItems = [
        ...columnItems,
        {
          id: RADIO_BUTTON_COLUMN_ID,
          Header: 'Select Report',
          Cell: row => (
            <RadioButton
              type="radio"
              className="awsui-util-ml-m"
              value={row.original.type_id.toString()}
              label=""
              name="typeSelector"
              onChange={() => this.selectReport(row.original.type_id)}
              checked={
                selectedReport != null
                && selectedReport === row.original.type_id
              }
            />
          ),
          sortable: false,
          filterable: false,
          width: 200,
        },
      ];
    }

    handleRunClick() {
      const { reportRun } = this.props;
      const { selectedReport, selectedPeriod } = this.state;
      reportRun(reportTypes[selectedReport - 1], selectedPeriod);
    }

    handleSuccessModalDismiss() {
      const { reportRunReset } = this.props;
      this.setState({
        selectedReport: null,
        selectedPeriod: null,
      });
      reportRunReset();
    }

    handleSuccessModalHistoryClick() {
      const { reportRunReset, history } = this.props;
      reportRunReset();
      history.push('/MJE/Reports/History');
    }

    render() {
      const bannerText = 'MJE 1.0 - Available Reporting';
      const { selectedReport, selectedPeriod } = this.state;
      const {
        history, runningReport, reportSuccess, successfulReportID, reportPeriods,
      } = this.props;

      let reportPeriodOptions = [];
      if (reportPeriods != null) {
          for (let i = 0; i < reportPeriods.length; i++) {
              reportPeriodOptions.push({
                  id: i,
                  label: reportPeriods[i],
              });
          }
      }

      this.buildMyDashboardHeaderColumns();
      return (
        <div className={styles.wrapper}>
          <MJEBanner bannerText={bannerText} />
          <br />
          <div className="awsui-util-container">
            <div className="awsui-util-container-header">
              <h2 className="awsui-util-font-size-3">Choose MJE Report</h2>
            </div>
            <div data-awsui-column-layout-root>
              <ReactTable
                data={reportTypes}
                minRows={0}
                columns={columnItems}
                sortable
                wrapLines={false}
                resizableColumns
                stickyHeader
                showPagination={false}
                noMatch="noMatchContent"
              />
            </div>
            <div className="awsui-util-action-stripe">
              <div className="awsui-util-action-stripe-title">
                <Button onClick={() => history.push('/')}>
                    Go Back
                </Button>
                <Button onClick={() => history.push('/MJE/Reports/History')}>
                    History
                </Button>
              </div>
              <div className={`awsui-util-action-stripe-group ${styles.runActionWrapper}`}>
                <Select
                  loading={reportPeriodOptions.length < 1}
                  options={reportPeriodOptions}
                  selectedOption={selectedPeriod}
                  placeholder="Select Period"
                  onChange={e => this.selectPeriod(e.detail.selectedOption)}
                />
                <Button
                  text="Run Report"
                  data-tip="Must Select Report and Report Period To Run"
                  onClick={this.handleRunClick}
                  disabled={!(selectedReport && selectedPeriod)}
                  variant="primary"
                />
                {!(selectedReport && selectedPeriod)
                  ? <ReactTooltip place="bottom" type="dark" effect="solid" event="mouseover" eventOff="mouseout" />
                  : <></>
                }
                <LoadingSpinner loading={runningReport} text="Fetching Report ID..." />
                <Modal
                  visible={reportSuccess}
                  size="small"
                  header="Report Running"
                  footer={(
                    <div className="awsui-util-f-r">
                      <Button text="History" variant="primary" onClick={this.handleSuccessModalHistoryClick} />
                    </div>
                      )}
                  onDismiss={this.handleSuccessModalDismiss}
                >
                  {selectedReport && reportTypes[selectedReport - 1].type_name}
                  {' '}
successfully initiated with reportID: &nbsp;
                <span className="awsui-util-copy-text">
                  <Popover
                      size="small"
                      position="top"
                      triggerType="custom"
                      dismissButton={false}
                      content={
                          <span className="awsui-util-status-positive">
                              <Icon variant="success" name="status-positive" /> Report ID Copied
                          </span>
                      }>
                      <Button icon="copy" variant="icon" style={{ color: 'blue', textDecoration: 'underline' }} label={successfulReportID} onClick={() => navigator.clipboard.writeText(successfulReportID)}/>
                  </Popover>
                  <span className="report-id" style={{ color: 'blue', textDecoration: 'underline' }}>{successfulReportID}</span>
                </span>
.
                    Please reference your reporting history for report availability.
                </Modal>
              </div>
            </div>
          </div>
        </div>
      );
    }
}

const mapStateToProps = (state) => {
  const {
    runningReport,
    reportSuccess,
    successfulReportID,
    error,
    reportPeriods,
  } = state.reports;
  return {
    runningReport,
    reportSuccess,
    successfulReportID,
    error,
    reportPeriods,
  };
};

const mapDispatchToProps = {
    getReportPeriods: actions.getReportPeriods,
    reportRun: actions.reportRun,
    reportRunReset: actions.reportRunReset,
};

const ReportsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsComponent);

export default withRouter(ReportsContainer);
