import React from 'react';
import PropTypes from 'prop-types';
import Logger from 'utils/logger';

class ErrorBoundary extends React.Component {
  static propTypes = {
    content: PropTypes.node,
    children: PropTypes.node,
  };

  static defaultProps = {
    content: null,
    children: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };

    this.componentDidCatch = this.componentDidCatch.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    Logger.logError(error, errorInfo);
  }

  render() {
    const { content, children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div>
          Something went wrong.
          {content}
          related content should have been displayed here.
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
