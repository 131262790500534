import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import CreateMetaData from 'pages/mje/CreateMetaData';
import QueryMetaData from 'pages/mje/QueryMetaData';
import UpdateMetaData from 'pages/mje/UpdateMetaData';
import QueryJE from 'pages/mje/QueryMJE';
import FileUpload from 'pages/mje/FileUpload';
import PrimeJournalSummary from 'pages/PrimeJournalSummary';
import PrimePostPage from 'pages/primePostPage';
import ValidationUI from 'pages/validationUI';
import LandingPage from 'pages/LandingPage';
import ReportsPage from 'pages/Reports'
import ReportsHistoryPage from 'pages/ReportsHistory'

function ICMJEApp() {
  return (
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/MJE/CreateMetaData" component={CreateMetaData} />
      <Route exact path="/MJE/QueryMetaData" component={QueryMetaData} />
      <Route exact path="/MJE/UpdateMetaData" component={UpdateMetaData} />
      <Route exact path="/MJE/FileUpload" component={FileUpload} />
      <Route exact path="/MJE/QueryMJE" component={QueryJE} />
      <Route exact path="/MJE/PrimeJournalSummary/:requestId" component={PrimeJournalSummary} />
      <Route exact path="/MJE/Validation/:requestType/:requestId" component={ValidationUI} />
      <Route exact path="/MJE/PostSuccess" component={PrimePostPage} />
      <Route exact path="/MJE/Reports" component={ReportsPage} />
      <Route exact path="/MJE/Reports/History" component={ReportsHistoryPage} />
      <Redirect exact path="/index.html" to="/" />
      <Route exact path="/MJE1_1" component={LandingPage} />
      <Route exact path="/MJE1_1/Validation/:requestType/:requestId" component={ValidationUI} />
      <Route exact path="/MJE1_1/PostSuccess" component={PrimePostPage} />
    </Switch>
  );
}

ICMJEApp.propTypes = {};

ICMJEApp.defaultProps = {};

export default ICMJEApp;
