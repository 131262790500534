import React from 'react';
import PropTypes from 'prop-types';
import {
  ColumnLayout, Table, Input, Button,
} from '@amzn/awsui-components-react';
import '../ValidationUI.scss';
import ReactTooltip from 'react-tooltip';
import FORMATTERS from 'utils/formatUtils';


const statsTopColumns = [
  {
    id: 'numJournalBatches',
    header: '#Journal Batches',
    width: 139,
  },
  {
    id: 'numJournals',
    header: '#Journals',
    width: 135,
  },
  {
    id: 'numAutomatedLines',
    header: '#Automated Lines',
    width: 152,
  },
  {
    id: 'numErrorRows',
    header: '#Error Rows',
    width: 114,
  },
  {
    id: 'numErrors',
    header: '#Errors',
    width: 112,
  },
];

const statsBottomColumns = [
  {
    id: 'accountingDate',
    header: 'Accounting Date',
    width: 139,
  },
  {
    id: 'netEntered',
    header: 'Entered Amount',
    width: 135,
  },
  {
    id: 'netAccounted',
    header: 'Accounted Amount',
    width: 152,
  },
  {
    id: 'numAgreements',
    header: '#Agreements',
    width: 114,
  },
  {
    id: 'numCurrencies',
    header: '#Currencies',
    width: 112,
  },
];

class ValidationUIStatistics extends React.Component {
  static propTypes = {
    workbookName: PropTypes.string,
    canEditWorkbookName: PropTypes.bool,
    isSaving: PropTypes.bool,
    statistics: PropTypes.shape({
      numJournalBatches: PropTypes.number,
      numJournalLines: PropTypes.number,
      numErrorRows: PropTypes.number,
      numErrors: PropTypes.number,
      numAutomatedLines: PropTypes.number,
      accountingDate: PropTypes.string,
      netEntered: PropTypes.number,
      netAccounted: PropTypes.number,
      numAgreements: PropTypes.number,
      numCurrencies: PropTypes.number,
    }),
    onClickSave: PropTypes.func,
  };

  static defaultProps = {
    workbookName: '',
    canEditWorkbookName: false,
    isSaving: false,
    statistics: {},
    onClickSave: () => { },
  };

  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      workbookName: props.workbookName,
      prevWorkbookName: props.workbookName,
    };
    this.onClickEdit = this.onClickEdit.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.workbookName !== state.prevWorkbookName) {
      return {
        ...state,
        workbookName: props.workbookName,
        prevWorkbookName: props.workbookName,
        isEditing: false,
      };
    }
    return null;
  }

  onClickEdit() {
    this.setState({
      isEditing: true,
    });
  }

  onClickSave() {
    const { onClickSave } = this.props;
    const { workbookName } = this.state;
    onClickSave(workbookName);
  }

  onClickCancel() {
    this.setState(state => ({
      isEditing: false,
      workbookName: state.prevWorkbookName,
    }));
  }

  onInputChange(event) {
    this.setState({
      workbookName: event.target.component.value,
    });
  }

  renderHeader() {
    const { isEditing, workbookName } = this.state;
    const { canEditWorkbookName, isSaving } = this.props;
    const workbookNameIsBlank = workbookName == null || workbookName === '' || workbookName.trim() === '';
    if (isEditing) {
      return (
        <div className="awsui-grid">
          <div className="awsui-row">
            <div className="col-8">
              <Input
                value={workbookName}
                autocomplete={false}
                disableBrowserAutocorrect
                onChange={this.onInputChange}
                onKeyup={this.onInputChange}
                className="awsui-util-shadow upload-name"
                disabled={isSaving}
              />
            </div>
            <div className="col-4">
              <Button
                text="Cancel"
                className="awsui-util-f-r awsui-util-ml-s"
                onClick={this.onClickCancel}
                disabled={isSaving}
              />
              <Button
                text={isSaving ? 'Saving...' : 'Save'}
                variant="primary"
                className="awsui-util-f-r"
                onClick={this.onClickSave}
                disabled={isSaving || workbookNameIsBlank}
              />
            </div>
          </div>
        </div>
      );
    }
    return (canEditWorkbookName
      ? (
        <div className="awsui-grid">
          <div className="awsui-row">
            <div className="col-11">
              <Input value={workbookName} readonly className="awsui-util-shadow upload-name" />
            </div>
            <div className="col-1">
              <Button
                variant="icon"
                icon="edit"
                onClick={this.onClickEdit}
                data-tip="Update Loaded Template Name"
                data-for="upload-edit"
              />
              <ReactTooltip
                id="upload-edit"
                place="bottom"
                type="dark"
                effect="solid"
                event="mouseenter"
                eventOff="mouseleave"
              />
            </div>
          </div>
        </div>
      )
      : (
        <div className="awsui-grid">
          <div className="awsui-row">
            <div className="col-12">
              <Input value={workbookName} readonly className="awsui-util-shadow upload-name" data-for="no-editable-message" data-tip="Upload name cannot be modified because one of the journals are processed" />
              <ReactTooltip
                id="no-editable-message"
                place="bottom"
                type="dark"
                effect="solid"
                event="mouseenter"
                eventOff="mouseleave"
              />
            </div>
          </div>
        </div>
      )
    );
  }

  render() {
    const columnToColumnDefinition = column => ({
      ...column,
      cell: (entry) => {
        const value = column.id === 'netEntered' || column.id === 'netAccounted'
          ? FORMATTERS.currencyTextFormatter(entry[column.id])
          : entry[column.id];
        return <div className="awsui-util-t-l">{value}</div>;
      },
    });
    const topColumnDefinitions = statsTopColumns.map(columnToColumnDefinition);
    const bottomColumnDefinitions = statsBottomColumns.map(columnToColumnDefinition);

    const { statistics } = this.props;
    return (
      <div>
        {this.renderHeader()}
        <div className="awsui-util-container awsui-util-mt-s">
          <div className="awsui-util-container-header">
            <h4>Upload Statistics</h4>
          </div>
          <ColumnLayout className="statistics-tables awsui-util-p-s">
            <div data-awsui-column-layout-root="true">
              <Table
                columnDefinitions={topColumnDefinitions}
                items={[statistics]}
                wrapLines={false}
                className="statistics-top-table"
              />
              <Table
                columnDefinitions={bottomColumnDefinitions}
                items={[statistics]}
                wrapLines={false}
              />
            </div>
          </ColumnLayout>
        </div>
      </div>
    );
  }
}

export default ValidationUIStatistics;
