// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pages-ReportsHistory-ReportsHistory__wrapper--2QVAt .pages-ReportsHistory-ReportsHistory__divider--3Ox-W{background:#d5dbdb;display:block;clear:both;width:100%;min-width:100%;height:.06em}.pages-ReportsHistory-ReportsHistory__wrapper--2QVAt .pages-ReportsHistory-ReportsHistory__centerAlignContent--2506_{text-align:center}.pages-ReportsHistory-ReportsHistory__wrapper--2QVAt .pages-ReportsHistory-ReportsHistory__leftAlignContent--2pd3v{text-align:left}.pages-ReportsHistory-ReportsHistory__wrapper--2QVAt .pages-ReportsHistory-ReportsHistory__downloadActionWrapper--12cD7 .awsui-select-keyboard-area{width:14em}.pages-ReportsHistory-ReportsHistory__wrapper--2QVAt .pages-ReportsHistory-ReportsHistory__filterInput--2UH8o::placeholder{font-style:italic}.pages-ReportsHistory-ReportsHistory__wrapper--2QVAt .ReactTable .rt-tbody .rt-td:last-child{overflow:visible !important}.pages-ReportsHistory-ReportsHistory__wrapper--2QVAt .ReactTable .rt-tbody{min-height:4em !important}.pages-ReportsHistory-ReportsHistory__wrapper--2QVAt .ReactTable .rt-noData{margin-top:1em;background:rgba(255,255,255,0);color:#555}.pages-ReportsHistory-ReportsHistory__wrapper--2QVAt .ReactTable .rt-th{text-align:left}.pages-ReportsHistory-ReportsHistory__wrapper--2QVAt .button-active .awsui-button-icon{color:var(--awsui-color-background-button-primary-default)}", ""]);
// Exports
exports.locals = {
	"wrapper": "pages-ReportsHistory-ReportsHistory__wrapper--2QVAt",
	"divider": "pages-ReportsHistory-ReportsHistory__divider--3Ox-W",
	"centerAlignContent": "pages-ReportsHistory-ReportsHistory__centerAlignContent--2506_",
	"leftAlignContent": "pages-ReportsHistory-ReportsHistory__leftAlignContent--2pd3v",
	"downloadActionWrapper": "pages-ReportsHistory-ReportsHistory__downloadActionWrapper--12cD7",
	"filterInput": "pages-ReportsHistory-ReportsHistory__filterInput--2UH8o"
};
module.exports = exports;
