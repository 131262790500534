import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import '@amzn/awsui-components-react/index.css';
import {
  Button, ColumnLayout, FormField, FormSection,
} from '@amzn/awsui-components-react';
import { CustomTileElement } from 'common/components/dataEntry';

class StepOne extends React.Component {
  static propTypes = {
    handleQueryTemplateClick: PropTypes.func.isRequired,

    value: PropTypes.string,
  };

  static defaultProps = {
    value: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      templateName: '',
    };
  }

  render() {
    const { value, handleQueryTemplateClick } = this.props;
    const { templateName } = this.state;

    // TODO: replace the hard coded template name with dynamically fetching from meta data table
    return (
      <div>
        <FormSection className="custom-screenshot-hide" header="Step 1 - Choose your MJE template">
          <ColumnLayout columns={1}>
            <div data-awsui-column-layout-root>
              <FormField
                label="Template type*"
                description="Please choose a type for the data defined. Validation will be enforced accordingly when uploading/inputting real data in future."
              >
                <ColumnLayout columns={2}>
                  <div data-awsui-column-layout-root>
                    <CustomTileElement
                      title="ICE MJE Template"
                      selected={value}
                      disabled={false}
                      label="ICE_MJE"
                      description="The template for ICE manual journal entry"
                      handleClick={() => this.setState({ templateName: 'ICE_MJE' })}
                    />
                    <CustomTileElement
                      title="MJE Testing Template"
                      selected={value}
                      disabled={false}
                      label="TEST_MJE"
                      description="The testing template for manual journal entry"
                      handleClick={() => this.setState({ templateName: 'TEST_MJE' })}
                    />
                  </div>
                </ColumnLayout>
              </FormField>
            </div>
          </ColumnLayout>
        </FormSection>

        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-title" />
          <div className="awsui-util-action-stripe-group">
            <Button
              text="Next"
              variant="primary"
              icon="angle-right"
              iconAlign="right"
              className="awsui-util-mb-xl"
              type="submit"
              onClick={() => handleQueryTemplateClick(templateName)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(StepOne);
