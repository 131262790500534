import React from 'react';
import PropTypes from 'prop-types';
import '@amzn/awsui-components-react/index.css';
import { Icon } from '@amzn/awsui-components-react';
import ErrorBoundary from 'common/components/err/errorBoundary';

class TwoKeyValuePairSingleVertical extends React.Component {
  static propTypes = {
    titleOne: PropTypes.string,
    titleTwo: PropTypes.string,
    statusOne: PropTypes.string,
    statusTwo: PropTypes.string,
    valueOne: PropTypes.string,
    valueTwo: PropTypes.string,
  };

  static defaultProps = {
    titleOne: '',
    titleTwo: '',
    statusOne: '',
    statusTwo: '',
    valueOne: '',
    valueTwo: '',
  };

  static getValueContent(status, value) {
    if (status === 'positive') {
      return (
        <div className="awsui-util-status-positive">
          <Icon name="status-positive" />
          {' '}
          {value}
        </div>
      );
    }
    if (status === 'negative') {
      return (
        <div className="awsui-util-status-negative">
          <Icon name="status-negative" />
          {' '}
          {value}
        </div>
      );
    }
    return <div>{value}</div>;
  }

  render() {
    const {
      titleOne, titleTwo, statusOne, statusTwo, valueOne, valueTwo,
    } = this.props;

    return (
      <ErrorBoundary content={`${titleOne} and ${titleTwo}`}>
        <div>
          <div className="awsui-util-spacing-v-s">
            <div>
              <div className="awsui-util-label">{titleOne}</div>
              {TwoKeyValuePairSingleVertical.getValueContent(statusOne, valueOne)}
            </div>
            <div>
              <div className="awsui-util-label">{titleTwo}</div>
              {TwoKeyValuePairSingleVertical.getValueContent(statusTwo, valueTwo)}
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

export default TwoKeyValuePairSingleVertical;
