import * as CONST from 'common/const';
import api from 'utils/api';

export const fetchSearchableAttributesStart = () => ({
  type: CONST.SEARCH_MODAL_LOAD_START,
});

export const fetchSearchableAttributesSuccess = (data = {}) => ({
  type: CONST.SEARCH_MODAL_LOAD_SUCCESS,
  payload: data,
});

export const fetchSearchableAttributesFailure = (error = null) => ({
  type: CONST.SEARCH_MODAL_LOAD_FAILURE,
  payload: error,
});

export const fetchSearchableAttributes = () => async (dispatch /* , getState */) => {
  dispatch(fetchSearchableAttributesStart());
  try {
    const result = await api.loadSearchModal();
    dispatch(fetchSearchableAttributesSuccess(result));
  } catch (e) {
    dispatch(fetchSearchableAttributesFailure(e));
  }
};
