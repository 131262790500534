import React from 'react';
import PropTypes from 'prop-types';
import { FormField, Input } from '@amzn/awsui-components-react';
import ErrorBoundary from 'common/components/err/errorBoundary';
import '@amzn/awsui-components-react/index.css';
import 'antd/dist/antd.css';
import 'index.css';

// Component for decimal input
class DecimalElement extends React.Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    setErrorFlag: PropTypes.func.isRequired,

    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.string,

    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,

    required: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    name: '',
    title: '',
    description: '',
    value: '',

    required: false,
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      errorText: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { errorText } = this.state;
    const {
      required, max, min, name, handleChange, setErrorFlag,
    } = this.props;
    const inputValue = event.target.component.value;

    if (inputValue === '') {
      if (required) this.setState({ errorText: 'This field is required.' });
    } else if (Number.isNaN(inputValue)) {
      this.setState({ errorText: `${inputValue} is not a valid decimal` });
    } else if (max !== undefined && Number(inputValue) > Number(max)) {
      this.setState({ errorText: `Only decimal <= ${max} are allowed` });
    } else if (min !== undefined && Number(inputValue) < Number(min)) {
      this.setState({ errorText: `Only decimal >= ${min} are allowed` });
    } else this.setState({ errorText: '' });
    handleChange(event.target.component.name, inputValue);
    setErrorFlag(name, errorText);
  }

  render() {
    const { errorText } = this.state;
    const {
      title, required, description, name, value, disabled,
    } = this.props;

    return (
      <ErrorBoundary content={title}>
        <FormField
          label={title + (required ? '*' : '')}
          description={description}
          errorText={errorText}
        >
          <Input
            name={name}
            value={value}
            onChange={this.handleChange}
            placeholder={this.placeholder}
            disabled={disabled}
          />
        </FormField>
      </ErrorBoundary>
    );
  }
}

export default DecimalElement;
