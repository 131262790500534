import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from 'actions';
import CustomProps from 'common/prop-types';
import {
  Modal,
  ColumnLayout,
  Input,
  Select,
  Button,
  DatePicker,
  Alert,
  FormField,
  Icon,
  Spinner,
} from '@amzn/awsui-components-react';
import ReactTooltip from 'react-tooltip';
import '@amzn/awsui-components-react/index.css';
import CONSTANT from 'utils/constant';
import styles from './SearchModal.scss';
import { getFromLocal, getFromSession, putInLocal, removeFromSession, putInSession } from "../../../utils/storage";
import { getSearchPayload } from "../../../actions";

const NewSupportTicketUrl = process.env.NEW_SUPPORT_TICKET_URL;

const secondaryAttribEmptyOption = {
  label: 'Select Other Attributes',
  id: 'none',
};

const errorMessages = {
  emptyFormMsg: 'Please enter at least one search condition.',
  missingStartDateMsg: 'Please enter an accounting start date.',
  missingEndDateMsg: 'Please enter an accounting end date.',
  endDateLesserThanStartMsg:
    'Please enter an accounting end date greater than or equal to the start date.',
  missingDynamicAttributeMsg: 'Please select an attribute.',
  missingDynamicAttributeValueMsg: 'Please enter a value for (0).',
};

class SearchModal extends React.Component {
  static handleCreateTicket() {
    window.open(NewSupportTicketUrl, '_blank');
  }

  static propTypes = {
    handleSearchGo: PropTypes.func.isRequired,
    handleSearchClose: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,

    // Redux Functions
    fetchSearchableAttributes: PropTypes.func,

    error: PropTypes.string,
    primaryAttributes: PropTypes.arrayOf(CustomProps.SearchableAttributeItem),
    secondaryAttributes: PropTypes.arrayOf(CustomProps.SearchableAttributeItem),
  };

  static defaultProps = {
    fetchSearchableAttributes: () => { },
    error: '',
    primaryAttributes: [],
    secondaryAttributes: [],
  };

  constructor(props) {
    super(props);
    const loadedStateContainer = getFromSession("searchModalState");
    this.state = loadedStateContainer && loadedStateContainer.state || this.getSearchModalState();
    this.validate = this.validate.bind(this);
    this.setError = this.setError.bind(this);
    this.isInvalidDynamicAttribute = this.isInvalidDynamicAttribute.bind(this);
    this.isInvalidAccountingDateRange = this.isInvalidAccountingDateRange.bind(this);
    this.handleSearchSave = this.handleSearchSave.bind(this);
    this.handleSearchClear = this.handleSearchClear.bind(this);
    this.handleSavedSearchDelete = this.handleSavedSearchDelete.bind(this);
    this.handleSavedSearchSelect = this.handleSavedSearchSelect.bind(this);
  }

  componentDidMount() {
    const { fetchSearchableAttributes } = this.props;
    fetchSearchableAttributes();
  }

  getSearchModalState = () => {
    return {
      searchItems: {
        journal_batch_name: '',
        journal_name: '',
        ic_transaction_id: '',
        company: '',
        account: '',
        agreement_line_num: '',
        prepare_user: '',
        approve_user: '',
        ledger: '',
      },
      dynamicAttributeValue: '',
      dynamicAttribute: secondaryAttribEmptyOption,
      accountingStartDate: '',
      accountingEndDate: '',
      errors: {
        emptyFormError: '',
        startDateError: '',
        endDateError: '',
        dynamicAttributeError: '',
        dynamicAttributeValueError: '',
      },
      selectedSearch: '',
    }
  };

  handleChange = (e, item) => {
    let prevSearchItems = this.state.searchItems;
    prevSearchItems[item.id] = e.detail["value"];

    this.setState({
      searchItems: prevSearchItems,
    });
  }

  handleDateChange = (e, startDate = false) => {
    if (startDate) {
      this.setState({
        accountingStartDate: e.detail["value"],
      });
    } else {
      this.setState({
        accountingEndDate: e.detail["value"],
      });
    }
  }

  handleDynamicAttributeValueChange = (e) => {
    this.setState({
      dynamicAttributeValue: e.detail["value"],
    });
  }

  handleSearchSave = () => {
    let invalidForm = this.isInvalidAccountingDateRange();
    invalidForm = this.isInvalidDynamicAttribute() || invalidForm;
    if (!invalidForm) {
      let searches = getFromLocal("searches")
      if (!searches || searches === null || searches === undefined) {
        searches = [];
      }

      let searchCount = searches.length + 1;
      let searchTitle = "Search " + searchCount;
      this.state.selectedSearch = searchTitle;
      let search = {
        title: searchTitle,
        state: this.state,
      }

      searches.push(search);
      putInLocal("searches", searches);
    }

    this.validate();
  }

  handleSearchClear = () => {
    removeFromSession("searchModalState");
    this.setState(this.getSearchModalState);
  }

  handleSavedSearchDelete = () => {
    const { selectedSearch } = this.state;
    if (!selectedSearch) {
      return;
    }

    let searches = getFromLocal("searches")
    if (!searches || searches === null || searches === undefined) {
      return;
    }

    for (let index = 0; index < searches.length; index++) {
      if (searches[index] !== null && searches[index].title === selectedSearch && searches[index].state) {
        searches = searches.filter(item => item && item.title !== null && item.title !== selectedSearch)
      }
    }

    putInLocal("searches", searches);
    removeFromSession("searchModalState");
    this.setState(this.getSearchModalState);
  }

  handleSavedSearchSelect = (e) => {
    let searches = getFromLocal("searches")
    if (!searches || searches === null || searches === undefined) {
      return;
    }

    let selectedTitle = e.target.outerText;
    for (let index = 0; index < searches.length; index++) {
      if (searches[index] !== null && searches[index].title === selectedTitle && searches[index].state) {
        this.setState({
          ...searches[index].state,
          selectedSearch: selectedTitle,
        });
      }
    }
    return;
  }

  getSearchFieldDisplay(item) {
    if (!item.dateRange) {
      return (
        <React.Fragment key={item.id}>
          <div key={`${item.id}_label`}>
            <span>{item.label}</span>
          </div>
          <div key={`${item.id}_value`}>
            <Input
              id={item.id}
              value={this.state.searchItems[item.id]}
              onChange={(e) => {
                this.clearFormErrors('emptyFormError');
                this.handleChange(e, item);
              }}
              placeholder="Enter"
              autocomplete={false}
            />
          </div>
        </React.Fragment>
      );
    }
    return '';
  }

  getDateRangeDisplay(item) {
    if (item.dateRange) {
      const { errors } = this.state;
      return (
        <React.Fragment key={item.id}>
          <div key={`${item.id}_label`}>
            <span>
              {item.label}
              &nbsp;&nbsp;
              <span className="awsui-util-status-info">
                <Icon
                  name="status-info"
                  data-tip="Please enter valid date range with starting date at or after August 17th 2020"
                />
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  effect="solid"
                  event="mouseover"
                  eventOff="mouseout"
                />
              </span>
            </span>
          </div>
          <div key={`${item.id}_value`}>
            <ColumnLayout columns={2}>
              <div data-awsui-column-layout-root="true">
                <FormField errorText={errors.startDateError} hintText="YYYY/MM/DD">
                  <div>
                    <DatePicker
                      id="accountingStartDate"
                      value={this.state.accountingStartDate}
                      onChange={(e) => {
                        this.clearFormErrors('emptyFormError', 'startDateError', 'endDateError');
                        this.handleDateChange(e, true);
                      }}
                      placeholder="Start"
                    />
                  </div>
                </FormField>
                <FormField errorText={errors.endDateError} hintText="YYYY/MM/DD">
                  <div>
                    <DatePicker
                      id="accountingEndDate"
                      value={this.state.accountingEndDate}
                      onChange={(e) => {
                        this.clearFormErrors('emptyFormError', 'startDateError', 'endDateError');
                        this.handleDateChange(e, false);
                      }}
                      placeholder="End"
                    />
                  </div>
                </FormField>
              </div>
            </ColumnLayout>
          </div>
        </React.Fragment>
      );
    }
    return '';
  }

  setError(errorKey, errorMessage) {
    this.setState((prevState) => {
      const { errors } = prevState;
      errors[errorKey] = errorMessage;
      return { errors };
    });
  }

  isInvalidDynamicAttribute() {
    const { dynamicAttribute, dynamicAttributeValue, searchItems } = this.state;
    const dynamicAttributeID = dynamicAttribute.id;

    if (dynamicAttributeID !== 'none' && dynamicAttributeValue === '') {
      let emptyForm = true;
      Object.keys(searchItems).forEach((key) => {
        if (searchItems[key] !== '') {
          emptyForm = false;
        }
      });
      if (emptyForm) {
        this.setError(
          'dynamicAttributeValueError',
          errorMessages.missingDynamicAttributeValueMsg.replace('(0)', dynamicAttribute.label),
        );
        return true;
      }
      return false;
    }

    if (dynamicAttributeID === 'none' && dynamicAttributeValue !== '') {
      this.setError('dynamicAttributeError', errorMessages.missingDynamicAttributeMsg);
      return true;
    }
    return false;
  }

  isInvalidAccountingDateRange() {
    const { accountingStartDate, accountingEndDate } = this.state;

    if (accountingStartDate === '' && accountingEndDate === '') {
      this.setError('startDateError', errorMessages.missingStartDateMsg);
      this.setError('endDateError', errorMessages.missingEndDateMsg);
      return true;
    }

    if (accountingStartDate === '' && accountingEndDate !== '') {
      this.setError('startDateError', errorMessages.missingStartDateMsg);
      return true;
    }
    if (accountingStartDate !== '' && accountingEndDate === '') {
      this.setError('endDateError', errorMessages.missingEndDateMsg);
      return true;
    }
    if (accountingStartDate !== '' && accountingEndDate !== '') {
      const startDate = new Date(accountingStartDate);
      const endDate = new Date(accountingEndDate);
      if (endDate < startDate) {
        this.setError('endDateError', errorMessages.endDateLesserThanStartMsg);
        return true;
      }
    }
    return false;
  }

  validate() {
    let invalidForm = this.isInvalidAccountingDateRange();
    invalidForm = this.isInvalidDynamicAttribute() || invalidForm;
    if (!invalidForm) {
      const { searchItems, accountingStartDate, accountingEndDate, dynamicAttribute, dynamicAttributeValue } = this.state;
      const { primaryAttributes } = this.props;
      const searchPayload = getSearchPayload(searchItems, accountingStartDate, accountingEndDate, dynamicAttribute, dynamicAttributeValue, primaryAttributes);
      const { handleSearchGo } = this.props;
      putInSession("searchModalState", {
        state: this.state,
        primaryAttributes: primaryAttributes
      });
      handleSearchGo(searchPayload);
    }
  }

  clearFormErrors(...args) {
    this.setState((prevState) => {
      const { errors } = prevState;
      args.forEach((arg) => {
        errors[arg] = '';
      });
      return { errors };
    });
  }

  updateSearchField(key, value, dynamic) {
    this.setState((prevState) => {
      const newValue = typeof value === 'string' ? value.trim() : value;
      if (dynamic) {
        return { [key]: newValue };
      }
      const { searchItems } = prevState;
      searchItems[key] = newValue;
      return { searchItems };
    });
  }

  loadSavedSearches() {
    const savedSearches = getFromLocal("searches");
    let searches = [];
    if (savedSearches) {
      for (let index = 0; index < savedSearches.length; index++) {
        if (savedSearches[index] !== null) {
          searches.push({
            id: savedSearches[index].title,
            label: savedSearches[index].title
          });
        }
      }
    }
    return searches;
  }

  render() {
    const { errors, dynamicAttribute, selectedSearch } = this.state;
    const {
      error, primaryAttributes, secondaryAttributes, handleSearchClose, visible,
    } = this.props;
    const searches = this.loadSavedSearches();
    const selectedSearchOption = {
      id: selectedSearch,
      label: selectedSearch ? selectedSearch : "Select An Option",
    }

    return (
      <div className={styles.searchModalWrapper}>
        <Modal
          content={(
            <div className={styles.searchContainer}>
              <h3>Search</h3>
              <Alert header="Error" buttonText="Create Support Ticket" onButtonClick={this.handleCreateTicket} type="error" visible={error !== ''}>
                <div className="awsui-util-d-ib">
                  {CONSTANT.GENERIC_ERROR_MESSAGE}
                </div>
              </Alert>
              {error === '' && primaryAttributes.length === 0 && secondaryAttributes.length === 0 && <Spinner size="big" />}
              <Alert
                type="error"
                visible={errors.emptyFormError !== ''}
                content={errors.emptyFormError}
              />
              <br />
              {error === '' && primaryAttributes.length > 0 && secondaryAttributes.length > 0
              && (
              <React.Fragment>
                <ColumnLayout columns={2}>
                  <div data-awsui-column-layout-root="true">
                    <div>
                      <span>Saved Searches</span>
                    </div>
                    <div>
                      <Select
                          options={[...searches]}
                          selectedOption={selectedSearchOption}
                          onChange={this.handleSavedSearchSelect}
                      />
                    </div>

                    {primaryAttributes.map(item => this.getSearchFieldDisplay(item))}
                    <div>
                      <FormField errorText={errors.dynamicAttributeError}>
                        <Select
                          value={this.state.dynamicAttribute}
                          options={[secondaryAttribEmptyOption, ...secondaryAttributes]}
                          selectedOption={dynamicAttribute}
                          onChange={(e) => {
                            this.clearFormErrors(
                              'emptyFormError',
                              'dynamicAttributeError',
                              'dynamicAttributeValueError',
                            );
                            this.updateSearchField('dynamicAttribute', e.detail.selectedOption, true);
                          }}
                        />
                      </FormField>
                    </div>
                    <div>
                      <FormField errorText={errors.dynamicAttributeValueError}>
                        <Input
                          id="dynamicAttributeValue"
                          value={this.state.dynamicAttributeValue}
                          onChange={(e) => {
                            this.handleDynamicAttributeValueChange(e);
                            this.clearFormErrors(
                                'emptyFormError',
                                'dynamicAttributeError',
                                'dynamicAttributeValueError',
                            )
                          }}
                          placeholder="Enter"
                          autocomplete={false}
                        />
                      </FormField>
                    </div>
                  </div>
                </ColumnLayout>
                <br />
                <div className={styles.divider} />
                <br />
                <ColumnLayout columns={2}>
                  <div data-awsui-column-layout-root="true">
                    {primaryAttributes.map(item => this.getDateRangeDisplay(item))}
                  </div>
                </ColumnLayout>
              </React.Fragment>
              )
            }
            </div>
          )}
          visible={visible}
          header="Update Dashboard"
          expandToFit
          onDismiss={handleSearchClose}
          footer={(
              <div>
                <span className="awsui-util-f-l">
                  {selectedSearch &&
                    <Button variant="link" text="Delete Search" onClick={this.handleSavedSearchDelete}/>
                  }
                </span>
                <span className="awsui-util-f-r">
                  <Button variant="link" text="Clear" onClick={this.handleSearchClear}/>
                  <Button variant="link" text="Close" onClick={handleSearchClose} />
                  <Button variant="link" text="Save & Go!" onClick={this.handleSearchSave} disabled={primaryAttributes.length === 0 && secondaryAttributes.length === 0} />
                  <Button variant="primary" text="Go!" onClick={this.validate} disabled={primaryAttributes.length === 0 && secondaryAttributes.length === 0} />
                </span>
              </div>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    error,
    primaryAttributes,
    secondaryAttributes,
  } = state.searchModal;
  return {
    error,
    primaryAttributes,
    secondaryAttributes,
  };
};

const mapDispatchToProps = {
  fetchSearchableAttributes: actions.fetchSearchableAttributes,
};

const SearchModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchModal);

export default SearchModalContainer;
