/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Alert, ColumnLayout, Button, Checkbox, Modal,
} from '@amzn/awsui-components-react';
import ReactTable from 'react-table';
import * as actions from 'actions';
import { Header, Body } from 'common/components/Modal/Modal';
import MJEBanner from 'common/components/mjeBanner/MJEBanner';
import ReactTooltip from 'react-tooltip';
import CONSTANT from 'utils/constant';
import CustomProps from 'common/prop-types';
import FORMATTERS from 'utils/formatUtils';
import styles from './PrimePostPage.scss';
import MODALS from './modal';


const summaryColumnDefinitions = [
  {
    id: 'Index',
    Header: '#',
    width: 40,
    Cell: row => <span>{row.index + 1}</span>,
  },
  {
    id: 'AccDate',
    Header: 'Accounting Date',
    width: 127,
    accessor: 'accounting_date',
  },
  {
    id: 'LoadDate',
    Header: 'Load Date',
    width: 120,
    accessor: 'load_date',
  },
  {
    id: 'NetEntered',
    Header: 'Net Entered',
    width: 120,
    accessor: 'net_entered',
    Cell: cellInfo => <span>{FORMATTERS.currencyTextFormatter(cellInfo.value)}</span>,
  },
  {
    id: 'NetAccounted',
    Header: 'Net Accounted',
    width: 117,
    accessor: 'net_accounted',
    Cell: cellInfo => <span>{FORMATTERS.currencyTextFormatter(cellInfo.value)}</span>,
  },
  {
    id: 'NumAgreements',
    Header: '#Agreements',
    width: 127,
    accessor: 'agreements_count',
  },
  {
    id: 'NumCurrency',
    Header: '#Entered Currency',
    width: 117,
    accessor: 'entered_currency_count',
  },
  {
    id: 'NumRows',
    Header: '#Rows',
    width: 80,
    accessor: 'rows_count',
  },
  {
    id: 'NumAttributes',
    Header: '#Attributes',
    width: 115,
    accessor: 'attributes_count',
  },
];

const detailsColumnDefinitions = [
  {
    id: 'Index',
    Header: '#',
    width: 50,
    Cell: row => <span>{row.index + 1}</span>,
  },
  {
    id: 'Journal Name',
    Header: 'Journal Name',
    width: 140,
    accessor: 'journal_name',
  },
  {
    id: 'Reversal Period',
    Header: 'Reversal Period',
    width: 130,
    accessor: 'reversal_period',
  },
  {
    id: 'Entr Currency',
    Header: 'Entered Currency',
    width: 115,
    accessor: 'entered_currency',
  },
  {
    id: 'Entr Amount',
    Header: 'Entered Amount',
    width: 120,
    accessor: 'entered_amount',
    Cell: cellInfo => <span>{FORMATTERS.currencyTextFormatter(cellInfo.value)}</span>,
  },
  {
    id: 'Acc Amount',
    Header: 'Accounted Amount',
    width: 120,
    accessor: 'accounted_amount',
    Cell: cellInfo => <span>{FORMATTERS.currencyTextFormatter(cellInfo.value)}</span>,
  },
  {
    id: 'Status',
    Header: 'Status',
    width: 150,
    accessor: 'status',
  },
  {
    id: 'Approver Login',
    Header: 'Approver Login',
    width: 150,
    accessor: 'approver_login',
  },
];

export class PrimePostPage extends Component {
  static propTypes = {
    // onOpenClick: PropTypes.func,
    // onCloseClick: PropTypes.func,

    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    workbookName: PropTypes.string,
    history: CustomProps.history(),

    postingSummary: PropTypes.objectOf(PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.objectOf(PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.number,
          PropTypes.string,
        ])),
      ),
    ])),
    downloadUrl: PropTypes.string,

    fetchData: PropTypes.func,
    downloadConfirmation: PropTypes.func,
    downloadConfirmationReset: PropTypes.func,
  };

  static defaultProps = {
    error: null,
    workbookName: null,
    history: {},
    downloadUrl: null,

    // onOpenClick: () => { },
    // onCloseClick: () => { },

    postingSummary: {},
    fetchData: () => { },
    downloadConfirmation: () => { },
    downloadConfirmationReset: () => { },
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: MODALS.EMPTY,
    };
    this.onDownloadClick = this.onDownloadClick.bind(this);
    this.onOpenClick = this.onOpenClick.bind(this);
    this.onCloseClick = this.onCloseClick.bind(this);
    this.onDismissModal = this.onDismissModal.bind(this);
  }

  componentDidMount() {
    const { postingSummary, fetchData } = this.props;
    fetchData(postingSummary);
  }

  componentDidUpdate() {
    const { downloadUrl, downloadConfirmationReset } = this.props;
    if (downloadUrl !== null) {
      window.open(downloadUrl);
      downloadConfirmationReset();
    }
  }

  onDownloadClick() {
    const { downloadConfirmation, postingSummary } = this.props;
    downloadConfirmation('workbook', postingSummary.workbookID);
  }

  onOpenClick() {
    const { postingSummary } = this.props;
    const { batchSummaries, journalSummaries } = postingSummary;
    const { FILE_REQUEST_TYPES } = CONSTANT;
    let isEmpty = true;

    batchSummaries.forEach((batch) => {
      if (batch.isSelected === true) {
        const requestId = batch.journal_batch_id;
        const pathname = `/MJE/Validation/${FILE_REQUEST_TYPES.BATCH}/${requestId}`;
        window.open(pathname);
        isEmpty = false;
      }
    });
    journalSummaries.forEach((journal) => {
      if (journal.isSelected === true) {
        const requestId = journal.journal_header_id;
        const pathname = `/MJE/Validation/${FILE_REQUEST_TYPES.JOURNAL}/${requestId}`;
        window.open(pathname);
        isEmpty = false;
      }
    });

    if (isEmpty === true) {
      this.setState({ modal: MODALS.CANCEL });
    }
  }

  onCloseClick() {
    const { history } = this.props;
    if (window.location.href.includes('/MJE1_1')) {
      history.push('/MJE1_1');
    } else {
      history.push('/');
    } 
  }

  onDismissModal() {
    this.setState({ modal: MODALS.EMPTY });
  }

  handleSummaryCheckboxChange(index) {
    const { postingSummary } = this.props;
    const { batchSummaries } = postingSummary;
    batchSummaries[index].isSelected = !batchSummaries[index].isSelected;
  }

  handleDetailsCheckboxChange(index) {
    const { postingSummary } = this.props;
    const { journalSummaries } = postingSummary;
    journalSummaries[index].isSelected = !journalSummaries[index].isSelected;
  }

  renderModal() {
    const { modal } = this.state;
    switch (modal) {
      case MODALS.CANCEL:
        return (
          <Modal
            visible
            size="small"
            header="Note"
            content="Please select at least one journal batch or journal!"
            footer={(
              <div className="awsui-util-f-r">
                <Button
                  text="Cancel"
                  variant="link"
                  onClick={this.onDismissModal}
                />
              </div>
            )}
            onDismiss={this.onDismissModal}
          />
        );
      default:
        return <div />;
    }
  }

  renderFileSummary() {
    const { postingSummary } = this.props;
    const { batchSummaries } = postingSummary;

    const columnDefinitions = summaryColumnDefinitions.slice();
    columnDefinitions.push({
      id: 'actions',
      Header: 'View Journal Batch',
      Cell: row => <Checkbox onChange={() => this.handleSummaryCheckboxChange(row.index)} />,
    });

    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Journal Batch Summary</h2>
            </div>
          </div>
        </div>
        <ReactTable
          data={batchSummaries || []}
          minRows={0}
          columns={columnDefinitions}
          sortable={false}
          filterable={false}
          loadingText="Loading..."
          wrapLines={false}
          resizableColumns
          stickyHeader
          showPagination={false}
          noMatch="noMatchContent"
        />
      </div>
    );
  }

  renderJournalDetails() {
    const { postingSummary } = this.props;
    const { journalSummaries } = postingSummary;

    const columnDefinitions = detailsColumnDefinitions.slice();

    columnDefinitions.push({
      id: 'journalDetails',
      Header: 'View Journal',
      Cell: row => <Checkbox onChange={() => this.handleDetailsCheckboxChange(row.index)} />,
    });

    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Loaded Journal Details</h2>
            </div>
          </div>
        </div>

        <ReactTable
          data={journalSummaries || []}
          minRows={0}
          columns={columnDefinitions}
          sortable={false}
          filterable={false}
          loadingText="Loading..."
          wrapLines={false}
          resizableColumns
          stickyHeader
          showPagination={false}
          noMatch="noMatchContent"
        />
      </div>
    );
  }

  render() {
    const { error, workbookName, loading } = this.props;
    if (error) {
      return (
        <Modal>
          <Header>Error</Header>
          <Body>{error}</Body>
        </Modal>
      );
    }

    return (
      <div className={styles.wrapper}>
        <MJEBanner bannerText="MJE Posting Confirmation" />
        <div className="awsui-util-container">
          {workbookName && (
            <Alert type="success" dismissible className="awsui-util-t-c">
              <h1>
                Success!! Entries Loaded for
                {' '}
                {workbookName}
                .
              </h1>
            </Alert>
          )}
          <ColumnLayout className={styles.primePost}>
            <div data-awsui-column-layout-root="true">{this.renderFileSummary()}</div>
          </ColumnLayout>
          <ColumnLayout className={styles.primePost}>
            <div data-awsui-column-layout-root="true">{this.renderJournalDetails()}</div>
          </ColumnLayout>
          <ColumnLayout columns={2}>
            <div data-awsui-column-layout-root="true">
              <div>
                <Button
                  variant="primary"
                  text={loading ? 'Downloading...' : 'Download'}
                  disabled={loading}
                  icon="download"
                  className="download-button"
                  onClick={this.onDownloadClick}
                  data-tip="Download Complete Entry"
                />
                <Button
                  text="Open View(s)"
                  onClick={this.onOpenClick}
                  data-tip="View Selected Journal Batches or Journals"
                />
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  effect="solid"
                  event="mouseover"
                  eventOff="mouseout"
                />
              </div>
              <div>
                <Button
                  variant="primary"
                  text="Close"
                  icon="close"
                  iconAlign="right"
                  className="awsui-util-f-r"
                  onClick={this.onCloseClick}
                  data-tip="Close Confirmation And Go to Dashboard"
                />
                <ReactTooltip
                  place="bottom"
                  type="dark"
                  effect="solid"
                  event="mouseover"
                  eventOff="mouseout"
                />
              </div>
            </div>
          </ColumnLayout>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { validationUI, primePostPage } = state;
  const {
    error, postingSummary, workbookName,
  } = validationUI;
  const { loading, downloadUrl } = primePostPage;
  return {
    loading,
    error,
    postingSummary,
    workbookName,
    downloadUrl,
  };
};

const mapDispatchToProps = {
  fetchData: actions.fetchData,
  downloadConfirmation: actions.downloadConfirmation,
  downloadConfirmationReset: actions.downloadConfirmationReset,
};

const PrimePostPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrimePostPage);

export default withRouter(PrimePostPageContainer);
