import React from 'react';
import PropTypes from 'prop-types';
import { FormField, Input } from '@amzn/awsui-components-react';
import ErrorBoundary from 'common/components/err/errorBoundary';
import '@amzn/awsui-components-react/index.css';
import 'antd/dist/antd.css';
import 'index.css';

// Component for free text input with button on the same line
class FreeTextWithButtonElement extends React.Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    setErrorFlag: PropTypes.func.isRequired,

    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.string,

    required: PropTypes.bool,
    disabled: PropTypes.bool,

    buttonContent: PropTypes.node,
  };

  static defaultProps = {
    name: '',
    title: '',
    description: '',
    value: '',

    required: false,
    disabled: false,

    buttonContent: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      errorText: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const {
      handleChange, setErrorFlag, required, name,
    } = this.props;
    const { errorText } = this.state;
    const inputValue = event.target.component.value;

    handleChange(event.target.component.name, inputValue);
    if (required && inputValue === '') {
      this.setState({ errorText: 'This field is required' });
    } else {
      this.setState({ errorText: '' });
    }

    setErrorFlag(name, errorText);
  }

  render() {
    const {
      title, description, name, value, disabled, required, buttonContent,
    } = this.props;
    const { errorText } = this.state;

    return (
      <ErrorBoundary content={title}>
        <FormField
          label={title + (required ? '*' : '')}
          description={description}
          errorText={errorText}
          secondaryControl={buttonContent}
        >
          <Input
            name={name}
            value={value}
            onChange={this.handleChange}
            placeholder={this.placeholder}
            disabled={disabled}
          />
        </FormField>
      </ErrorBoundary>
    );
  }
}

export default FreeTextWithButtonElement;
