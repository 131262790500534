// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pages-LandingPage-components-ResourcesButton__resourceButtonWrapper--1WAe2 button.awsui-button{width:12em;text-align:center}", ""]);
// Exports
exports.locals = {
	"resourceButtonWrapper": "pages-LandingPage-components-ResourcesButton__resourceButtonWrapper--1WAe2"
};
module.exports = exports;
