import * as CONST from 'common/const';
import createReducer from './createReducer';

export const initialState = {
  runLogs: [],
  fetching: true,
  error: null,
  downloading: false,
  fileURL: null,
};

export default createReducer(initialState, {
  [CONST.REPORTS_HISTORY_GET_START]: state => ({
    ...state,
    error: null,
  }),

  [CONST.REPORTS_HISTORY_GET_SUCESS]: (state, action) => ({
    ...state,
    fetching: false,
    runLogs: action.runLogs,
    error: null,
  }),

  [CONST.REPORTS_HISTORY_GET_FAILURE]: (state, action) => ({
    ...state,
    fetching: false,
    runLogs: [],
    error: action.globalError,
  }),


  [CONST.REPORTS_HISTORY_GET_RESET]: (state, action) => ({
    ...state,
    fetching: true,
    error: null,
    downloading: false,
    fileURL: null,
  }),

  [CONST.REPORTS_HISTORY_DOWNLOAD_START]: (state, action) => ({
    ...state,
    downloading: true,
  }),

  [CONST.REPORTS_HISTORY_DOWNLOAD_SUCCESS]: (state, action) => ({
    ...state,
    downloading: false,
    fileURL: action.fileURL,
  }),

  [CONST.REPORTS_HISTORY_DOWNLOAD_FAILURE]: (state, action) => ({
    ...state,
    downloading: false,
    fileURL: null,
  }),

  [CONST.REPORTS_HISTORY_DOWNLOAD_RESET]: (state, action) => ({
    ...state,
    downloading: false,
    fileURL: null,
  }),
});
