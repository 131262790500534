import React from 'react';
import MJEBanner from './common/components/mjeBanner/MJEBanner';

function ICMJEAppRestrict() {
  return (
    <div>
      <MJEBanner bannerText="Amazon Intercompany Manual Journal Entry Tool" />
      <h1>Sorry! Access Denied</h1>
      <p>Looks like you don't have the required permission to access MJE website.</p>
      <p>
         Please send an email to FinTech Intercompany Manager Pooja Verma (poojv@)
         with your manager's written approval to gain access to MJE website.
      </p>
    </div>
  );
}

ICMJEAppRestrict.propTypes = {};

ICMJEAppRestrict.defaultProps = {};

export default ICMJEAppRestrict;
