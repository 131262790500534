import React from 'react';
import PropTypes from 'prop-types';
import '@amzn/awsui-components-react/index.css';
import { ColumnLayout } from '@amzn/awsui-components-react';
import { TableView } from 'common/components/dataDisplay';

class MJELinesSection extends React.Component {
  static propTypes = {
    templateName: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.string),
    items: PropTypes.arrayOf(PropTypes.shape({})),
  };

  static defaultProps = {
    templateName: '',
    columns: [],
    items: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      // elementSelected: [],
    };

    // TODO: Uncomment this line when handleSelectChange is implemented.
    // this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange = (/* polarisSelectedList */) => {
    // this.setState({
    //   elementSelected: polarisSelectedList,
    // });
  };

  render() {
    const { templateName, columns, items } = this.props;

    const header = (
      <div className="awsui-util-action-stripe">
        <div className="awsui-util-action-stripe-title">
          <h2>
            {templateName}
            {' '}
Line Table
          </h2>
        </div>
        <div className="awsui-util-action-stripe-group" />
      </div>
    );

    const columnDefinitions = [];
    for (let index = 0; index < columns.length; index += 1) {
      const columnDefinitionsItem = {};
      const column = columns[index];
      columnDefinitionsItem.id = column;
      columnDefinitionsItem.header = column;
      columnDefinitionsItem.cell = item => item[column];
      columnDefinitionsItem.width = 150;
      columnDefinitionsItem.minWidth = '150px';
      columnDefinitions.push(columnDefinitionsItem);
    }

    const sortableColumns = [];
    for (let index = 0; index < columns.length; index += 1) {
      const sortableColumnsItem = {};
      const column = columns[index];
      sortableColumnsItem.id = column;
      sortableColumnsItem.field = column;
      sortableColumns.push(sortableColumnsItem);
    }

    const visibleColumnOption = [];
    const firstOption = {};
    firstOption.label = 'Properties';
    const options = [];
    for (let index = 0; index < columns.length; index += 1) {
      const visibleColumnOptionItem = {};
      const column = columns[index];
      visibleColumnOptionItem.id = column;
      visibleColumnOptionItem.label = column;
      visibleColumnOptionItem.editable = column !== 'column_name';
      visibleColumnOptionItem.visible = true;
      options.push(visibleColumnOptionItem);
    }

    firstOption.options = options;
    visibleColumnOption.push(firstOption);

    const pageSizeOption = [
      { value: 10, label: '10 items' },
      { value: 20, label: '20 items' },
      { value: 50, label: '50 items' },
    ];

    return (
      <ColumnLayout columns={1}>
        <div data-awsui-column-layout-root>
          <TableView
            tableTitle="MJE lines table"
            columnDefinitions={columnDefinitions}
            items={items}
            header={header}
            trackBy="column_name"
            searchMessage="Filter"
            pageSize={10}
            loading={false}
            sortableColumns={sortableColumns}
            pageSizeOption={pageSizeOption}
            visibleColumnOption={visibleColumnOption}
            handleSelectChange={this.handleSelectChange}
          />
        </div>
      </ColumnLayout>
    );
  }
}

export default MJELinesSection;
