/**
 * Key value pair local storage.
 *
 * @param key local storage key
 * @param value serializable object
 */
export const putInLocal = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        console.log("Could not save object to local storage");
    }
}

/**
 * Retrieve value from local storage.
 *
 * @param key storage key
 * @returns {undefined|*}
 */
export const getFromLocal = (key) => {
    try {
        const value = localStorage.getItem(key);
        return value === null ? undefined : JSON.parse(value);
    } catch (e) {
        console.log("Could not parse object in local storage")
        return undefined;
    }
}

/**
 * Removes an entry from session storge.
 *
 * @param key session storage key
 */
export const removeFromSession = (key) => {
    try {
        sessionStorage.removeItem(key);
    } catch (e) {
        console.log("Could not remove object from session storage");
    }
}

/**
 * Key value pair session storage.
 *
 * @param key session storage key
 * @param value serializable object
 */
export const putInSession = (key, value) => {
    try {
        sessionStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        console.log("Could not save object to session storage");
    }
}

/**
 * Retrieve value from session storage.
 *
 * @param key storage key
 * @returns {undefined|*}
 */
export const getFromSession = (key) => {
    try {
        const value = sessionStorage.getItem(key);
        return value === null ? undefined : JSON.parse(value);
    } catch (e) {
        console.log("Could not parse object in session storage")
        return undefined;
    }
}

/**
 * Clears the session storage.
 */
export const clearSession = () => {
    try {
        sessionStorage.clear();
    } catch (e) {
        console.log("Could not clear session storage");
    }
}