import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from '@amzn/awsui-components-react';
import ErrorBoundary from 'common/components/err/errorBoundary';
import '@amzn/awsui-components-react/index.css';
import 'antd/dist/antd.css';
import 'index.css';

// Component for tile input
function CustomTileElement({
  handleClick, title, label, description, selected, disabled,
}) {
  return (
    <div>
      <ErrorBoundary content={title}>
        <div className={`custom-tiles${selected === label ? '-selected' : ''}`}>
          <RadioButton
            label={label}
            disabled={disabled}
            checked={selected === label}
            description={description}
            onChange={() => !disabled && handleClick(label)}
          />
        </div>
      </ErrorBoundary>
    </div>
  );
}

CustomTileElement.propTypes = {
  handleClick: PropTypes.func.isRequired,

  title: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  selected: PropTypes.string,

  disabled: PropTypes.bool,
};

CustomTileElement.defaultProps = {
  title: '',
  label: '',
  description: '',
  selected: '',
  disabled: false,
};

export default CustomTileElement;
