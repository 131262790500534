import XLSX from 'xlsx';
import config from '../config/config.json';

function readFile(filename) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      const data = e.target.result;
      resolve(data);
    };

    fileReader.onerror = (e) => {
      reject(e);
    };

    fileReader.readAsBinaryString(filename);
  });
}

class XLSXUtils {
  // static empty_header_sheetName = 'blank-MJE-with-header';
  static emptyHeaderSheetName = 'blank-MJE-with-header';

  static writeEmptyXLSX = (templateName, items) => {
    const headerData = {};
    items.forEach((item) => {
      headerData[item.column_name] = '';
    });
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([headerData]);
    XLSX.utils.book_append_sheet(wb, ws, 'blank-MJE-with-header');
    XLSX.writeFile(wb, `${templateName}.xlsx`);
  };

  static writeSingleSheetTable(fileName, sheetName, jsonContent, header) {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(jsonContent, { header });
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  static loadWorkbook = async (filename) => {
    const fileData = await readFile(filename);

    const workbook = XLSX.read(fileData, {
      type: 'binary',
      cellText: false,
      cellDates: true,
    });

    return workbook;
  };

  static loadSheetJson = sheet => XLSX.utils.sheet_to_json(sheet, {
    header: 1,
    blankrows: false,
    raw: false,
    dateNF: config.dateFormat,
  });

  static loadFile = async (filename) => {
    const fileData = await readFile(filename);

    const workbook = XLSX.read(fileData, {
      type: 'binary',
      cellText: false,
      cellDates: true,
    });

    const targetSheets = workbook.SheetNames.filter(sheetName => sheetName.startsWith('ICMJE'));

    let sheets = targetSheets.map((sheetName) => {
      const sheetJson = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
        blankrows: false,
        raw: false,
        dateNF: config.dateFormat,
      });

      return [sheetName, sheetJson.slice(1)]; // Remove unnecessary header from file
    });

    sheets = Object.fromEntries(sheets);

    return {
      workbook,
      sheets,
    };
  };

  static loadAllRecordsfromXLSX = (data) => {
    const firstWorkBook = XLSX.read(data, { type: 'binary', cellText: false, cellDates: true });

    // assuming each spreadsheet has only one sheet here
    const workSheet = firstWorkBook.Sheets[firstWorkBook.SheetNames[0]];

    return XLSX.utils.sheet_to_json(workSheet, {
      header: 1,
      raw: false,
      dateNF: config.dateFormat,
    });
  };
}

export default XLSXUtils;
