import React from 'react';
// import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import '@amzn/awsui-components-react/index.css';
import CustomProps from 'common/prop-types';
import CONSTANT from 'utils/constant';
import APIClient from 'utils/apiClient';
import Logger from 'utils/logger';
import getModalMessage from 'common/components/feedback/modal';
import SearchBar from './SearchBar';
import MetaDataBlock from './MetaDataBlock';

/*
 * Parent element that wrap all the above component
 */
class QueryMetaData extends React.Component {
  static propTypes = {
    history: CustomProps.history().isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      templateName: '',
      status: CONSTANT.MODAL_EMPTY,
      loading: false,
      data: {
        columns: [],
        items: [],
      },
    };

    this.handleQueryClick = this.handleQueryClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleCreateClick = this.handleCreateClick.bind(this);
    this.handleViewClick = this.handleViewClick.bind(this);
  }

  handleQueryClick(newTemplateName) {
    const { templateName } = this.state;

    if (newTemplateName === '') {
      this.setState({ status: CONSTANT.MODAL_REQUIRED_EMPTY });
      return;
    }

    this.setState({
      loading: true,
      templateName: newTemplateName,
    });

    APIClient.invokeBusinessLogic(
      'ddb',
      'QueryMetaData',
      { template_name: newTemplateName },
      {},
      (err, data) => {
        this.setState({ loading: false });
        if (!err) {
          switch (data.status) {
            case CONSTANT.RESPONSE_SUCCESS:
              this.setState({
                status: CONSTANT.MODAL_QUERY_SUCCESS,
                data: { columns: data.columns, items: data.items },
              });
              break;
            case CONSTANT.RESPONSE_KEY_NOT_EXIST:
              this.setState({ status: CONSTANT.MODAL_META_QUERY_KEY_NOT_EXIST });
              break;
            case CONSTANT.RESPONSE_ERROR:
              this.setState({ status: CONSTANT.MODAL_QUERY_ERROR });
              break;
            case CONSTANT.RESPONSE_REQUEST_ERROR:
              this.setState({ status: CONSTANT.MODAL_REQUEST_ERROR });
              break;
            default:
          }
        } else {
          Logger.logError(`Backend crash when queryMetaData ${templateName}`);
          this.setState({ status: CONSTANT.MODAL_QUERY_ERROR });
        }
      },
    );
  }

  handleDeleteClick(selectedList) {
    const { templateName, data } = this.state;

    Logger.logInfo(`Enter delete click with selectedList ${selectedList}`);
    this.setState({ status: CONSTANT.MODAL_SUBMITTING });
    APIClient.invokeBusinessLogic(
      'ddb',
      'DeleteMetaData',
      {
        template_name: templateName,
        column_name: selectedList.map(item => item.column_name),
      },
      {},
      (err, newData) => {
        if (!err) {
          switch (newData.status) {
            case CONSTANT.RESPONSE_SUCCESS: {
              // this.setState({ status: CONSTANT.MODAL_DELETE_SUCCESS });
              // delete the record from ui, need to sort in descending order
              const indexToDeleteList = selectedList
                .map(item => item.index - 1)
                .sort((a, b) => b - a);
              // for (const indexToDelete of indexToDeleteList) data.items.splice(indexToDelete, 1);
              const itemsCopy = data.items.slice();
              const updatedItems = indexToDeleteList.reduce((r, i) => r.splice(i, 1), itemsCopy);
              this.setState({
                status: CONSTANT.MODAL_DELETE_SUCCESS,
                data: {
                  ...data,
                  items: updatedItems,
                },
              });
              break;
            }

            case CONSTANT.RESPONSE_ERROR:
              this.setState({ status: CONSTANT.MODAL_DELETE_ERROR });
              break;
            case CONSTANT.RESPONSE_REQUEST_ERROR:
              this.setState({ status: CONSTANT.MODAL_REQUEST_ERROR });
              break;
            default:
          }
        } else {
          Logger.logError('Backend crashed when deleteMetaData');
          this.setState({ status: CONSTANT.MODAL_DELETE_ERROR });
        }
      },
    );
  }

  handleCreateClick() {
    const { history } = this.props;
    const { templateName } = this.state;
    history.push({
      pathname: '/MJE/CreateMetaData',
      state: { templateName },
    });
  }

  handleViewClick(elementSelected) {
    const { history } = this.props;
    const { data } = this.state;
    const itemToView = data.items[elementSelected[0].index - 1];
    history.push({
      pathname: '/MJE/UpdateMetaData',
      state: { metaDefinition: itemToView },
    });
  }

  render() {
    const {
      data, status, loading, templateName,
    } = this.state;

    // only string is allowed in Polaris table
    const tableItems = JSON.parse(JSON.stringify(data.items));
    for (let index = 0; index < tableItems.length; index += 1) {
      if (data.items[index].valid_values !== undefined) tableItems[index].valid_values = data.items[index].valid_values.join(', ');
      tableItems[index].index = index + 1;
    }

    return (
      <div>
        <SearchBar handleQueryClick={this.handleQueryClick} />
        <MetaDataBlock
          templateName={templateName}
          items={tableItems}
          columns={data.columns}
          loading={loading}
          handleViewClick={this.handleViewClick}
          handleCreateClick={this.handleCreateClick}
          handleDeleteClick={this.handleDeleteClick}
        />
        {getModalMessage(status, this)}
      </div>
    );
  }
}

export default withRouter(QueryMetaData);
