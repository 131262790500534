import React from 'react';
import {
  Modal, Input, Icon, Button, Spinner,
} from '@amzn/awsui-components-react';
import CONSTANT from 'utils/constant';
import '@amzn/awsui-components-react/index.css';

function getModalMessage(state, reactComponent) {
  switch (state) {
    // general modal message
    case CONSTANT.MODAL_REQUEST_ERROR:
      return (
        <Modal
          content={<div>Development error </div>}
          visible
          header={(
            <div>
              <Icon variant="error" name="status-negative" />
              This request is invalid
            </div>
          )}
          onDismiss={() => reactComponent.setState({ status: CONSTANT.MODAL_EMPTY })}
        />
      );
    case CONSTANT.MODAL_INPUT_ERROR:
      return (
        <Modal
          content={<div>Detect error for one or more input fields. </div>}
          visible
          header={(
            <div>
              <Icon variant="warning" name="status-warning" />
              Submitting failed
            </div>
          )}
          onDismiss={() => reactComponent.setState({ status: CONSTANT.MODAL_EMPTY })}
        />
      );
    case CONSTANT.MODAL_SUBMITTING:
      return (
        <Modal
          content={<div />}
          visible
          header={(
            <div>
              <Spinner />
              Submitting... Do not close the window...
            </div>
          )}
        />
      );

    case CONSTANT.MODAL_REQUIRED_EMPTY:
      return (
        <Modal
          content={<div>Input error </div>}
          visible
          header={(
            <div>
              <Icon variant="error" name="status-negative" />
              Please fix the error field and resubmit
            </div>
          )}
          onDismiss={() => reactComponent.setState({ status: CONSTANT.MODAL_EMPTY })}
        />
      );
    case CONSTANT.MODAL_QUERYING:
      return (
        <Modal
          content={<div>Fetching data for you... Do not close the window...</div>}
          visible
          header={(
            <div>
              <Spinner />
              Fetching
            </div>
          )}
        />
      );

    // general create data related modal
    case CONSTANT.MODAL_CREATE_DUPLICATE:
      return (
        <Modal
          content={<div>Creation failed. This record has already been created.</div>}
          visible
          header={(
            <div>
              <Icon variant="warning" name="status-warning" />
              Create failed
            </div>
          )}
          onDismiss={() => reactComponent.setState({ status: CONSTANT.MODAL_EMPTY })}
        />
      );
    case CONSTANT.MODAL_CREATE_ERROR:
      return (
        <Modal
          content={<div>Creation failed due to some unknown error. Please try again latter.</div>}
          visible
          header={(
            <div>
              <Icon variant="error" name="status-negative" />
              Create failed
            </div>
          )}
          onDismiss={() => reactComponent.setState({ status: CONSTANT.MODAL_EMPTY })}
        />
      );
    case CONSTANT.MODAL_CREATE_SUCCESS:
      return (
        <Modal
          content={<div>The record has been successfully created.</div>}
          visible
          header={(
            <div>
              <Icon variant="success" name="status-positive" />
              Create Success
            </div>
          )}
          onDismiss={reactComponent.handleClear}
        />
      );

    // general delete data related modal
    case CONSTANT.MODAL_DELETE_SUCCESS:
      return (
        <Modal
          content={<div>The selected rows have been deleted.</div>}
          visible
          header={(
            <div>
              <Icon variant="success" name="status-positive" />
              Delete Succeed
            </div>
          )}
          onDismiss={() => reactComponent.setState({ status: CONSTANT.MODAL_EMPTY })}
        />
      );

    case CONSTANT.MODAL_DELETE_ERROR:
      return (
        <Modal
          content={<div>Delete failed due to some unknown error. Please try again latter.</div>}
          visible
          header={(
            <div>
              <Icon variant="error" name="status-negative" />
              Delete failed
            </div>
          )}
          onDismiss={() => reactComponent.setState({ status: CONSTANT.MODAL_EMPTY })}
        />
      );

    // general update data related modal
    case CONSTANT.MODAL_UPDATE_SUCCESS:
      return (
        <Modal
          content={<div>The record has been successfully updated.</div>}
          visible
          header={(
            <div>
              <Icon variant="success" name="status-positive" />
              Update Success
            </div>
          )}
          onDismiss={reactComponent.lockUpdates}
        />
      );

    case CONSTANT.MODAL_UPDATE_ERROR:
      return (
        <Modal
          content={<div>Update failed due to some unknown error. Please try again latter.</div>}
          visible
          header={(
            <div>
              <Icon variant="error" name="status-negative" />
              Update failed
            </div>
          )}
          onDismiss={() => reactComponent.setState({ status: CONSTANT.MODAL_EMPTY })}
        />
      );

    // modal unique to create meta data page
    case CONSTANT.MODAL_META_ADD_BUTTON_CLICK:
      return (
        <Modal
          content={(
            <Input
              type="text"
              value={reactComponent.state.valueToAdd}
              autoFocus
              onChange={event => reactComponent.setState({
                valueToAdd: event.target.component.value,
              })
              }
            />
          )}
          visible
          header="Please add a value"
          footer={(
            <span className="awsui-util-f-r">
              <Button variant="link" text="Cancel" onClick={reactComponent.recoverEmptyState} />
              <Button variant="primary" text="Ok" onClick={reactComponent.handleAddConfirm} />
            </span>
          )}
          onDismiss={reactComponent.recoverEmptyState}
        />
      );
    case CONSTANT.MODAL_META_ADD_DUPLICATE_VALUE:
      return (
        <Modal
          content={(
            <div>
              {reactComponent.state.valueToAdd}
              is already in the value list.
            </div>
          )}
          visible
          header="Add failed"
          onDismiss={() => reactComponent.setState({ status: CONSTANT.MODAL_EMPTY })}
        />
      );

    // modal unique to query meta data page
    case CONSTANT.MODAL_META_QUERY_KEY_NOT_EXIST:
      return (
        <Modal
          content={(
            <div>
              Here is no table named
              {reactComponent.state.template_name}
              yet.
            </div>
          )}
          visible
          header={(
            <div>
              <Icon variant="warning" name="status-warning" />
              Not exist
            </div>
          )}
          onDismiss={() => reactComponent.setState({ status: CONSTANT.MODAL_EMPTY })}
        />
      );

    // modal unique to query mje data
    case CONSTANT.MODAL_FETCHING_KEY:
      return (
        <Modal
          content={<div />}
          visible
          header={(
            <div>
              <Spinner />
              Fetching key columns... Do not close the window...
            </div>
          )}
        />
      );

    case CONSTANT.MODAL_KEY_COLUMN_NOT_EXIST:
      return (
        <Modal
          content={(
            <div>
              There is no key columns set up yet for template
              {reactComponent.state.templateName}
            </div>
          )}
          visible
          header={(
            <div>
              <Icon variant="warning" name="status-warning" />
              Not exist
            </div>
          )}
          onDismiss={() => reactComponent.setState({ status: CONSTANT.MODAL_EMPTY })}
        />
      );
    case CONSTANT.MODAL_MJE_NOT_EXIST:
      return (
        <Modal
          content={<div>Here is no mje corresponding to your input</div>}
          visible
          header={(
            <div>
              <Icon variant="warning" name="status-warning" />
              Not exist
            </div>
          )}
          onDismiss={() => reactComponent.setState({ status: CONSTANT.MODAL_EMPTY })}
        />
      );

    // modal unique to mje file upload
    case CONSTANT.MODAL_FETCHING_META_DATA:
      return (
        <Modal
          content={(
            <div>
              <Spinner />
              Fetching columns to generate empty spreadsheet ... Do not close the window...
            </div>
          )}
          visible
          header={<div>Fetching</div>}
        />
      );
    case CONSTANT.MODAL_FILE_FORMAT_ERROR:
      return (
        <Modal
          content={
            <div>Encounter error when parsing file. Please check if the format is correct.</div>
          }
          visible
          header={(
            <div>
              <Icon variant="error" name="status-negative" />
              Paring filed
            </div>
          )}
          onDismiss={() => reactComponent.setState({ status: CONSTANT.MODAL_EMPTY })}
        />
      );
    case CONSTANT.MODAL_VALIDATING:
      return (
        <Modal
          content={<div />}
          visible
          header={(
            <div>
              <Spinner />
              Validating your file... Please wait...
            </div>
          )}
        />
      );
    case CONSTANT.MODAL_VALIDATE_ERROR:
      return (
        <Modal
          content={<div>Validation failed due to some unknown error. Please try again latter.</div>}
          visible
          header={(
            <div>
              <Icon variant="error" name="status-negative" />
              Validate failed
            </div>
          )}
          onDismiss={() => reactComponent.setState({ status: CONSTANT.MODAL_EMPTY })}
        />
      );

    case CONSTANT.MODAL_UPLOADING:
      return (
        <Modal
          content={<div />}
          visible
          header={(
            <div>
              <Spinner />
              Uploading your file... Please wait...
            </div>
          )}
        />
      );

    default:
      return null;
  }
}

export default getModalMessage;
