// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pages-LandingPage-components-SearchModal__searchModalWrapper--3Avoe .pages-LandingPage-components-SearchModal__searchContainer--1CA6L{border:.06em solid #000;padding:1.8em !important}.pages-LandingPage-components-SearchModal__searchModalWrapper--3Avoe .pages-LandingPage-components-SearchModal__searchContainer--1CA6L h3{width:18%;margin-top:-2.5em;margin-left:.6em;padding:.6em;background:#fff;text-align:center}.pages-LandingPage-components-SearchModal__searchModalWrapper--3Avoe .pages-LandingPage-components-SearchModal__divider--3I77E{background:#d5dbdb;display:block;clear:both;width:100%;min-width:100%;height:.06em}.pages-LandingPage-components-SearchModal__searchModalWrapper--3Avoe .pages-LandingPage-components-SearchModal__searchContainer--1CA6L .awsui-select-keyboard-area{width:17em}.pages-LandingPage-components-SearchModal__searchModalWrapper--3Avoe .awsui-modal-content{width:650px}", ""]);
// Exports
exports.locals = {
	"searchModalWrapper": "pages-LandingPage-components-SearchModal__searchModalWrapper--3Avoe",
	"searchContainer": "pages-LandingPage-components-SearchModal__searchContainer--1CA6L",
	"divider": "pages-LandingPage-components-SearchModal__divider--3I77E"
};
module.exports = exports;
