/**
 * Custom Prop type for validating React Component types.
 *
 * Under normal circumstances, we don't need advanced validation
 * for React node types. PropTypes.node, PropTypes.element and
 * PropTypes.elementType are sufficient to validate most React
 * components. However these prop validators don't check the types
 * of the nodes, which is restricting in certain situations.
 *
 * For example, say we have a Modal class with the following props:
 *
 * class Modal extends React.Component {
 *   static propTypes = {
 *     children: PropTypes.node,
 *   };
 * }
 *
 * This works for general React nodes, but if we want to only allow
 * specific types, this code doesn't work. Using PropTypes.instanceOf(...)
 * could work, but that validator uses Javascript's builtin 'instanceof'
 * operator, which--since JSX is compiled into JSON at runtime--doesn't
 * work for React elements.
 *
 * Example:
 *
 * import CommonProps from 'common/prop-types';
 *
 * function ModalBody({ content }) { return <div>{content}</div>; };
 *
 * class Modal extends React.Component {
 *   static propTypes = {
 *     children: CommonProps.componentOf(ModalBody), // Restricts children to just <ModalBody />
 *   }
 *
 *   render() {
 *     return <div>{this.props.children}</div>;
 *   }
 * }
 */
export function componentOf(componentType) {
  return (props, propName /* , componentName */) => {
    const { [propName]: component } = props;

    if (typeof componentType !== 'function') {
      return new Error(`Expected type constructor, but got: ${componentType}`);
    }

    if (component === null) {
      return null; // Valid Optional Case
    }

    if (typeof component !== 'object') {
      return new Error(`Expected object, but got: ${component}`);
    }

    if (!component.type) {
      return new Error(`Expected React component, but got invalid object: ${component}`);
    }

    if (component.type !== componentType) {
      return new Error(`Expect component of type ${componentType}, but got: ${component}`);
    }

    return null;
  };
}

export const date = (props, propName, componentName) => {
  const { [propName]: value } = props;

  if (value === null) {
    return null; // Valid Optional Case
  }

  if (typeof value !== 'string') {
    return new Error(
      `Expected a Date String for prop '${propName}' in component '${componentName}', but got: ${value}`,
    );
  }

  if (Number.isNaN(new Date(value).getTime())) {
    return new Error(
      `Expected valid Date Format for prop '${propName}' in component '${componentName}', but got: ${value}`,
    );
  }

  return null;
};

export default {
  componentOf,
};
