import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@amzn/awsui-components-react';
import ReactTooltip from 'react-tooltip';
import styles from './ResourcesButton.scss';

const ResourcesButton = ({
  onSubmit, value, iconType, tooltipText, disabled, loading,
}) => (
  <div className={styles.resourceButtonWrapper}>
    <Button
      icon={iconType}
      text={value}
      iconAlign="right"
      data-tip={tooltipText === '' ? value : tooltipText}
      onClick={onSubmit}
      disabled={disabled}
      loading={loading}
    />
    <ReactTooltip place="bottom" type="dark" effect="solid" event="mouseover" eventOff="mouseout" />
  </div>
);

ResourcesButton.propTypes = {
  onSubmit: PropTypes.func,
  value: PropTypes.string.isRequired,
  iconType: PropTypes.string,
  tooltipText: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
};

ResourcesButton.defaultProps = {
  onSubmit: () => { },
  iconType: '',
  tooltipText: '',
  loading: false,
};
export default ResourcesButton;
