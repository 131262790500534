/* eslint-disable no-plusplus */
import {
  Button, ColumnLayout, Input,
} from '@amzn/awsui-components-react';
import * as actions from 'actions';
import { LoadingSpinner } from 'common/components';
import MJEBanner from 'common/components/mjeBanner/MJEBanner';
import MJEModal from 'common/components/mjeModal/MJEModal';
import CustomProps from 'common/prop-types';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDataGrid from 'react-data-grid';
import { Data } from 'react-data-grid-addons';
import { connect } from 'react-redux';
import CONSTANT from 'utils/constant';
import FORMATTERS from 'utils/formatUtils';
import styles from './PrimeJournalSummary.scss';

const { Selectors } = Data;

export class PrimeJournalSummaryComponent extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    username: PropTypes.string.isRequired,
    journalHeaderId: PropTypes.string.isRequired,

    header: PropTypes.shape({
      journalName: PropTypes.string,
      description: PropTypes.string,
      ledger: PropTypes.string,
      period: PropTypes.string,
      balanceType: PropTypes.string,
      category: PropTypes.string,
      effectiveDate: PropTypes.string,
      preparerLogin: PropTypes.string,
      periodForDisplay: PropTypes.string,
    }),
    conversion: PropTypes.shape({
      currency: PropTypes.string,
      date: PropTypes.string,
      type: PropTypes.string,
      rate: PropTypes.string,
    }),
    dataGridColumns: PropTypes.arrayOf(
      PropTypes.shape({
        seqNum: PropTypes.number,
        header: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.oneOf(['required', 'conditional', 'system']),
      }),
    ),
    dataGrid: PropTypes.arrayOf(
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
          PropTypes.arrayOf(PropTypes.string),
          PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
          PropTypes.objectOf(PropTypes.bool),
        ]),
      ),
    ),
    saving: PropTypes.bool,

    onClickOpenFile: PropTypes.func,
    onClickOpenJournal: PropTypes.func,
    setErrorFlag: PropTypes.func,
    fetchInitialData: PropTypes.func,
    downloadJournal: PropTypes.func,
    downloadJournalReset: PropTypes.func,

    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    downloadJournalUrl: PropTypes.string,

    history: CustomProps.history(),
    match: CustomProps.match({
      requestId: PropTypes.string,
    }),

  };

  static defaultProps = {
    className: '',
    saving: false,
    error: null,
    header: {},
    conversion: {},
    dataGridColumns: [],
    dataGrid: [],
    history: {},
    match: {},
    downloadJournalUrl: null,

    onClickOpenFile: () => { },
    onClickOpenJournal: () => { },
    setErrorFlag: () => { },
    fetchInitialData: () => { },
    downloadJournal: () => { },
    downloadJournalReset: () => { },
  };

  constructor(props) {
    super(props);
    this.state = {
      spinnerText: 'Loading...',
      columnDefinitions: [],
    };

    this.onChangeField = this.onChangeField.bind(this);
    this.setErrorFlag = this.setErrorFlag.bind(this);
    this.onClickOpenFile = this.onClickOpenFile.bind(this);
    this.onClickOpenJournal = this.onClickOpenJournal.bind(this);
    this.onClickDownload = this.onClickDownload.bind(this);
    this.onClickDone = this.onClickDone.bind(this);
    this.onViewClick = this.onViewClick.bind(this);
    this.getGridRows = this.getGridRows.bind(this);
    this.getGridRow = this.getGridRow.bind(this);
    this.getGridSize = this.getGridSize.bind(this);
    this.buildColumnDefinitions = this.buildColumnDefinitions.bind(this);
  }

  // ///////////////////////// //
  // /// Lifecycle Methods /// //
  // ///////////////////////// //

  componentDidMount() {
    const {
      match,
      fetchInitialData,
    } = this.props;
    const { requestId } = match.params;
    fetchInitialData(requestId);
  }

  componentDidUpdate() {
    const {
      downloadJournalUrl,
      downloadJournalReset,
    } = this.props;
    this.buildColumnDefinitions();
    if (downloadJournalUrl !== null) {
      window.open(downloadJournalUrl);
      downloadJournalReset();
    }
  }

  // ////////////////////// //
  // /// Event Handlers /// //
  // ////////////////////// //

  onChangeField(section) {
    return (name, value) => {
      this.setState(state => ({
        [section]: {
          ...state[section],
          [name]: value,
        },
      }));
    };
  }

  onClickOpenFile(e) {
    const { onClickOpenFile } = this.props;
    onClickOpenFile(e);
  }

  onClickOpenJournal(e) {
    const { onClickOpenJournal } = this.props;
    onClickOpenJournal(e);
  }

  onClickDownload() {
    const { match, downloadJournal } = this.props;
    const { requestId } = match.params;
    this.setState(() => ({ spinnerText: 'Downloading...' }));
    downloadJournal(requestId);
  }

  onClickDone() {
    const { history } = this.props;
    history.push('/');
  }

  onViewClick() {
    const { history, journalHeaderId } = this.props;
    const { FILE_REQUEST_TYPES } = CONSTANT;
    history.push(`/MJE/Validation/${FILE_REQUEST_TYPES.JOURNAL}/${journalHeaderId}`);
  }

  // //////////////////////// //
  // /// Helper Functions /// //
  // //////////////////////// //

  setErrorFlag(e) {
    const { setErrorFlag } = this.props;
    setErrorFlag(e);
  }

  getGridRows() {
    const { dataGrid } = this.props;
    return Selectors.getRows({
      rows: dataGrid,
    });
  }

  getGridRow(i) {
    return this.getGridRows()[i];
  }

  getGridSize() {
    return this.getGridRows().length;
  }

  buildColumnDefinitions() {
    const { dataGridColumns } = this.props;
    if (dataGridColumns == null || dataGridColumns.length === 0) {
      return;
    }
    const { columnDefinitions } = this.state;

    if (columnDefinitions != null && columnDefinitions.length > 0) {
      return;
    }

    const columnsOnTheLeft = [
      { key: 'index', width: 45, frozen: true },
      { key: 'accounting_string', width: 290, frozen: true },
      { key: 'entered_dr', width: 145 },
      { key: 'entered_cr', width: 145 },
      { key: 'accounted_dr', width: 150 },
      { key: 'accounted_cr', width: 150 },
      { key: 'line_desc', width: 620 },
    ];

    const columnDefs = [];
    dataGridColumns.forEach((column) => {
      const formatterFunction = FORMATTERS.resolveFormatter(column.dataType);
      const columnDef = {
        key: column.name,
        name: column.header,
        width: column.header.length * 8 + 20,
        resizable: true,
        // eslint-disable-next-line arrow-body-style
        cellContentRenderer: ({ rowData }) => {
          return PrimeJournalSummaryComponent.renderGridCell(
            column.name, rowData, formatterFunction,
          );
        },
      };
      const columnOnTheLeft = columnsOnTheLeft.find(x => x.key === columnDef.key);
      if (columnOnTheLeft) {
        columnOnTheLeft.name = columnDef.name;
        columnOnTheLeft.formatter = columnDef.formatter;
        columnOnTheLeft.resizable = true;
        columnOnTheLeft.cellContentRenderer = columnDef.cellContentRenderer;
      } else {
        columnDefs.push(columnDef);
      }
    });

    for (let i = columnsOnTheLeft.length - 1; i >= 0; i--) {
      columnDefs.unshift(columnsOnTheLeft[i]);
    }

    this.setState({ columnDefinitions: columnDefs });
  }

  // //////////////////////// //
  // /// Render Functions /// //
  // //////////////////////// //

  renderHeader() {
    const { header } = this.props;
    return (
      <div className="awsui-util-container top-container-box">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Header</h2>
            </div>
          </div>
        </div>
        <div>
          <div className="awsui-grid">
            <div className="awsui-row">
              <div className="col-3">Journal Name</div>
              <div className="col-9"><Input name="journal_name" value={header.journalName} disabled /></div>
            </div>
            <div className="awsui-row">
              <div className="col-3">Description</div>
              <div className="col-9"><Input name="description" value={header.description} disabled /></div>
            </div>

            <div className="awsui-row">
              <div className="col-3">Ledger</div>
              <div className="col-9">
                <div className="awsui-grid">
                  <div className="awsui-row">
                    <div className="col-4"><Input name="ledger" value={header.ledger} disabled /></div>
                    <div className="col-4">Category</div>
                    <div className="col-4"><Input name="category" value={header.category} disabled /></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="awsui-row">
              <div className="col-3">Period</div>
              <div className="col-9">
                <div className="awsui-grid">
                  <div className="awsui-row">
                    <div className="col-4"><Input name="period" value={header.periodForDisplay} disabled /></div>
                    <div className="col-4">Effective Date</div>
                    <div className="col-4"><Input name="effective_date" value={header.effectiveDate} disabled /></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="awsui-row">
              <div className="col-3">Balance Type</div>
              <div className="col-9">
                <div className="awsui-grid">
                  <div className="awsui-row">
                    <div className="col-4"><Input name="balance_type" value={header.balanceType} disabled /></div>
                    <div className="col-4">Preparer Login</div>
                    <div className="col-4"><Input name="preparer_login" value={header.preparerLogin} disabled /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderConversion() {
    const className = 'Conversion awsui-util-container top-container-box';
    const { conversion } = this.props;
    return (
      <div className={className}>
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>Conversion</h2>
            </div>
          </div>
        </div>
        <div>
          <div className="awsui-grid">
            <div className="awsui-row">
              <div className="col-5">Currency</div>
              <div className="col-7">
                <Input name="Currency" value={conversion.currency} disabled />
              </div>
            </div>
            <div className="awsui-row">
              <div className="col-5">Date</div>
              <div className="col-7">
                <Input name="Date" value={conversion.date} disabled />
              </div>
            </div>
            <div className="awsui-row">
              <div className="col-5">Type</div>
              <div className="col-7">
                <Input name="Type" value={conversion.type} disabled />
              </div>
            </div>
            <div className="awsui-row">
              <div className="col-5">Rate</div>
              <div className="col-7">
                <Input name="Rate" value={conversion.rate} disabled />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  static renderGridCell(column, rowData, formatterFunction) {
    let cellValue = column === 'index' && !rowData.isFooterRow ? rowData[column] + 1 : rowData[column];

    if (formatterFunction != null) {
      cellValue = formatterFunction(cellValue);
    }
    return (
      <div>
        {cellValue}
      </div>
    );
  }


  renderLines() {
    const { loading } = this.props;
    const { columnDefinitions } = this.state;
    const rowsCount = this.getGridSize();

    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>
                Lines&nbsp;
              </h2>
            </div>
            <div className="awsui-util-action-stripe-group">
              <Button
                text="View Journal"
                onClick={this.onViewClick}
              />
            </div>
          </div>
        </div>
        <div data-awsui-column-layout-root>
          <ReactDataGrid
            rowGetter={this.getGridRow}
            rowsCount={rowsCount}
            columns={columnDefinitions}
            sortable={false}
            filterable={false}
            loadingText="Loading..."
            wrapLines={false}
            resizableColumns
            stickyHeader
            loading={loading}
            showPagination={false}
            noMatch="noMatchContent"
          />


          {/* Acct Desc */}

          <div className="awsui-grid awsui-util-mt-m">
            <div className="awsui-row">
              <div className="col-2">Account Description</div>
              <div className="col-10">
                <Input name="acct_desc" disabled />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderFooter() {
    return (
      <div className={styles.footer}>
        <ColumnLayout columns={2}>
          <div data-awsui-column-layout-root="true">
            <div>
              <Button
                text="Download"
                className="awsui-util-f-l"
                onClick={this.onClickDownload}
              />
            </div>
            <div>
              <Button
                variant="primary"
                text="Done"
                className="awsui-util-f-r"
                onClick={this.onClickDone}
              />
            </div>
          </div>
        </ColumnLayout>
      </div>
    );
  }

  render() {
    const { className } = this.props;
    const divClassName = `${className} ${styles.wrapper}`;
    const { loading, error } = this.props;
    const { spinnerText } = this.state;

    if (error) {
      return (
        <div className={divClassName}>
          <div>Received an Error:</div>
          <div>{error}</div>
        </div>
      );
    }

    return (
      <div className={divClassName}>
        <MJEBanner bannerText="Journal Summary" />
        <LoadingSpinner loading={loading} text={spinnerText} />
        <div className="awsui-grid">
          <div className="awsui-row">
            <div className="col-9">
              {this.renderHeader()}
            </div>
            <div className="col-3">
              {this.renderConversion()}
            </div>
          </div>
        </div>
        {this.renderLines()}
        {this.renderFooter()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { primeJournalSummary } = state;
  const { username } = state.auth;
  const {
    loading, error, header, conversion, dataGrid, dataGridColumns,
    journalHeaderId, saving,
    downloadJournalUrl,
  } = primeJournalSummary;
  return {
    loading,
    error,
    header,
    conversion,
    dataGrid,
    dataGridColumns,
    journalHeaderId,
    username,
    saving,
    downloadJournalUrl,
  };
};

const mapDispatchToProps = {
  fetchInitialData: actions.fetchInitialData,
  downloadJournal: actions.downloadJournal,
  downloadJournalReset: actions.downloadJournalReset,
};

const PrimeJournalSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrimeJournalSummaryComponent);

export default PrimeJournalSummaryContainer;
